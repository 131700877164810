<template>
  <div v-for="item in gameAudioList" :key="item.id">
    <audio :id="`${item.id}`" :src="`${item.src}`"></audio>
  </div>

  <f7-col>
    <f7-row>
      <f7-menu-item
        class="sheet-open menu-item-size"
        v-show="voiceEnabled"
        v-on:click="switchVolume(voiceEnabled)"
        text="🔊"
      ></f7-menu-item>
      <f7-menu-item
        class="sheet-open menu-item-size"
        v-show="!voiceEnabled"
        v-on:click="switchVolume(voiceEnabled)"
        text="🔇"
      ></f7-menu-item>
    </f7-row>
  </f7-col>

  <!-- Menu container -->
  <div class="menu">
    <div class="menu-item menu-item-dropdown">
      <div class="menu-item-content">୧(๑•̀ᗝ•́)૭</div>
      <div class="menu-dropdown menu-dropdown-left">
        <div class="menu-dropdown-content">
          <a v-for="item in gameAudioList" :key="item.id">
            <a class="menu-dropdown-link menu-close" @click="sendTauntAudion(item.id)">{{ item.name }}</a>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, ref } from 'vue';
import { gameAudioList } from '@/js/game-content/static/game-static';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { InboundGameServerEventEnum } from '@/js/enum/server-interaction/inbound-game-server-event.enum';

let hasActiveAudio = ref(false);
let voiceEnabled = ref(true);

function sendTauntAudion(audioId) {
  gameEventEmitter.emit(OutboundServerEventEnum.SendTauntAudio, audioId);
}

async function switchVolume(status) {
  if (status) {
    if (gameEventEmitter.listenerCount(ClientGameNotificationEventEnum.PlayAudio) === 0) {
      gameEventEmitter.addListener(ClientGameNotificationEventEnum.PlayAudio, playAudio);
    }
  } else {
    gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PlayAudio, playAudio);
  }

  return (voiceEnabled.value = !status);
}

function playAudio({ audioId }) {
  if (voiceEnabled.value) {
    hasActiveAudio.value = true;

    let sound = document.getElementById(audioId);

    if (!sound) {
      console.error('sound not found: ', audioId);
      return;
    }

    sound.volume = 0.35;
    sound.play();

    sound.addEventListener('ended', () => {
      hasActiveAudio.value = false;
    });
  }
}

onMounted(() => gameEventEmitter.addListener(InboundGameServerEventEnum.PlayTauntAudio, playAudio));
onUnmounted(() => gameEventEmitter.removeListener(InboundGameServerEventEnum.PlayTauntAudio, playAudio));
</script>

<style scoped></style>

<template>
  <f7-page>
    <h1>{{ props.contentType }}</h1>
    <div class="container">
      <div v-if="contentType !== 'mage-hero'" v-for="card in cards" :key="card.id" class="item">
        <CardPreview :item="card"></CardPreview>
      </div>

      <div v-if="contentType === 'mage-hero'" v-for="card in cards" :key="card.id" class="item">
        <PickMage :mage-hero="card"></PickMage>
      </div>
    </div>

    <CardPreviewComponent></CardPreviewComponent>
  </f7-page>
</template>

<script setup>
import { onMounted, onUnmounted, reactive } from 'vue';
import { getDeadMages, getMageHeroes, getSpells, getTreasures } from '@/js/game-content/game-content';
import CardPreviewComponent from '@/components/card/card-preview-component.vue';
import CardPreview from '@/components/card/card-preview.vue';
import PickMage from '@/components/pick-mages/pick-mage.vue';

const cards = reactive([]);

const props = defineProps({
  contentType: String,
});

onMounted(async () => {
  if (props.contentType === 'spells') {
    const content = await getSpells();
    cards.push(...filterUniqueObjects(Object.values(content), 'name'));
  }

  if (props.contentType === 'treasures') {
    const content = await getTreasures();
    cards.push(...filterUniqueObjects(Object.values(content), 'name'));
  }

  if (props.contentType === 'mage-hero') {
    const content = await getMageHeroes();
    cards.push(...filterUniqueObjects(Object.values(content), 'name'));
  }

  if (props.contentType === 'dead-mage') {
    const content = await getDeadMages();
    cards.push(...filterUniqueObjects(Object.values(content), 'name'));
  }

  function filterUniqueObjects(array, key) {
    const uniqueKeys = [];
    return array.filter((obj) => {
      if (!uniqueKeys.includes(obj[key])) {
        uniqueKeys.push(obj[key]);
        return true;
      }
      return false;
    });
  }
});
onUnmounted(() => {});
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: calc(12% - 10px); /* Размер элемента, с учетом отступов */
  margin: 5px; /* Отступы между элементами */
  padding: 10px;
  box-sizing: border-box;
}
</style>

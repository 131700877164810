<template>
  <div class="rule-card">
    <strong class="glitch" :data-text="title">{{ title }}</strong>
    <div class="rules_innerContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style scoped>
.rule-card {
  background-color: #2a2a2a;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rules_innerContainer {
  flex-wrap: wrap;
  padding: 0 10%;
  justify-content: space-around;
}

.rules_innerContainer > .col {
  width: 30%;
  margin-bottom: 20px;
  border-bottom: 4px solid gray;
}

.rules_innerContainer p {
  text-align: justify;
  margin-bottom: 10px;
  font-size: 12px;
}

.rules_innerContainer p:last-child {
  margin-bottom: 35px;
}

.rules_innerContainer h5 {
  text-align: center;
  font-size: 14px;
  margin: 10px;
}

@media only screen and (max-width: 1050px) {
  .rules_innerContainer > .col {
    width: 85%;
  }
}

.glitch {
  color: white;
  font-size: 20px;
  text-transform: upercase;
  position: relative;
  /*display: inline-block;*/
}
.glitch::before,
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
}
.glitch::before {
  left: 2px;
  text-shadow: -2px 0 #49fc00;
  clip: rect(24px, 550px, 90px, 0);
  animation: glitch-anim-2 3s infinite linear alternate-reverse;
}
.glitch::after {
  left: -2px;
  text-shadow: -2px 0 #b300fc;
  clip: rect(85px, 550px, 140px, 0);
  animation: glitch-anim 2.5s infinite linear alternate-reverse;
}
@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(98px, 9999px, 120px, 0);
  }
  4.166666666666666% {
    clip: rect(73px, 9999px, 77px, 0);
  }
  8.333333333333332% {
    clip: rect(142px, 9999px, 54px, 0);
  }
  12.5% {
    clip: rect(53px, 9999px, 21px, 0);
  }
  16.666666666666664% {
    clip: rect(66px, 9999px, 15px, 0);
  }
  20.833333333333336% {
    clip: rect(29px, 9999px, 124px, 0);
  }
  25% {
    clip: rect(56px, 9999px, 136px, 0);
  }
  29.166666666666668% {
    clip: rect(70px, 9999px, 74px, 0);
  }
  33.33333333333333% {
    clip: rect(100px, 9999px, 5px, 0);
  }
  37.5% {
    clip: rect(90px, 9999px, 150px, 0);
  }
  41.66666666666667% {
    clip: rect(144px, 9999px, 97px, 0);
  }
  45.83333333333333% {
    clip: rect(130px, 9999px, 130px, 0);
  }
  50% {
    clip: rect(39px, 9999px, 83px, 0);
  }
  54.166666666666664% {
    clip: rect(125px, 9999px, 74px, 0);
  }
  58.333333333333336% {
    clip: rect(68px, 9999px, 78px, 0);
  }
  62.5% {
    clip: rect(142px, 9999px, 76px, 0);
  }
  66.66666666666666% {
    clip: rect(137px, 9999px, 146px, 0);
  }
  70.83333333333334% {
    clip: rect(143px, 9999px, 95px, 0);
  }
  75% {
    clip: rect(39px, 9999px, 76px, 0);
  }
  79.16666666666666% {
    clip: rect(9px, 9999px, 16px, 0);
  }
  83.33333333333334% {
    clip: rect(79px, 9999px, 9px, 0);
  }
  87.5% {
    clip: rect(125px, 9999px, 94px, 0);
  }
  91.66666666666666% {
    clip: rect(15px, 9999px, 148px, 0);
  }
  95.83333333333334% {
    clip: rect(9px, 9999px, 12px, 0);
  }
  100% {
    clip: rect(61px, 9999px, 149px, 0);
  }
}
@keyframes glitch-anim {
  0% {
    clip: rect(98px, 9999px, 120px, 0);
  }
  4.166666666666666% {
    clip: rect(73px, 9999px, 77px, 0);
  }
  8.333333333333332% {
    clip: rect(142px, 9999px, 54px, 0);
  }
  12.5% {
    clip: rect(53px, 9999px, 21px, 0);
  }
  16.666666666666664% {
    clip: rect(66px, 9999px, 15px, 0);
  }
  20.833333333333336% {
    clip: rect(29px, 9999px, 124px, 0);
  }
  25% {
    clip: rect(56px, 9999px, 136px, 0);
  }
  29.166666666666668% {
    clip: rect(70px, 9999px, 74px, 0);
  }
  33.33333333333333% {
    clip: rect(100px, 9999px, 5px, 0);
  }
  37.5% {
    clip: rect(90px, 9999px, 150px, 0);
  }
  41.66666666666667% {
    clip: rect(144px, 9999px, 97px, 0);
  }
  45.83333333333333% {
    clip: rect(130px, 9999px, 130px, 0);
  }
  50% {
    clip: rect(39px, 9999px, 83px, 0);
  }
  54.166666666666664% {
    clip: rect(125px, 9999px, 74px, 0);
  }
  58.333333333333336% {
    clip: rect(68px, 9999px, 78px, 0);
  }
  62.5% {
    clip: rect(142px, 9999px, 76px, 0);
  }
  66.66666666666666% {
    clip: rect(137px, 9999px, 146px, 0);
  }
  70.83333333333334% {
    clip: rect(143px, 9999px, 95px, 0);
  }
  75% {
    clip: rect(39px, 9999px, 76px, 0);
  }
  79.16666666666666% {
    clip: rect(9px, 9999px, 16px, 0);
  }
  83.33333333333334% {
    clip: rect(79px, 9999px, 9px, 0);
  }
  87.5% {
    clip: rect(125px, 9999px, 94px, 0);
  }
  91.66666666666666% {
    clip: rect(15px, 9999px, 148px, 0);
  }
  95.83333333333334% {
    clip: rect(9px, 9999px, 12px, 0);
  }
  100% {
    clip: rect(61px, 9999px, 149px, 0);
  }
}
@-webkit-keyframes glitch-anim-2 {
  6.666666666666667% {
    clip: rect(140px, 9999px, 73px, 0);
  }
  10% {
    clip: rect(147px, 9999px, 3px, 0);
  }
  13.333333333333334% {
    clip: rect(115px, 9999px, 85px, 0);
  }
  16.666666666666664% {
    clip: rect(131px, 9999px, 141px, 0);
  }
  20% {
    clip: rect(67px, 9999px, 85px, 0);
  }
  23.333333333333332% {
    clip: rect(11px, 9999px, 13px, 0);
  }
  26.666666666666668% {
    clip: rect(81px, 9999px, 45px, 0);
  }
  30% {
    clip: rect(112px, 9999px, 117px, 0);
  }
  33.33333333333333% {
    clip: rect(70px, 9999px, 9px, 0);
  }
  36.666666666666664% {
    clip: rect(74px, 9999px, 149px, 0);
  }
  40% {
    clip: rect(6px, 9999px, 12px, 0);
  }
  43.333333333333336% {
    clip: rect(44px, 9999px, 86px, 0);
  }
  46.666666666666664% {
    clip: rect(118px, 9999px, 100px, 0);
  }
  50% {
    clip: rect(23px, 9999px, 54px, 0);
  }
  53.333333333333336% {
    clip: rect(55px, 9999px, 112px, 0);
  }
  56.666666666666664% {
    clip: rect(150px, 9999px, 99px, 0);
  }
  60% {
    clip: rect(54px, 9999px, 75px, 0);
  }
  63.33333333333333% {
    clip: rect(42px, 9999px, 56px, 0);
  }
  66.66666666666666% {
    clip: rect(58px, 9999px, 109px, 0);
  }
  70% {
    clip: rect(79px, 9999px, 118px, 0);
  }
  73.33333333333333% {
    clip: rect(115px, 9999px, 140px, 0);
  }
  76.66666666666667% {
    clip: rect(106px, 9999px, 45px, 0);
  }
  80% {
    clip: rect(135px, 9999px, 20px, 0);
  }
  83.33333333333334% {
    clip: rect(40px, 9999px, 98px, 0);
  }
  86.66666666666667% {
    clip: rect(16px, 9999px, 39px, 0);
  }
  90% {
    clip: rect(59px, 9999px, 35px, 0);
  }
  93.33333333333333% {
    clip: rect(135px, 9999px, 21px, 0);
  }
  96.66666666666667% {
    clip: rect(99px, 9999px, 52px, 0);
  }
  100% {
    clip: rect(33px, 9999px, 57px, 0);
  }
}
@keyframes glitch-anim-2 {
  6.666666666666667% {
    clip: rect(140px, 9999px, 73px, 0);
  }
  10% {
    clip: rect(147px, 9999px, 3px, 0);
  }
  13.333333333333334% {
    clip: rect(115px, 9999px, 85px, 0);
  }
  16.666666666666664% {
    clip: rect(131px, 9999px, 141px, 0);
  }
  20% {
    clip: rect(67px, 9999px, 85px, 0);
  }
  23.333333333333332% {
    clip: rect(11px, 9999px, 13px, 0);
  }
  26.666666666666668% {
    clip: rect(81px, 9999px, 45px, 0);
  }
  30% {
    clip: rect(112px, 9999px, 117px, 0);
  }
  33.33333333333333% {
    clip: rect(70px, 9999px, 9px, 0);
  }
  36.666666666666664% {
    clip: rect(74px, 9999px, 149px, 0);
  }
  40% {
    clip: rect(6px, 9999px, 12px, 0);
  }
  43.333333333333336% {
    clip: rect(44px, 9999px, 86px, 0);
  }
  46.666666666666664% {
    clip: rect(118px, 9999px, 100px, 0);
  }
  50% {
    clip: rect(23px, 9999px, 54px, 0);
  }
  53.333333333333336% {
    clip: rect(55px, 9999px, 112px, 0);
  }
  56.666666666666664% {
    clip: rect(150px, 9999px, 99px, 0);
  }
  60% {
    clip: rect(54px, 9999px, 75px, 0);
  }
  63.33333333333333% {
    clip: rect(42px, 9999px, 56px, 0);
  }
  66.66666666666666% {
    clip: rect(58px, 9999px, 109px, 0);
  }
  70% {
    clip: rect(79px, 9999px, 118px, 0);
  }
  73.33333333333333% {
    clip: rect(115px, 9999px, 140px, 0);
  }
  76.66666666666667% {
    clip: rect(106px, 9999px, 45px, 0);
  }
  80% {
    clip: rect(135px, 9999px, 20px, 0);
  }
  83.33333333333334% {
    clip: rect(40px, 9999px, 98px, 0);
  }
  86.66666666666667% {
    clip: rect(16px, 9999px, 39px, 0);
  }
  90% {
    clip: rect(59px, 9999px, 35px, 0);
  }
  93.33333333333333% {
    clip: rect(135px, 9999px, 21px, 0);
  }
  96.66666666666667% {
    clip: rect(99px, 9999px, 52px, 0);
  }
  100% {
    clip: rect(33px, 9999px, 57px, 0);
  }
}
</style>

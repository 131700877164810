<template>
  <f7-row class="section-background">
    <button style="height: 30px; width: 10%" @click="getGameStatistic">📈</button>
    <!-- <button @click="pause()">{{ sessionInfo.hasPause ? 'Unpause': 'Pause' }}</button> -->
    <button style="height: 30px; width: 10%" @click="autoPlay()">autoPlay {{ player.autoPlay ? '🟢' : '🔴' }}</button>
    <span class="informationToolBarText">{{ sessionInfo.spellsCount }}🃏</span>
    <span class="informationToolBarText">{{ sessionInfo.treasureCount }}🏆</span>
    <span class="informationToolBarText">{{ player.wins }}🥇</span>
  </f7-row>
</template>

<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';

const props = defineProps({
  player: Object,
  sessionInfo: Object,
});

function getGameStatistic() {
  gameEventEmitter.emit(OutboundServerEventEnum.GameStatistic);
}

function pause() {
  gameEventEmitter.emit(ClientGameNotificationEventEnum.Pause);
}

function autoPlay() {
  gameEventEmitter.emit(OutboundServerEventEnum.AutoPlay);
}
</script>

<style scoped></style>

<template>
  <div class="icon-card-hover"></div>
</template>

<script setup></script>

<style scoped>
.icon-card-hover {
  width: 80px;
  height: 50px;
  border: 2px solid black;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  opacity: 0.15;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1050px) {
}
</style>

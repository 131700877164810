<template>
  <f7-block class="pickedSpellsBlock">
    <p v-if="sessionInfo.currentUsername">
      <strong>Кастує: {{ sessionInfo.currentUsername }}</strong>
    </p>
    <f7-row>
      <f7-col class="center-text">
        <transition-group name="bounce">
          <div class="card-in-row" :data-id="card.id" v-for="card in sessionInfo.currentSpells" :key="card.id">
            <strong v-if="card.isDropped">✋🛑 {{ card.droppedBy }}</strong>
            <Card
              :class="[
                card.hasClicked ? (card.hasClicked && card.hasPlayed ? 'card-played' : 'card-clicked') : getCardStyle(),
              ]"
              :item="card"
            />
          </div>
        </transition-group>
        <div v-if="!sessionInfo.currentSpells.length" class="card-in-row" v-for="card in preview">
          <CardHover></CardHover>
        </div>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script setup>
import Card from '../card/card';
import CardHover from '../card/card-hover';
import { ref } from 'vue';
import { GameStageEnum } from '@/js/enum/game-stage.enum';

const props = defineProps({
  player: Object,
  sessionInfo: Object,
});
let preview = ref([0, 1, 2]);

const getCardStyle = () => {
  return props.player.mageStatus === 'playing' && props.sessionInfo.stage === GameStageEnum.PLAYING_SPELLS
    ? 'card-available'
    : '';
};
</script>

<style scoped>
.pickedSpellsBlock {
  overflow: hidden;
  height: 230px;
  width: 550px;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.card-in-row {
  float: left;
  width: 130px;
  height: 120px;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.card-available:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 1px 1px 0 0 rgba(0, 255, 0, 0.7);
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
    box-shadow: inset 0 0 0 0 rgba(0, 255, 0, 0.7);
  }
  50% {
    box-shadow: inset 0 0 0 10px rgba(95, 176, 95, 0.7);
    opacity: 0.7;
  }
  100% {
    box-shadow: inset 0 0 0 0 rgba(9, 94, 9, 0.7);
    opacity: 0.1;
  }
}

.card-clicked {
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out; /* Added border transition */
  opacity: 0.7;
}

.card-played {
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in-out; /* Added border transition */
  opacity: 0.7;
  border: 2px solid red;
}
</style>

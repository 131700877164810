<template>
  <div class="game-page-content">
    <f7-block>
      <PlayerPlayingSpellsPreviewComponent :cards="sessionInfo.currentSpells"></PlayerPlayingSpellsPreviewComponent>
      <SpecialEffectPreviewComponent></SpecialEffectPreviewComponent>
      <TableScoreComponent />
      <EndGameTableScoreComponent />
      <NotificationPreviewComponent />
      <PlayerPreviewComponent />
      <WeatherNotificationComponent />
      <RulesPreviewComponent />

      <div class="space-background">
        <f7-row class="section-background">
          <f7-col width="20">
            <strong :style="{ 'background-color': player.color }">Маг: {{ player.username }}</strong>
            <MageHeroComponent :player="player"></MageHeroComponent>
          </f7-col>
          <f7-col width="10">
            <SoundComponent></SoundComponent>
          </f7-col>
          <f7-col width="10">
            <TauntComponent></TauntComponent>
          </f7-col>
          <f7-col width="10">
            <CastleComponent
              :castle="sessionInfo.castleOwner ? `🏰${sessionInfo.castleOwner}` : '🏰Вільно'"
            ></CastleComponent>
          </f7-col>
          <f7-col width="20">
            <InformationPanelComponent :player="player" :sessionInfo="sessionInfo"></InformationPanelComponent>
          </f7-col>
          <f7-col width="15">
            <GameStageComponent :stage="sessionInfo.stage"></GameStageComponent>
          </f7-col>
          <f7-col width="15"></f7-col>
        </f7-row>

        <f7-row>
          <f7-col width="10"></f7-col>
          <f7-col width="5">
            <HistoryCurrentCardsComponent></HistoryCurrentCardsComponent>
          </f7-col>
          <f7-col width="60">
            <CenterCardPanel :player="player" :session-info="sessionInfo"></CenterCardPanel>
          </f7-col>
          <f7-col width="20">
            <PlayerMenu :players="players"></PlayerMenu>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="33"> </f7-col>
          <f7-col width="33">
            <BottomCardPanel :player="player"></BottomCardPanel>
          </f7-col>
          <f7-col width="33"> </f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="15"></f7-col>
          <f7-col width="50">
            <SpellsOnHandPanel :stage="sessionInfo.stage" :player="player"></SpellsOnHandPanel>
          </f7-col>
          <f7-col width="15"> </f7-col>
        </f7-row>
      </div>
    </f7-block>
  </div>

  <PickMagesPreviewComponent></PickMagesPreviewComponent>
  <CardPreviewComponent></CardPreviewComponent>
  <PersonalTargetPreviewComponent></PersonalTargetPreviewComponent>
  <TreasureTargetPreviewComponent></TreasureTargetPreviewComponent>
  <ActionTargetPreviewComponent></ActionTargetPreviewComponent>
</template>

<script setup>
import PlayerMenu from '../../components/player/player-menu';
import TableScoreComponent from '../../components/tablescore/tablescore-component';
import EndGameTableScoreComponent from '../../components/tablescore/endgame-tablescore-component';
import BottomCardPanel from '../../components/card-panel/item-card-panel';
import CenterCardPanel from '../../components/center-card-panel/center-card-panel';
import CardPreviewComponent from '../../components/card/card-preview-component';
import PersonalTargetPreviewComponent from '../../components/personal-target/personal-target-preview-component';
import TreasureTargetPreviewComponent from '../../components/treasure-target/treasure-target-preview-component.vue';
import { getPlayer } from '@/js/game-session/player/player';

import { getSessionInfo } from '@/js/game-session/sessionInfo/sessionInfo';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { Game } from '@/js/game/game';
import InformationPanelComponent from '../../components/information-panel/information-panel-component';
import NotificationPreviewComponent from '../../components/notification/notification-preview-component';
import PlayerPreviewComponent from '@/components/player/player-preview-component';
import PickMagesPreviewComponent from '@/components/pick-mages/pick-mages-preview-component';
import SoundComponent from '@/components/sound/sound-component';
import SpecialEffectPreviewComponent from '@/components/special-effects/special-effect-preview-component';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import GameStageComponent from '@/components/information-panel/game-stage-component';
import HistoryCurrentCardsComponent from '@/components/history/history-current-cards-component';
import PlayerPlayingSpellsPreviewComponent from '@/components/current-cards-panel/player-playing-spells-preview-component';
import SpellsOnHandPanel from '@/components/card-panel/spells-on-hand-panel';
import WeatherNotificationComponent from '@/components/weather/weather-notification-component';
import RulesPreviewComponent from '@/components/rules/rules-preview.component.vue';
import { getters } from '@/js/store/store';
import TauntComponent from '@/components/sound/taunt-component.vue';
import CastleComponent from '@/components/information-panel/castle-component.vue';
import { f7 } from 'framework7-vue';
import { SocketGame } from '@/js/communication/socket-game';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { InboundGameServerEventEnum } from '@/js/enum/server-interaction/inbound-game-server-event.enum';
import MageHeroComponent from '@/components/information-panel/mage-hero.component';
import ActionTargetPreviewComponent from '@/components/action-target/action-target-preview-component';
import { detectAFK } from '@/js/utils/afk-detection';
import { getPlayers } from '@/js/game-session/players/players';

const account = getters.account();
const players = ref(getPlayers());
const player = reactive(getPlayer());
const sessionInfo = ref(getSessionInfo());
let gameInstance = null;

onMounted(() => {
  if (!getters.gameUrl() || !getters.gamePort()) {
    f7.dialog.alert('something not defined');
    f7.views.main.router.navigate('/lobby/');
  }

  const interval = setInterval(() => {
    if (detectAFK()) {
      clearInterval(interval);

      f7.dialog.alert(`AFK detected`);

      setTimeout(() => {
        location.reload();
      }, 2500);
    }
  }, 1000);

  gameInstance = new Game(new SocketGame(`ws://${getters.gameUrl()}:${getters.gamePort()}`, account?.id));

  if (getters.status() === 'join') {
    gameEventEmitter.emit(OutboundServerEventEnum.JoinToGame);
  }

  if (getters.status() === 'reconnect') {
    gameEventEmitter.emit(OutboundServerEventEnum.ReconnectToGame);
  }

  gameEventEmitter.addListener(InboundGameServerEventEnum.UpdatePlayerList, () => (players.value = getPlayers()));
  gameEventEmitter.addListener(InboundGameServerEventEnum.UpdateGameSessionInfo, () => {
    sessionInfo.value = getSessionInfo();
  });
  gameEventEmitter.addListener(InboundGameServerEventEnum.UpdatePlayerState, () => {
    for (const [key, value] of Object.entries(getPlayer())) {
      player[key] = value;
    }
  });

  // gameEventEmitter.addListener(ClientNotificationEventEnum.Pause, () => f7.dialog.alert('Гру поставили на паузу'));
  // gameEventEmitter.addListener(ClientNotificationEventEnum.Unpause, () => f7.dialog.alert('Гру зняли з паузи'));
});

onUnmounted(() => {
  gameEventEmitter.removeAllListeners();
  gameInstance.close();
});
</script>
<style>
ul {
  list-style-type: none; /* Remove bullets */
}

.space-background {
  background-image: url('https://d3eliglfhyhzo3.cloudfront.net/site/game-bg.jpg');
  background-size: cover;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.game-page-content {
  background-color: #121212;
}

@media only screen and (max-width: 1050px) {
  .cardImg strong {
    font-size: 8px;
  }
}
</style>

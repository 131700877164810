<template>
  <f7-block class="player-preview-block display-header">
    <f7-row>
      <f7-col>
        <f7-row>
          <f7-col width="20">
            <h1>{{ player.username }}</h1>
          </f7-col>
          <f7-col width="40"> </f7-col>
          <f7-col width="20">
            <MageHeroComponent :mage-hero="player.mageHero"></MageHeroComponent>
          </f7-col>
        </f7-row>

        <f7-row>
          <div class="text">{{ player.health }}💚</div>
          <div class="text">{{ player.shield }}🛡️️</div>
          <div class="text">{{ player.blood }}🩸️️</div>
          <div class="text">{{ player.pointsForTurn }}⚡️</div>
        </f7-row>

        <f7-row>
          <div class="text">Wins: {{ player.wins }}🥇️</div>
          <div class="text">KilledMages: {{ player.killedMages }} 💀️</div>
        </f7-row>
      </f7-col>
      <f7-col width="100">
        <f7-row>
          <div class="player-treasures-block">
            <strong>{{ treasuresName }}</strong>
            <hr />
            <div class="player-treasures-block">
              <f7-col class="card-in-row" v-for="treasure in getTreasures(player.treasures)" :key="treasure.id">
                <CardIcon :item="treasure"></CardIcon>
              </f7-col>
            </div>
          </div>
        </f7-row>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script setup>
import MageHeroComponent from '@/components/information-panel/mage-hero.component';
import CardIcon from '../card/card-icon';
import { getCard } from '@/js/game-session/sessionInfo/sessionInfo';
import { CardType } from '@/js/game-content/static/game-static';

const treasuresName = 'Treasures';
const props = defineProps({
  player: Object,
});

function getTreasures(treasures) {
  return treasures.map((treasure) => {
    return getCard(CardType.Treasure, treasure.id);
  });
}
</script>

<style>
.player-preview-block {
  overflow: hidden;
  background-color: #151717;
  height: 300px;
  width: 300px;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.text {
  font-size: 12px;
}

.card-in-row {
  float: left;
  padding: 5px;
  width: 70px;
  height: 45px;
}
</style>

const LobbyServerEventEnum = {
  FindMatch: 'find-match',
  KickPlayer: 'kick-player',
  LeaveLobby: 'leave-lobby',
  JoinToLobby: 'join-to-lobby',

  InitGame: 'init-game',
  CreateNewGame: 'create-new-game',
  GetClientCount: 'get-client-count',
};

export { LobbyServerEventEnum };

export const config = {
  env: process.env.NODE_ENV,

  apiBaseUrl: process.env.API_BASE_URL,
  apiPrefix: process.env.API_PREFIX || '/api',

  gatewayUrl: process.env.GATEWAY_URL,
  gatewayPort: process.env.GATEWAY_PORT,

  gameVersion: process.env.GAME_VERSION,
  staticRoot: process.env.STATIC_ROOT || 'https://d3eliglfhyhzo3.cloudfront.net',
  gameServerProxyUrl: process.env.GAME_SERVER_PROXY_URL,
};

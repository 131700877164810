import { HomePage, NotFoundPage } from '../../pages/default';
import { InGameLobby, GamePage } from '../../pages/game';
import { LobbyPage } from '../../pages/lobby';
import { PlaygroundPage, GameSimulationPage } from '../../pages/playground';
import { ContentPage } from '@/pages/content';

export const routes = [
  {
    path: '/',
    component: HomePage,
  },

  {
    path: '/game/',
    component: GamePage,
  },
  {
    path: '/lobby/',
    component: LobbyPage,
  },
  {
    path: '/in-game-lobby/',
    component: InGameLobby,
  },
  {
    path: '/playground/',
    component: PlaygroundPage,
  },
  {
    path: '/game-simulation/',
    component: GameSimulationPage,
  },
  {
    path: '/content/',
    component: ContentPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

import { config } from '@/config';

let inactivityTime = 0;

const resetTimer = () => {
  inactivityTime = 0;
};

document.addEventListener('mousemove', resetTimer);
document.addEventListener('keydown', resetTimer);

export const detectAFK = () => {
  if (config.env === 'development') return;

  const threshold = 180000;
  inactivityTime += 1000;

  return inactivityTime >= threshold;
};

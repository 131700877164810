<template>
  <modal v-if="showModal">
    <div class="component-preview">
      <div class="scoreboard">
        <div class="data-item">
          <button @click="closePreview()" class="close-btn">x</button>
          <p v-show="item.dicesResult">Результат Могутнього куба🎲 {{ item.dicesResult }}</p>
          <p>
            Карту зіграв: <strong>{{ item.player.username }}</strong>
          </p>
        </div>
        <div id="info-box">
          <div v-for="action of item.container">
            <strong v-if="!action.targetsBefore?.length">Цілей немає</strong>
            <p><strong>------------------------------</strong></p>
            <div v-for="(target, index) of action.targetsBefore">
              <div class="section-background">
                <f7-block>
                  <f7-row>
                    <f7-col>
                      <strong>Гравець: {{ getPlayerById(target.id).username }}</strong>
                      <PlayerIcon :player="getPlayerById(target.id)"></PlayerIcon>
                    </f7-col>
                    <f7-col>
                      <strong>До</strong>
                      <div class="before-attr">
                        <TargetAttr :target="target"></TargetAttr>
                      </div>
                    </f7-col>
                    <f7-col>
                      <strong>Після</strong>
                      <div class="after-attr">
                        <div v-for="targetAfter of getTargetAfter(index, target.id)">
                          <TargetAttr :target="targetAfter"></TargetAttr>
                        </div>
                      </div>
                    </f7-col>
                  </f7-row>
                  <f7-row>
                    <div v-for="targetAfter of getTargetAfter(index, target.id)">
                      <TargetsAfterComponent :actions="targetAfter.actions"></TargetsAfterComponent>
                    </div>
                  </f7-row>
                </f7-block>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script setup>
import TargetsAfterComponent from './targetsAfter-component';
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import PlayerIcon from '@/components/player/player-icon';
import { getPlayerById } from '@/js/game-session/player/player';
import TargetAttr from '@/components/history/target-attr';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

let item = ref(null);
let showModal = ref(null);

function setItem(data) {
  showModal.value = !!data;
  item.value = data;
}

function getTargetAfter(index, id) {
  return item.value.container[index].targetsAfter.filter((target) => target.id === id);
}

function closePreview() {
  gameEventEmitter.emit(ClientEventEnum.ShowCardDetails, null);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientEventEnum.ShowCardDetails, setItem);
});

onUnmounted(() => {
  gameEventEmitter.removeListener(ClientEventEnum.ShowCardDetails, setItem);
});
</script>

<style>
.scoreboard {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #424040;
  background-color: #1f1e1e;
  padding: 10px;
}

.before-attr {
  min-height: 25px;
  border: 2px solid #f8f3f3;
}

.after-attr {
  min-height: 25px;
  border: 2px solid #f8f3f3;
}
</style>

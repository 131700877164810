const SpecialEffectEnum = {
  getHeal: 'player-preview-get-heal',
  getDamage: 'player-preview-get-damage',
  getTreasure: 'player-preview-get-treasure',
  getDeadMage: 'player-preview-get-dead-mage',
  getShield: 'player-preview-get-shield',
  blockDamage: 'player-preview-block-damage',
};

export { SpecialEffectEnum };

<template>
  <f7-row className="section-background">
    <strong style="font-size: 10px">
      <span class="informationToolBarText section-background" style="font-size: 20px">{{ timer.toFixed(0) }}⏳</span>
      {{ StageUaEnum[stage] }}
    </strong>
  </f7-row>
</template>

<script setup>
import { StageUaEnum } from '@/js/enum/translate/ua/stage-ua.enum';
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

let timer = ref(0);
let interval = null;

function startTimer({ value }) {
  timer.value = value / 30;

  interval = setInterval(() => {
    timer.value -= 1;
    timer.value = Math.max(timer.value, 0);
  }, 1000);
}

function stopTimer() {
  clearInterval(interval);
  timer.value = 0;
}

const props = defineProps({
  stage: String,
});

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.StartTimer, startTimer);
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.StopTimer, stopTimer);
});

onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.StartTimer, startTimer);
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.StopTimer, stopTimer);
});
</script>

<style scoped></style>

<script setup>
import { reactive } from 'vue';
import { HttpClient } from '@/js/utils/http-client';
import { f7 } from 'framework7-vue';
import { actions } from '@/js/store/store';

const signupForm = reactive({
  username: '',
  password: '',
  passwordConfirmation: '',
  email: '',
});

function validateForm() {
  if (!signupForm.username || !signupForm.password || !signupForm.passwordConfirmation || !signupForm.email) {
    console.log('Заполните все поля.');
    return false;
  }

  if (signupForm.password !== signupForm.passwordConfirmation) {
    console.log('Пароли не совпадают.');
    return false;
  }

  return true;
}
async function submitForm() {
  if (!validateForm()) {
    console.log('Форма содержит ошибки. Пожалуйста, исправьте и отправьте снова.');
    return;
  }

  const account = await new HttpClient()
    .post('/accounts', {
      username: signupForm.username,
      password: signupForm.password,
      email: signupForm.email,
    })
    .catch((error) => {
      console.error(error);
      f7.dialog.alert('Проблеми із регістрацію. Спробуйте пізніше');
    });

  if (!account.error) {
    actions.setUsername(signupForm.username);
    actions.setAccount(account.data);

    f7.views.main.router.navigate('/lobby/');

    return;
  }

  console.error(account.error);
}
</script>

<template>
  <div class="registration-form">
    <h2>Розпочати шлях бойового мага</h2>
    <form @submit.prevent="submitForm">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="signupForm.username" required />

      <label for="password">Password:</label>
      <input type="password" id="password" v-model="signupForm.password" required />

      <label for="passwordConfirmation">Password Confirmation:</label>
      <input type="password" id="passwordConfirmation" v-model="signupForm.passwordConfirmation" required />

      <label for="email">Email:</label>
      <input type="email" id="email" v-model="signupForm.email" required />

      <button type="submit">SignUp!</button>
    </form>
  </div>
</template>

<style scoped>
.registration-form {
  max-width: 400px;
  margin: auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  font-weight: bold;
}

input {
  padding: 8px;
  font-size: 16px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #45a049;
}
</style>

export const treasure = {
  id: 'random-uuid-treasure',
  cardType: 'Treasure',
  name: 'Пушка-хохотушка',
  key: 'pushka_hohotuska.jpg',
  when: 'AtTheStartOfPlayerTurn',
  actions: [
    {
      target: 'LeftTarget',
      type: 'Hit',
      value: 1,
    },
  ],
};

export const treasure1 = {
  id: 'random-uuid-treasure-1',
  cardType: 'Treasure',
  name: 'Шлем надмозга',
  key: 'shlem_nadmozga.jpg',
  when: 'AtTheStartOfPlayerTurn',
  actions: [
    {
      target: 'LeftTarget',
      type: 'Hit',
      value: 1,
    },
  ],
};

export const spell = {
  id: 'random-uuid-spell-1',
  name: 'Кубический',
  key: 'kubicheskiy.jpg',
  type: 'quality',
  sign: 'Red',
  cardType: 'Spell',
  actions: [
    {
      target: 'AllTargetWithYou',
      type: 'RollAndGetTreasure',
      value: 1,
    },
    {
      target: 'Self',
      type: 'GetCastle',
      value: 1,
    },
  ],
  hasPlayed: false,
  hasClicked: false,
};

export const spell1 = {
  id: 'random-uuid-spell-2',
  cardType: 'Spell',
  name: 'Химерический хохот',
  key: 'himiricheskiy_hohot.jpg',
  type: 'delivery',
  rating: 17,
  sign: 'Blue',
  actions: [
    {
      target: 'AllWithYou',
      type: 'SnatchHealth',
      value: {
        low: 1,
        medium: 2,
        high: 3,
      },
    },
    {
      target: 'AllTargetWithYou',
      type: 'RollAndGetTreasure',
      value: 1,
    },
    {
      target: 'Self',
      type: 'GetCastle',
      value: 1,
    },
  ],
};

export const spell2 = {
  id: 'random-uuid-spell-3',
  cardType: 'Spell',
  name: 'От подруги',
  key: 'ot_mashi_dominashi.jpg',
  type: 'source',
  sign: 'Blue',
  actions: [
    {
      target: 'TargetWithLessKilledMages',
      type: 'SnatchHealth',
      value: 2,
      rollCondition: {
        sign: 'MORE_THAN',
        value: 3,
      },
    },
  ],
};

export const deadMage = {
  id: 'random-uuid-deadMage',
  cardType: 'DeadMage',
  name: 'Ответочка из преисподней',
  key: 'dead_mage.jpg',
  actions: [
    {
      type: 'Hit',
      value: 2,
      target: 'Random',
    },
  ],
};

export const magesHeros = [
  {
    id: '431ff3cd-92a2-431d-a8a1-1fea11505970',
    name: 'Принцесса Каникула',
    key: 'princessa',
    actionTypes: ['Get'],
    priority: 0,
    actions: [{ target: 'Self', type: 'GetRandomTreasure', value: 2 }],
  },
  {
    id: '3f412e0b-b957-46e0-9be4-5fd2a25e0c72',
    name: 'Занзабор шлаковый джинн',
    actionTypes: ['Get'],
    key: 'zanzabor',
    priority: 0,
    actions: [
      { target: 'Self', type: 'GetRandomTreasure', value: 1 },
      { target: 'Self', type: 'GetShield', value: 4 },
    ],
  },
  {
    id: '772a30e2-8f12-4f52-ac63-8518ae010a20',
    name: 'БаДжу-Джу призыватель духов',
    actionTypes: ['Get'],
    key: 'bagudgu',
    priority: 0,
    actions: [{ target: 'Self', type: 'GetShield', value: 8 }],
  },
  {
    id: 'eebc333b-300c-4f47-808a-571cd6d6dbbf',
    name: 'Лютопанк Кровомант',
    actionTypes: ['Hit'],
    key: 'nekromag',
    priority: 0,
    actions: [{ target: 'AllTarget', type: 'Hit', value: 3 }],
  },
  {
    id: 'a24923a8-df7b-4f35-b447-6556382dbb05',
    name: 'Бухлон Алкомаг',
    priority: 0,
    actionTypes: ['Hit'],
    key: 'buhlotron',
    actions: [{ target: 'Random', type: 'Hit', value: 5 }],
  },
  {
    id: '4beed84a-9ae5-4408-a9e6-5d38d94eec46',
    name: 'Свиня руснява',
    priority: 0,
    actionTypes: ['Hit'],
    key: 'hryakiy_domovoi',
    actions: [{ target: 'Self', type: 'Hit', value: 10 }],
  },
  {
    id: '69977522-3baa-4abd-b93f-58c100e0e102',
    name: 'Щекутончик-Чаровник',
    priority: 1,
    actionTypes: ['Snatch'],
    key: 'shekotun',
    actions: [{ target: 'AllTargetWithTreasures', type: 'SnatchRandomTreasure', value: 1 }],
  },
  {
    id: 'f51e2f2f-2afd-4d17-8d3c-537d9de9800c',
    name: 'Леди Лазерщица',
    priority: 1,
    actionTypes: ['Hit'],
    key: 'ledi_mars',
    actions: [
      { target: 'AllTargetWithShield', type: 'Hit', value: 2 },
      { target: 'AllTargetWithTreasures', type: 'Hit', value: 2 },
    ],
  },
];
export const playerState = {
  color: 'violet',
  mageHero: {},
  health: 25,
  username: 'dsds',
  dicesResultForTurn: 0,
  pointsForTurn: 0,
  treasures: [],
  deadMages: [deadMage],
  spellsOnHand: [],
  pickedSpells: [],
  shield: 0,
  played: true,
  id: 'a55f9d51-4581-482c-8f58-b9702f4bb2ea',
  wins: 0,
  killedMages: 0,
  mageStatus: 'awaiting',
  availableSpellTypes: ['delivery', 'quality', 'source'],
};
export const playerList = [
  {
    color: 'teal',
    mageHero: {},
    health: 25,
    username: 'ApacheAnus',
    dicesResultForTurn: 0,
    pointsForTurn: 0,
    treasures: [],
    deadMages: [],
    shield: 0,
    played: true,
    id: 'c1ac9f78-fe29-4101-bae7-3d92b80558d1',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: ['delivery', 'quality', 'source'],
  },
  {
    color: 'Gainsboro',
    mageHero: {},
    health: 25,
    username: 'Saharoviy',
    dicesResultForTurn: 0,
    pointsForTurn: 0,
    treasures: [],
    deadMages: [],
    shield: 0,
    played: true,
    id: '69c07b5a-c004-4f3a-82c5-027513c27c75',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: ['delivery', 'quality', 'source'],
  },
  {
    color: 'gray',
    mageHero: {},
    health: 25,
    username: 'Necrobyer',
    dicesResultForTurn: 0,
    pointsForTurn: 0,
    treasures: [],
    deadMages: [],
    disconnected: true,
    shield: 0,
    played: false,
    id: 'b1443095-2cea-43d4-ad91-2841b427a1dd',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: ['delivery', 'quality', 'source'],
  },
  {
    color: 'blue',
    mageHero: {},
    health: 25,
    username: 'Healtour',
    dicesResultForTurn: 0,
    pointsForTurn: 0,
    treasures: [],
    deadMages: [],
    shield: 0,
    played: false,
    id: 'c684a36f-b7ac-4aaa-868c-9758b10c61bc',
    wins: 0,
    killedMages: 0,
    mageStatus: 'dead',
    availableSpellTypes: ['delivery', 'quality', 'source'],
  },
  {
    color: 'olive',
    mageHero: {},
    health: 25,
    username: 'RezoKoz',
    dicesResultForTurn: 0,
    pointsForTurn: 0,
    treasures: [],
    deadMages: [],
    shield: 0,
    played: false,
    disconnected: true,
    id: '3fb4cb1a-d465-4efd-9654-aac1b5c583ff',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: ['delivery', 'quality', 'source'],
  },
  {
    color: 'red',
    mageHero: {},
    health: 25,
    username: 'dsds',
    dicesResultForTurn: 0,
    pointsForTurn: 0,
    treasures: [],
    deadMages: [],
    shield: 0,
    played: true,
    id: 'a9133b54-3a8c-4830-b11d-8e326beb8d0a',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: ['delivery', 'quality', 'source'],
  },
];
export const gameSessionInfo = {
  currentSpells: [
    {
      id: '2e03062f-e3e0-45f1-b459-fe027c0ce87a',
      hasPlayed: true,
      hasClicked: true,
      ownerId: '98e1ddc7-4ce4-4042-a3f2-1a0004a7b367',
    },
    {
      id: 'a3f16a07-1743-4403-b4f3-dd3abe250de9',
      hasPlayed: true,
      hasClicked: true,
      ownerId: '98e1ddc7-4ce4-4042-a3f2-1a0004a7b367',
    },
    {
      id: '147c1b90-af46-4a5b-b774-ec433a6d6a12',
      hasPlayed: false,
      hasClicked: false,
      ownerId: '98e1ddc7-4ce4-4042-a3f2-1a0004a7b367',
    },
  ],
  cardType: 'Spell',
  stage: 'PLAYING_SPELLS',
  spellsCount: 78,
  treasureCount: 27,
  hasPause: false,
};

export const spells = [
  {
    id: '9aa9524d-3891-4056-9987-79aa8e3168eb',
    name: 'От горячей Штучки',
    type: 'source',
    description: null,
    sign: 'Red',
    position: 1,
    actionTypes: ['Hit'],
    actions: [{ type: 'Hit', value: 3, target: 'TargetWithMostNumberOfHeals' }],
    rating: null,
    key: 'ot_gorachey_shtucki.jpg',
    cardType: 'Spell',
  },
  {
    id: 'a9180161-28e1-45f7-8d92-426657bb5c05',
    name: 'От Обезьяны с колчеданом',
    type: 'source',
    description: null,
    sign: 'Purple',
    position: 1,
    actionTypes: ['Drop'],
    actions: [
      { type: 'DropSpellFromHand', value: 1, target: 'AllTarget' },
      { type: 'Hit', value: 1, target: 'AllTarget' },
    ],
    rating: null,
    key: 'ot_mk_s_kalchedanom.jpg',
    cardType: 'Spell',
  },
  {
    id: '02d4c6be-4bd6-49d6-b49c-2614997258ac',
    name: 'От Запальничка',
    type: 'source',
    description: null,
    sign: 'Red',
    position: 1,
    actionTypes: ['Hit', 'Get'],
    actions: [
      { type: 'Hit', value: 3, target: 'TargetWithMostNumberOfHeals' },
      { type: 'GetCastle', value: 1, target: 'Self' },
    ],
    rating: null,
    key: 'ot_zajigalchika.jpg',
    cardType: 'Spell',
  },
  {
    id: '6aa5bb50-af4d-4926-9292-18cf859f83e6',
    name: 'Гостинцевый',
    type: 'quality',
    description: null,
    sign: 'Green',
    position: 2,
    actionTypes: ['Heal'],
    actions: [{ type: 'Heal', value: 2, target: 'AllTargetWithYou' }],
    rating: null,
    key: 'gostinsevii.jpg',
    cardType: 'Spell',
  },
  {
    id: 'a6302430-b919-4fbd-9b3d-b0c12a253efb',
    name: 'Петушиный',
    type: 'quality',
    description: null,
    sign: 'Red',
    position: 2,
    actionTypes: ['Hit'],
    actions: [{ type: 'Hit', value: { low: 1, high: 6, medium: 1 }, target: 'MostHealthyTarget' }],
    rating: null,
    key: 'petushok.jpg',
    cardType: 'Spell',
  },
  {
    id: 'ccab6c46-0a59-4499-9226-3b7c8f0789e3',
    name: 'Опарышный',
    type: 'quality',
    description: null,
    sign: 'Gray',
    position: 2,
    actionTypes: ['Hit'],
    actions: [
      { type: 'HitForEachSign', value: 2, target: 'MostHealthyTarget' },
      { type: 'Hit', value: 1, target: 'Self' },
    ],
    rating: null,
    key: 'oparishniy.jpg',
    cardType: 'Spell',
  },
  {
    id: '0f9a676b-06ab-4c13-8d4e-2880771629b4',
    name: 'Шалтай Раздолбай',
    type: 'delivery',
    description: null,
    sign: 'Purple',
    position: 3,
    actionTypes: ['Hit'],
    actions: [{ type: 'Hit', value: { low: 1, high: 3, medium: 2 }, target: 'AllTargetWithTreasures' }],
    rating: 4,
    key: 'shaltay_razdolbay.jpg',
    cardType: 'Spell',
  },
  {
    id: 'a4285e26-5791-4035-8bd9-3c8833f62b40',
    name: 'Раздвоитель личности',
    type: 'delivery',
    description: null,
    sign: 'Blue',
    position: 3,
    actionTypes: ['Hit'],
    actions: [{ type: 'Hit', value: { low: 1, high: 3, medium: 2 }, target: 'LeftAndRight' }],
    rating: 7,
    key: 'razdvoitel_lichosty.jpg',
    cardType: 'Spell',
  },
  {
    id: '741cf0f5-e60b-4235-b167-eb0a3c5a3b27',
    name: 'Змеиный жор',
    type: 'delivery',
    description: null,
    sign: 'Green',
    position: 3,
    actionTypes: ['Drop', 'Heal'],
    actions: [
      { type: 'DropSpellFromHand', value: 3, target: 'Self' },
      { type: 'Heal', value: 3, target: 'Self' },
    ],
    rating: 1,
    key: 'zmeiniy_jor.jpg',
    cardType: 'Spell',
  },
];

export const setPersonalTargets = [
  {
    color: 'magenta',
    mageHero: { id: '54e3014f-4d1a-45f2-8c97-b5238e2078c5', name: 'Бухлон Алкомаг', key: 'buhlotron' },
    health: 3,
    username: 'OldManKolchedan',
    dicesResultForTurn: 3,
    pointsForTurn: 12,
    treasures: [],
    deadMages: [],
    shield: 0,
    played: false,
    id: 'f7c2bb63-309a-4ff1-aeac-a3517651a778',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: [],
    dicesResult: 3,
  },
  {
    color: 'whitesmoke',
    mageHero: { id: '37876db0-d531-45bb-a88c-abb4a2af2205', name: 'Лютопанк Кровомант', key: 'nekromag' },
    health: 4,
    username: 'Marnokey',
    dicesResultForTurn: 7,
    pointsForTurn: 1,
    treasures: [{ id: '2c6929b2-1a45-4657-9002-96bcb2e02e16' }],
    deadMages: [],
    shield: 0,
    played: false,
    id: '53f3c6a9-5a42-4c5e-9c73-82538ba917bc',
    wins: 0,
    killedMages: 0,
    mageStatus: 'awaiting',
    availableSpellTypes: [],
    dicesResult: 6,
  },
];

export const playerPlayedCards = [
  {
    cardId: 'c94260e9-98f6-428a-aa78-066e9578f6f5',
    id: '978b8c1e-f307-430f-88fc-6a7aa6cc46e3',
    cardType: 'Spell',
    targetClass: 'Self',
    playerId: 'f67a58ed-e73f-4533-82df-331500cb12f7',
    targetsBefore: [
      {
        mageHero: { id: '5fe3fcce-4108-487d-bf49-56d51610c0e4', name: 'Принцесса Каникула', key: 'princessa' },
        health: 26,
        availableSpellTypes: [],
        id: 'f67a58ed-e73f-4533-82df-331500cb12f7',
      },
    ],
    targetsAfter: [
      {
        mageHero: { id: '5fe3fcce-4108-487d-bf49-56d51610c0e4', name: 'Принцесса Каникула', key: 'princessa' },
        health: 24,
        availableSpellTypes: [],
        id: 'f67a58ed-e73f-4533-82df-331500cb12f7',
        actions: [{ action: 'Hit', value: 2 }],
      },
    ],
  },
  {
    cardId: 'c94260e9-98f6-428a-aa78-066e9578f6f5',
    id: 'bd45b504-2795-41ae-b013-9478fd879cc4',
    cardType: 'Spell',
    targetClass: 'TargetWithMostKilledMages',
    playerId: 'f67a58ed-e73f-4533-82df-331500cb12f7',
    targetsBefore: [],
    targetsAfter: [],
  },
  {
    cardId: 'c66c87bb-289e-49b1-9bac-9a12e46e8b40',
    id: 'bdabbfd7-2e3e-43e7-854f-a5c69dbf8770',
    cardType: 'Spell',
    targetClass: 'AllTarget',
    playerId: 'f67a58ed-e73f-4533-82df-331500cb12f7',
    targetsBefore: [
      {
        mageHero: { id: '5fe3fcce-4108-487d-bf49-56d51610c0e4', name: 'Принцесса Каникула', key: 'princessa' },
        health: 24,
        availableSpellTypes: [],
        id: '69f1b82f-4ebf-4924-9eea-73df87d3dcc5',
      },
      {
        mageHero: { id: '82369582-2cdc-478e-a1fd-40b88486d2df', name: 'Лютопанк Кровомант', key: 'nekromag' },
        health: 28,
        availableSpellTypes: [],
        id: 'bb2284dc-304e-4bd9-9b93-5f97dd8dbede',
      },
      {
        mageHero: { id: '5389affa-72c2-4336-aa0d-039c4c6c0963', name: 'Свиня руснява', key: 'hryakiy_domovoi' },
        health: 10,
        availableSpellTypes: ['delivery', 'quality', 'source'],
        id: '2197d4ce-7b89-49fc-9c1a-330b06bc0e2b',
      },
      {
        mageHero: { id: 'f37ff0ef-8915-4a42-aff0-e8302b1b21eb', name: 'Занзабор шлаковый джинн', key: 'zanzabor' },
        shield: 4,
        availableSpellTypes: [],
        id: '4ed045ac-ace5-4aae-b00c-fff3cc2dfd16',
      },
      {
        mageHero: { id: '5389affa-72c2-4336-aa0d-039c4c6c0963', name: 'Свиня руснява', key: 'hryakiy_domovoi' },
        health: 13,
        availableSpellTypes: [],
        id: 'cb24fda5-ec81-4a83-b4b9-8a73a6aa38b8',
      },
    ],
    targetsAfter: [
      {
        mageHero: { id: '5fe3fcce-4108-487d-bf49-56d51610c0e4', name: 'Принцесса Каникула', key: 'princessa' },
        health: 21,
        availableSpellTypes: [],
        id: '69f1b82f-4ebf-4924-9eea-73df87d3dcc5',
        actions: [{ action: 'Hit', value: 3 }],
      },
      {
        mageHero: { id: '82369582-2cdc-478e-a1fd-40b88486d2df', name: 'Лютопанк Кровомант', key: 'nekromag' },
        health: 25,
        availableSpellTypes: [],
        id: 'bb2284dc-304e-4bd9-9b93-5f97dd8dbede',
        actions: [{ action: 'Hit', value: 3 }],
      },
      {
        mageHero: { id: '5389affa-72c2-4336-aa0d-039c4c6c0963', name: 'Свиня руснява', key: 'hryakiy_domovoi' },
        health: 7,
        availableSpellTypes: ['delivery', 'quality', 'source'],
        id: '2197d4ce-7b89-49fc-9c1a-330b06bc0e2b',
        actions: [{ action: 'Hit', value: 3 }],
      },
      {
        mageHero: { id: 'f37ff0ef-8915-4a42-aff0-e8302b1b21eb', name: 'Занзабор шлаковый джинн', key: 'zanzabor' },
        shield: 1,
        availableSpellTypes: [],
        id: '4ed045ac-ace5-4aae-b00c-fff3cc2dfd16',
        actions: [{ action: 'BlockedByAShield', value: 3 }],
      },
      {
        mageHero: { id: '5389affa-72c2-4336-aa0d-039c4c6c0963', name: 'Свиня руснява', key: 'hryakiy_domovoi' },
        health: 10,
        availableSpellTypes: [],
        id: 'cb24fda5-ec81-4a83-b4b9-8a73a6aa38b8',
        actions: [{ action: 'Hit', value: 3 }],
      },
    ],
  },
];

export const player1 = {
  id: 'id-1',
  username: 'player-1',
  mageStatus: 'dead',
  health: 25,
  shield: 2,
  spellsOnHand: [spell, spell1, spell1, spell1, spell1, spell1, spell1],
  disconnected: true,
  pickedSpells: [spell1, spell],
  treasures: [],
  deadMages: [],
};

export const player2 = {
  id: 'id-3',
  mageName: 'Power-ranger',
  username: 'player-3',
  mageStatus: 'awaiting',
  wins: 0,
  killedMages: 3,
  spellsOnHandCount: 7,
  pickedSpellsCount: 3,
  pointsForTurn: 212,
  health: 25,
  shield: 1,
  treasures: [treasure, treasure1, treasure, treasure1, treasure],
};

export const targets = [
  player1,
  {
    id: 'id-6',
    username: 'player-6',
    mageStatus: 'dead',
    health: 5,
    shield: 2,
    disconnected: true,
    treasures: [],
    pointsForTurn: 22,
  },
  {
    id: 'id-2',
    username: 'player-2',
    mageStatus: 'dead',
    health: 25,
    shield: 0,
    pointsForTurn: 44,
    treasures: [treasure, treasure1, treasure1, treasure1, treasure1],
  },
  player2,
];

export const historyItem = {
  cardId: '3ee30657-729b-48f1-aab5-aaf124e8986f',
  id: 'd09de7c2-f3b0-465a-8c06-61c20c71084b',
  cardType: 'Spell',
  targetClass: 'Self',
  playerId: 'tFyoohTeJt2S788LAAAB',
  targetsBefore: [
    {
      health: 14,
      id: 'tFyoohTeJt2S788LAAAB',
    },
  ],
  targetsAfter: [
    {
      health: 10,
      id: 'tFyoohTeJt2S788LAAAB',
      actions: [
        {
          action: 'Hit',
          value: 4,
          cards: [],
        },
      ],
    },
  ],
  player: {
    mageHero: { id: 'b41fe82c-0d5c-4e3e-baa0-030e62a06b67' },
    health: 10,
    username: 'ffff',
    dicesResultForTurn: 2,
    pointsForTurn: 100,
    treasures: [
      {
        id: 'f77d04b2-471f-4443-b2c5-f6a746515879',
        name: 'Трон Тормозуна',
        description: 'Добавляет +20 к инициативе',
        sign: null,
        actions: [
          {
            type: 'IncreasePoints',
            value: 20,
            target: 'Self',
          },
        ],
        when: 'PlayerPriority',
        key: 'tron_tormozuna.jpg',
        cardType: 'Treasure',
      },
      {
        id: 'a2df7e04-b991-4e36-afc0-97adf2a88708',
        name: 'Башмаки Скоромоха',
        sign: 'Blue',
        actions: [
          {
            target: 'Self',
          },
        ],
        when: 'AtThePlayerTurn',
        key: 'basmaki_skoromoha.jpg',
        cardType: 'Treasure',
      },
    ],
    deadMages: [],
    spellsOnHand: [],
    pickedSpells: [],
    shield: 0,
    played: false,
    id: 'tFyoohTeJt2S788LAAAB',
    wins: 0,
    killedMages: 0,
    mageStatus: 'playing',
  },
  card: {
    id: '3ee30657-729b-48f1-aab5-aaf124e8986f',
    name: 'От Сос-они',
    type: 'source',
    description: 'Сбросит 3 карты с руки цели и нанесёт урон',
    sign: 'Gray',
    position: 1,
    actions: [
      {
        type: 'DropSpellFromHandAndDamage',
        value: 3,
        target: 'PersonalTarget',
      },
      {
        type: 'Hit',
        value: 4,
        target: 'Self',
      },
    ],
    rating: null,
    key: 'ot_sosoniu.jpg',
    cardType: 'Spell',
  },
};

<template>
  <div
    class="icon-card"
    @mouseenter="setCardItem(item)"
    @mouseleave="setCardItem(null)"
    :style="[item.sign && item.cardType === CardType.Spell ? signColors[item.sign] : signColors[item.cardType]]"
  >
    <img :src="imgUrl" />
  </div>
</template>

<script setup>
import { signColors, CardType, staticDict } from '@/js/game-content/static/game-static';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

const props = defineProps({
  item: Object,
  showTargets: { type: Boolean, default: true },
});

const imgUrl = `${staticDict[props.item.cardType]}${props.item.key}`;

function setCardItem(item) {
  const data = item ? { ...item, showTargets: props.showTargets } : item;

  gameEventEmitter.emit(ClientEventEnum.GetCardPreview, data);
}
</script>

<style scoped>
.icon-card {
  width: 80px;
  height: 60px;
  border: 2px solid black;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1050px) {
  .icon-card {
    width: 50px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <f7-block class="section-background">
    <f7-col class="center-text">
      <f7-row>
        <f7-col width="5">
          <ul>
            <li class="button-header section-background" @click="changeCurrentPanel(CardType.Treasure)">
              <a href="">🏆</a>
            </li>
            <li class="button-header section-background" @click="changeCurrentPanel(CardType.DeadMage)">
              <a href="">💀</a>
            </li>
          </ul>
        </f7-col>
        <f7-col width="95">
          <f7-row>
            <strong>Заклинання: {{ player.spellsOnHand.length }}</strong>
            <CardsComponent v-if="currentPanel === CardType.Treasure" :cards="player.treasures"></CardsComponent>
            <CardsComponent v-if="currentPanel === CardType.DeadMage" :cards="player.deadMages"></CardsComponent>
            <strong
              >{{ currentPanel }}:
              {{ currentPanel === CardType.Treasure ? player.treasures.length : player.deadMages.length }}</strong
            >
          </f7-row>
        </f7-col>
      </f7-row>
    </f7-col>
  </f7-block>
</template>

<script setup>
import { ref } from 'vue';
import { CardType } from '@/js/game-content/static/game-static';
import CardsComponent from '../draggable/cards-component';

let currentPanel = ref(CardType.Treasure);

const props = defineProps({
  player: Object,
});

function changeCurrentPanel(panel) {
  currentPanel.value = panel;
}
</script>

<style scoped>
.button-group button {
  background-color: #29292a;
  color: #fff;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-group button:hover {
  background-color: #151717;
}

.button-header {
  float: left;
  border: 1px solid #1f1a1a;
  padding: 14px 7px;
  color: #fff;
  text-align: center;
}
</style>

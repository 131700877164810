import { CardType } from '@/js/game-content/static/game-static';
import { getters } from '@/js/store/store';

const defaultPlayer = {
  mageHero: {},
  id: 'test-id',
  isBot: false,
  maxHealth: 25,
  health: 20,
  dicesResult: 0,
  username: 'test',
  pointsForTurn: 0,
  treasures: [],
  deadMages: [],
  spellsOnHand: [],
  pickedSpells: [],
  disconnected: false,
  shield: 0,
  maxCountSpellOnHand: 7,
  maxCountPickedSpell: 3,
  played: false,
  wins: 0,
  spellsOnHandCount: 0,
  pickedSpellsCount: 0,
  killedMages: 0,
  mageStatus: 'awaiting',
  coins: 0,
  availableSpellTypes: [],
};

function getPlayer() {
  const player = getters.player() || defaultPlayer;
  const spellsData = getters.spells();
  const treasuresData = getters.treasures();
  const deadMagesData = getters.deadMages();

  const pickedSpells = player?.pickedSpells || [];
  const spellsOnHand = player?.spellsOnHand || [];
  const treasures = player?.treasures || [];
  const deadMages = player?.deadMages || [];

  return {
    ...player,
    pickedSpells: pickedSpells.map((spell) => ({ ...spellsData[spell.id], ...spell, cardType: CardType.Spell })),
    spellsOnHand: spellsOnHand.map((spell) => ({ ...spellsData[spell.id], ...spell, cardType: CardType.Spell })),
    treasures: treasures.map((treasure) => ({ ...treasuresData[treasure.id], cardType: CardType.Treasure })),
    deadMages: deadMages.map((deadMage) => ({ ...deadMagesData[deadMage.id], cardType: CardType.DeadMage })),
  };
}

function getPlayerById(id) {
  return getters.players().find((player) => player.id === id);
}

function getPlayersById(ids) {
  return getters.players().filter((player) => ids.includes(player.id));
}

export { getPlayer, getPlayerById, getPlayersById };

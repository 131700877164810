<template>
  <modal v-if="showModal">
    <div class="section-background player-preview">
      <f7-block>
        <f7-row>
          <h1>Обери дію</h1>
        </f7-row>
        <f7-row>
          <h3>Карта: {{ card.name }}</h3>
        </f7-row>
        <f7-row>
          <h3>Тип: {{ card.cardType }}</h3>
        </f7-row>
        <f7-row>
          <div :data-id="action.id" class="card-in-row" v-for="action in actions" :key="action.id">
            <div class="section-background" @click="pickAction(action.id)">
              <p>{{ getAction(action.type) }}</p>
              <p>{{ getTarget(action.target) }}</p>
              <p>Значення: {{ action.value }}</p>
            </div>
          </div>
        </f7-row>
      </f7-block>
    </div>
  </modal>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { getCard } from '@/js/game-session/sessionInfo/sessionInfo';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { getAction, getTarget } from '@/js/card';

let showModal = ref(false);
const actions = ref(null);
const card = ref(null);

let timer = null;

function setActions({ cardId, cardType }) {
  showModal.value = !!cardId;
  card.value = getCard(cardType, cardId);
  actions.value = card.value.actions.find((action) => action.type === 'ChooseBetween').value;

  timer = setTimeout(() => {
    pickAction(actions.value[0].id);
  }, 10_000);
}

function setItem(data) {
  showModal.value = !!data;
}

function pickAction(actionId) {
  gameEventEmitter.emit(OutboundServerEventEnum.PickAction, {
    cardId: card.value.id,
    actionId,
  });
  closeWindow();
}

function closeWindow() {
  setItem(null);
  clearTimeout(timer);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.ChooseCard, setActions);
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.SpellPlayed, () => setItem(null));
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.ChooseCardComplete, closeWindow);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.ChooseCard, setActions);
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.SpellPlayed, () => setItem(null));
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.ChooseCardComplete, closeWindow);
});
</script>

<style scoped>
.player-preview {
  display: flex;
  align-items: stretch;
  position: fixed;
  z-index: 999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: rgba(26, 25, 25, 1);
}

.card-in-row {
  float: left;
  width: 170px;
  height: 80px;
}

.card-in-row:hover {
  border: 1px solid #f1546e;
  transform: scale(1.1);
  cursor: grab;
}
</style>

import { HttpClient } from '../utils/http-client';
import { CardType } from './static/game-static';

function getCardType(content) {
  const data = {
    spells: CardType.Spell,
    treasures: CardType.Treasure,
    deadMages: CardType.DeadMage,
  };

  return data[content];
}

function get(content, page = 1) {
  return new HttpClient()
    .get(`/game-content/${content}`, page)
    .then((data) => data)
    .catch((error) => {
      console.log('error: ', error);
    });
}

async function getContent(content) {
  const response = await get(content, { page: 1 });
  const { pageCount } = response.meta;

  const data = response.data;
  const pages = Array.from(Array(pageCount).keys());
  console.log('pages: ', pages);

  for await (const page of pages) {
    const response = await get(content, { page: page + 1 });
    data.push(...response.data);
  }

  return data.reduce((accum, current) => {
    accum[current.id] = { ...current, cardType: getCardType(content) };

    return accum;
  }, {});
}

async function getSpells() {
  return getContent('spells');
}

async function getTreasures() {
  return getContent('treasures');
}

async function getDeadMages() {
  return getContent('deadMages');
}

async function getMageHeroes() {
  return getContent('mageHeroes');
}

export { getSpells, getTreasures, getDeadMages, getMageHeroes };

<template>
  <f7-page class="page-content-main-menu">
    <LoadingScreen />
    <f7-block>
      <f7-row>
        <f7-col width="20"></f7-col>
        <f7-col width="60">
          <div class="section-background">
            <div class="menu" v-if="isHost" style="width: 100%">
              <f7-row>
                <f7-col width="33">
                  <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="leaveLobby">
                    <strong>Leave</strong>
                  </button>
                </f7-col>
                <f7-col width="33"> </f7-col>
                <f7-col width="33">
                  <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="startGame">
                    <strong>Run a game</strong>
                  </button>
                </f7-col>
              </f7-row>
            </div>

            <f7-row class="list" style="margin-top: 10px" :class="{ disabled: !isHost }">
              <f7-col width="50">
                <div class="item-content item-input item-input-outline">
                  <div class="item-inner">
                    <div class="item-title item-floating-label">Bots</div>
                    <div class="item-input-wrap">
                      <input
                        id="botCount"
                        type="tel"
                        pattern="^[0-9]+$"
                        class="form-control"
                        v-model="botCount"
                        placeholder="Максимально 5"
                      />
                      <span class="input-clear-button" v-show="!isCreatedGame"></span>
                    </div>
                  </div>
                </div>
              </f7-col>
              <f7-col width="50">
                <div class="item-content item-input item-input-outline">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <div class="menu">
                        <div class="menu-item menu-item-dropdown">
                          <div class="menu-item-content">GameMode: {{ gameMode }}</div>
                          <div class="menu-dropdown menu-dropdown-left">
                            <div class="menu-dropdown-content">
                              <a class="menu-dropdown-link menu-close" @click="setGameMode('classicGameMode')"
                                >classicGameMode</a
                              >
                              <a class="menu-dropdown-link menu-close" @click="setGameMode('kingOfTheHillMode')"
                                >kingOfTheHillMode</a
                              >
                              <a class="menu-dropdown-link menu-close" @click="setGameMode('spellCraftMode')"
                                >spellCraftMode</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <span class="input-clear-button" v-show="!isCreatedGame"></span>
                    </div>
                  </div>
                </div>
                <!-- <div class="item-content item-input item-input-outline">
                  <div class="item-inner">
                    <div class="item-title item-floating-label">Addons</div>
                    <div class="item-input-wrap">
                      <input id="addons" v-model="addons" />
                      <span class="input-clear-button" v-show="!isCreatedGame"></span>
                    </div>
                  </div>
                </div> -->
              </f7-col>
            </f7-row>
          </div>
        </f7-col>
        <f7-col width="20"></f7-col>
      </f7-row>

      <f7-row>
        <f7-col width="20">
          <f7-row class="section-background">
            <p>
              <b>Classic Game Mode</b>: Звичайні правила бойових магів. Одне життя, декілька битв. Перемагає той, хто
              здобуде дві медалі! Варінт для поціновувачів класики.
            </p>
          </f7-row>
          <br />
          <f7-row class="section-background">
            <p>
              <b>King of the Hill Mode</b>: Режим для активної гри. В мага 3 життя, якщо помер, то на наступний раунд
              вже як огірок! Перемагає той, хто останній залишится на полі. Найкращий варінт для швидкої та динамічної
              гри.
            </p>
          </f7-row>
          <br />
          <f7-row class="section-background">
            <p>
              <b>Spell Craft Mode</b>: Кожний раунд новий набір заклинань. В мага безліміт життя, якщо помер, то на
              наступний раунд вже як огірок! Перемагає той, хто вб'є 15 магів швидше всіх, або маг с найбільшою
              кількістью вбивств за 15 раундів.
            </p>
          </f7-row>
        </f7-col>
        <f7-col width="60">
          <f7-row class="players-list section-background">
            <h1 class="messages-title" style="font-size: 22px">Players</h1>
            <div v-for="player in players" :key="player.id">
              <f7-row>
                <f7-col width="100">
                  <h2>
                    <strong class="section-background">{{ player.username }} </strong>
                  </h2>
                  <button
                    Outline
                    small
                    fill
                    color="red"
                    class="bubbly-button"
                    style="width: 5%"
                    @click="kickPlayer(player.id)"
                    v-if="isHost && player.id !== roomId"
                  >
                    X
                  </button>
                </f7-col>
              </f7-row>
            </div>
          </f7-row>
        </f7-col>
        <f7-col width="20">
          <f7-row class="section-background">
            <p>
              <b>Weather addon</b>: Доповненя для основної гри. На початку кожного другого раунду відбуваєтся якась
              погодня пригода! Якщо бажаєте додати більше випадкових ситуацій, це доповненя створено саме для цього!
            </p>
          </f7-row>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { SocketLobby } from '@/js/communication/socket-lobby';
import { f7 } from 'framework7-vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import LoadingScreen from '@/components/loading-screen/loading-screen-component';
import { actions, getters } from '@/js/store/store';
import { ClientLobbyNotificationEventEnum } from '@/js/enum/server-interaction/client-lobby-notification-event.enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';
import { LobbyServerEventEnum } from '@/js/enum/server-interaction/lobby-server-event.enum';

const isCreatedGame = ref(false);
const addons = ref(['Weather']);
const socket = new SocketLobby();
const roomId = ref(getters.roomId());
const botCount = ref(0);
const gameMode = ref('classicGameMode');
const players = ref([{ id: socket.id, username: getters.username() }]);

const isHost = roomId.value === socket.id;

socket.on(ClientLobbyNotificationEventEnum.JoinToLobbySuccess, () => {
  isCreatedGame.value = true;
});

const startGame = () => {
  isCreatedGame.value = false;

  const createGameDto = {
    roomId: roomId.value,
    botCount: +botCount.value,
    gameMode: gameMode.value,
    addons: Array.isArray(addons.value) ? addons.value : [],
  };

  socket.emit(LobbyServerEventEnum.InitGame, createGameDto);
};

const leaveLobby = () => {
  console.log('LEAVE LOBBY');
  socket.emit(LobbyServerEventEnum.LeaveLobby, { roomId: roomId.value });
  f7.views.main.router.navigate('/lobby/');
};

const kickPlayer = (playerId) => {
  socket.emit(LobbyServerEventEnum.KickPlayer, { playerId, roomId: socket.id });
};

const updatePlayerList = (updatedPlayers) => {
  players.value = updatedPlayers;
  actions.setPlayers(updatedPlayers);
};

const setGameMode = (name) => {
  gameMode.value = name;
};

// Event listeners
socket.on(ClientLobbyNotificationEventEnum.CreateGameSession, ({ roomId }) => {
  actions.setRoomId(roomId);
  gameEventEmitter.emit(ClientLobbyNotificationEventEnum.CreateGameSession, true);
});

socket.on(ClientLobbyNotificationEventEnum.InitGameSession, async ({ roomId, port, url }) => {
  actions.setRoomId(roomId);
  actions.setGamePort(port);
  actions.setGameUrl(url);

  actions.setPlayers([]);

  gameEventEmitter.emit(ClientEventEnum.InitLoadingScreen, false);

  actions.setStatus('join');

  f7.views.main.router.navigate('/game/');
});

socket.on(ClientLobbyNotificationEventEnum.UpdatePlayerList, ({ players }) => {
  updatePlayerList(players);
});

watch([botCount], ([newBotCount]) => {
  if (!isCreatedGame.value) {
    document.getElementById('botCount').value = newBotCount;
  }
});

onMounted(() => {});

onUnmounted(() => {});
</script>

<style scoped>
.page-content-main-menu {
  height: 100%;
  width: 100%;
  background-image: url('https://d3eliglfhyhzo3.cloudfront.net/site/entry-screen-logo.jpg');
  background-size: cover;
}

.players-list {
  margin-top: 20px;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
}
</style>

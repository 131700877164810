const EventEmitter = require('events');

class GameEventEmitter {
  #eventEmitter = null;

  constructor() {
    this.#eventEmitter = new EventEmitter();
  }

  addListener(event, method) {
    this.#eventEmitter.on(event, method);
  }

  listenerCount(event) {
    return this.#eventEmitter.listenerCount(event);
  }

  removeAllListeners() {
    return this.#eventEmitter.removeAllListeners();
  }

  removeListener(event, method) {
    this.#eventEmitter.off(event, method);
  }

  emit(event, data) {
    this.#eventEmitter.emit(event, data);
  }
}

const gameEventEmitter = new GameEventEmitter();

export { gameEventEmitter };

import axios from 'axios';
import { config } from '@/config';

export class HttpClient {
  constructor(url) {
    this.instance = axios.create({
      baseURL: url || config.apiBaseUrl + config.apiPrefix,
      timeout: 5000,
    });
  }

  get(path, params, headers) {
    return this.instance.get(path, { params, headers }).then((response) => response.data);
  }

  post(path, body) {
    return this.instance.post(path, body).then((response) => response.data);
  }
}

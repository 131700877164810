import { config } from '@/config';

const signColors = {
  Purple: 'color: #6a1894;',
  Gray: 'color: #666566;',
  Red: 'color: #e04916;',
  Blue: 'color: #1bc4f2;',
  Green: 'color: #008214;',
  Treasure: 'color: #e1eb34;',
  DeadMage: 'color: #8c4d85;',
};

function imageCardShadowStyle(imgUrl) {
  return {
    background: `linear-gradient(
      rgba(53, 53, 54, 1) 1%,
      rgba(255, 255, 255, 0) 5%,
      rgba(255, 255, 255, 0) 90%
    ),
    linear-gradient(
      rgba(255, 255, 255, 0) 100%,
      rgba(255, 255, 255, 0) 70%,
      rgba(53, 53, 54, 1) 90%
    ),
     url("${imgUrl}")`,
    'background-size': 'cover',
  };
}

const staticDict = {
  deadMage: `${config.staticRoot}/dead-mages/`,
  treasure: `${config.staticRoot}/treasures/`,
  spell: `${config.staticRoot}/cards/`,
  Spell: `${config.staticRoot}/cards/`,
  DeadMage: `${config.staticRoot}/dead-mages/`,
  Treasure: `${config.staticRoot}/treasures/`,
  MageHero: `${config.staticRoot}/mages/`,
  Fatigue: `${config.staticRoot}/special/`,
  Castle: `${config.staticRoot}/special/`,
  GetBlood: `${config.staticRoot}/special/`,
  DrainBlood: `${config.staticRoot}/special/`,
  GetDeadMage: `${config.staticRoot}/special/`,
};

const NotificationType = {
  Text: 'Text',
  RollDices: 'RollDices',
  PlayerKilled: 'PlayerKilled',
};

const CardType = {
  Spell: 'Spell',
  Treasure: 'Treasure',
  DeadMage: 'DeadMage',

  MageHero: 'MageHero',

  Perk: 'Perk',
  Weather: 'Weather',

  Fatigue: 'Fatigue',
  Castle: 'Castle',
  GetDeadMage: 'GetDeadMage',

  GetBlood: 'GetBlood',
  DrainBlood: 'DrainBlood',
};

const gameAudioList = [
  {
    name: '🦁 Приветствовать победителя',
    src: `${config.staticRoot}/audio/papich-chempion-zverey.mp3`,
    id: 'greet_the_winner',
  },
  { name: 'Разрывная 💣', src: `${config.staticRoot}/audio/razrivnaya.mp3`, id: 'razrivnaya' },
  { name: 'What sauce?', src: `${config.staticRoot}/audio/what-sauce.mp3`, id: 'what-sauce' },
  {
    name: '❓ А что это значит?',
    src: `${config.staticRoot}/audio/irusya-a-chto-eto-znachit.mp3`,
    id: 'a_chto_eto_znachit',
  },
  { name: 'Аджара гуджу', src: `${config.staticRoot}/audio/adjara-gudju.mp3`, id: 'adjara_gudju' },
  { name: 'Dont hurt me', src: `${config.staticRoot}/audio/dont-hurt-me.mp3`, id: 'dont-hurt-me' },
  { name: 'Call an ambulance', src: `${config.staticRoot}/audio/call-an-ambulance.mp3`, id: 'call-an-ambulance' },
  { name: 'wtf-is-kilometer', src: `${config.staticRoot}/audio/wtf-is-kilometer.mp3`, id: 'wtf-is-kilometer' },
  { name: 'M. Mayers', src: `${config.staticRoot}/audio/mayers.mp3`, id: 'mayers' },
  { name: 'bye bich', src: `${config.staticRoot}/audio/bye-bich.mp3`, id: 'bye-bich' },
  { name: 'Braahh', src: `${config.staticRoot}/audio/brue.mp3`, id: 'brue' },
  { name: 'OMG', src: `${config.staticRoot}/audio/amogus-caca.mp3`, id: 'amogus-caca' },
  { name: '🥩 Давай мясо!', src: `${config.staticRoot}/audio/davai-myaso.mp3`, id: 'davai_myaso' },
  { name: 'Цена за услугу 💸', src: `${config.staticRoot}/audio/fisting-is-300-.mp3`, id: 'fisting-is-300' },
  { name: 'Как тебе моя попа?', src: `${config.staticRoot}/audio/kak-tebe-moya-popa.mp3`, id: 'kak-tebe-moya-popa' },
  { name: 'Ля ты крыса 🐀', src: `${config.staticRoot}/audio/lya-tyi-kryisa.mp3`, id: 'lya-tyi-kryisa' },
  { name: 'Капібара', src: `${config.staticRoot}/audio/imcappybara.mp3`, id: 'imcappybara' },
  {
    name: 'Интрига',
    src: `${config.staticRoot}/audio/mem-okontsovka-filma-to-be-continued.mp3`,
    id: 'mem-okontsovka-filma-to-be-continued',
  },
  { name: 'Извинения', src: `${config.staticRoot}/audio/oh-shit-iam-sorry.mp3`, id: 'oh-shit-iam-sorry' },
  { name: 'Повезло-повезло!', src: `${config.staticRoot}/audio/povezlo-povezlo.mp3`, id: 'ovezlo-povezlo' },
  { name: 'Спокойной ночи 💋', src: `${config.staticRoot}/audio/spokoynoy-nochi.mp3`, id: 'spokoynoy-nochi' },
  {
    name: 'Суету навести охота!',
    src: `${config.staticRoot}/audio/suetu-navesti-ohota.mp3`,
    id: 'suetu-navesti-ohota',
  },
  {
    name: 'Я самый первый и самый лучший',
    src: `${config.staticRoot}/audio/ultimate-respect-button_mdjioon.mp3`,
    id: 'ultimate-respect-button_mdjioon',
  },
  { name: 'О курва!', src: `${config.staticRoot}/audio/o-kurwa.mp3`, id: 'o-kurwa' },
  { name: 'Rich Girl', src: `${config.staticRoot}/audio/rich-girl.mp3`, id: 'rich-girl' },
  { name: 'Потрачено', src: `${config.staticRoot}/audio/gta-dead.mp3`, id: 'gta-dead' },
  { name: 'Perdole', src: `${config.staticRoot}/audio/perdole.mp3`, id: 'perdole' },
  { name: 'Helicopter', src: `${config.staticRoot}/audio/helicopter.mp3`, id: 'helicopter' },
];

export { signColors, gameAudioList, staticDict, CardType, NotificationType, imageCardShadowStyle };

<template>
  <div class="game-page-content space-background">
    <f7-block>
      <div id="no-drop" class="drag-drop">#no-drop</div>

      <div id="yes-drop" class="drag-drop">#yes-drop</div>
      <div id="yes-drop" class="drag-drop">#yes-drop</div>

      <div id="outer-dropzone" class="dropzone">
        #outer-dropzone
        <div id="inner-dropzone" class="dropzone">#inner-dropzone</div>
      </div>
      <f7-row class="section-background">
        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="pickMages">
            <strong>Pick Mages</strong>
          </button>
        </f7-col>
        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="setPlayer">
            <strong>Set Player</strong>
          </button>
        </f7-col>
        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="setPlayers">
            <strong>Set Players</strong>
          </button>
        </f7-col>
        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="setSessionInfo">
            <strong>Set Session Info</strong>
          </button>
        </f7-col>
        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="setSpells">
            <strong>Set Spells</strong>
          </button>
        </f7-col>

        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="history">
            <strong>history</strong>
          </button>
        </f7-col>

        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="getPersonalTargets">
            <strong>get personal targets</strong>
          </button>
        </f7-col>

        <f7-col width="10">
          <button style="font-size: 11px" class="col button button-fill bubbly-button" @click="playYourCards">
            <strong>play your cards</strong>
          </button>
        </f7-col>
      </f7-row>

      <PlayerPlayingSpellsPreviewComponent :cards="sessionInfo.currentSpells"></PlayerPlayingSpellsPreviewComponent>
      <SpecialEffectPreviewComponent></SpecialEffectPreviewComponent>
      <TableScoreComponent />
      <NotificationPreviewComponent />
      <PlayerPreviewComponent />

      <f7-row class="section-background">
        <f7-col width="10">
          <strong :style="{ 'background-color': player.color }">Маг: {{ player.username }}</strong>
        </f7-col>
        <f7-col width="10">
          <SoundComponent></SoundComponent>
        </f7-col>
        <f7-col width="10">
          <TauntComponent></TauntComponent>
        </f7-col>
        <f7-col width="10">
          <CastleComponent
            :castle="sessionInfo.castleOwner ? `🏰${sessionInfo.castleOwner}` : '🏰Вільно'"
          ></CastleComponent>
        </f7-col>
        <f7-col width="20">
          <InformationPanelComponent :player="player" :sessionInfo="sessionInfo"></InformationPanelComponent>
        </f7-col>
        <f7-col width="15">
          <GameStageComponent :stage="sessionInfo.stage"></GameStageComponent>
        </f7-col>
        <f7-col width="15"></f7-col>
      </f7-row>

      <f7-row>
        <f7-col width="10"></f7-col>
        <f7-col width="5">
          <HistoryCurrentCardsComponent></HistoryCurrentCardsComponent>
        </f7-col>
        <f7-col width="60">
          <CenterCardPanel :player="player" :session-info="sessionInfo"></CenterCardPanel>
        </f7-col>
        <f7-col width="20">
          <PlayerMenu :players="players"></PlayerMenu>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="33"> </f7-col>
        <f7-col width="33">
          <BottomCardPanel :player="player"></BottomCardPanel>
        </f7-col>
        <f7-col width="33"> </f7-col>
      </f7-row>
      <f7-row>
        <f7-col width="15"> </f7-col>
        <f7-col width="50">
          <SpellsOnHandPanel :stage="sessionInfo.stage" :player="player"></SpellsOnHandPanel>
        </f7-col>
        <f7-col width="15"> </f7-col>
      </f7-row>
    </f7-block>
  </div>
  <RulesPreviewComponent />
  <PickMagesPreviewComponent></PickMagesPreviewComponent>
  <CardPreviewComponent></CardPreviewComponent>
  <PersonalTargetPreviewComponent></PersonalTargetPreviewComponent>
</template>

<script setup>
import RulesPreviewComponent from '../../components/rules/rules-preview.component.vue';
import PlayerMenu from '../../components/player/player-menu';
import TableScoreComponent from '../../components/tablescore/tablescore-component';
import BottomCardPanel from '../../components/card-panel/item-card-panel';
import CenterCardPanel from '../../components/center-card-panel/center-card-panel';
import CardPreviewComponent from '../../components/card/card-preview-component';
import PersonalTargetPreviewComponent from '../../components/personal-target/personal-target-preview-component';
import { getPlayer } from '@/js/game-session/player/player';

import { getSessionInfo } from '@/js/game-session/sessionInfo/sessionInfo';
import { getPlayers } from '@/js/game-session/players/players';
import { gameEventEmitter } from '@/js/game-event-emitter';
import InformationPanelComponent from '../../components/information-panel/information-panel-component';
import NotificationPreviewComponent from '../../components/notification/notification-preview-component';
import PlayerPreviewComponent from '@/components/player/player-preview-component';
import PickMagesPreviewComponent from '@/components/pick-mages/pick-mages-preview-component';
import SoundComponent from '@/components/sound/sound-component';
import SpecialEffectPreviewComponent from '@/components/special-effects/special-effect-preview-component';
import { onMounted, onUnmounted, ref } from 'vue';
import GameStageComponent from '@/components/information-panel/game-stage-component';
import HistoryCurrentCardsComponent from '@/components/history/history-current-cards-component';
import PlayerPlayingSpellsPreviewComponent from '@/components/current-cards-panel/player-playing-spells-preview-component';
import SpellsOnHandPanel from '@/components/card-panel/spells-on-hand-panel';
import TauntComponent from '@/components/sound/taunt-component.vue';
import CastleComponent from '@/components/information-panel/castle-component.vue';
import { InboundGameServerEventEnum } from '@/js/enum/server-interaction/inbound-game-server-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import {
  gameSessionInfo,
  magesHeros,
  playerList,
  playerPlayedCards,
  playerState,
  setPersonalTargets,
  spells,
} from '@/pages/playground/fixtures';
import { actions } from '@/js/store/store';

import interact from 'interactjs';

interact('.dropzone').dropzone({
  // only accept elements matching this CSS selector
  accept: '#yes-drop',
  // Require a 75% element overlap for a drop to be possible
  overlap: 0.75,

  // listen for drop related events:

  ondropactivate: function (event) {
    // add active dropzone feedback
    console.log('ondropactivate');
    event.target.classList.add('drop-active');
  },
  ondragenter: function (event) {
    console.log('ondragenter');
    var draggableElement = event.relatedTarget;
    var dropzoneElement = event.target;

    // feedback the possibility of a drop
    dropzoneElement.classList.add('drop-target');
    draggableElement.classList.add('can-drop');
    draggableElement.textContent = 'Dragged in';
  },
  ondragleave: function (event) {
    console.log('ondragleave');
    // remove the drop feedback style
    event.target.classList.remove('drop-target');
    event.relatedTarget.classList.remove('can-drop');
    event.relatedTarget.textContent = 'Dragged out';
  },
  ondrop: function (event) {
    console.log('ondrop');
    event.relatedTarget.textContent = 'Dropped';
  },
  ondropdeactivate: function (event) {
    // remove active dropzone feedback
    console.log('ondropdeactivate');
    event.target.classList.remove('drop-active');
    event.target.classList.remove('drop-target');
  },
});

function dragMoveListener(event) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
}

interact('.drag-drop').draggable({
  inertia: true,
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: 'parent',
      endOnly: true,
    }),
  ],
  autoScroll: true,
  // dragMoveListener from the dragging demo above
  listeners: { move: dragMoveListener },
});

const players = ref([]);
const player = ref(getPlayer());
const sessionInfo = ref(getSessionInfo());

function pickMages() {
  gameEventEmitter.emit(ClientGameNotificationEventEnum.PickMages, { mages: magesHeros });
}

function setPlayer() {
  actions.setPlayer(playerState);
  gameEventEmitter.emit(InboundGameServerEventEnum.UpdatePlayerState);
}

function setPlayers() {
  actions.setPlayers([]);

  playerList.forEach((player) => actions.addPlayer(player));

  actions.updatePlayers(playerList);
  gameEventEmitter.emit(InboundGameServerEventEnum.UpdatePlayerList);
}

function setSessionInfo() {
  actions.setSessionInfo(gameSessionInfo);
  gameEventEmitter.emit(InboundGameServerEventEnum.UpdateGameSessionInfo);
}

function setSpells() {
  spells.forEach((spell) => gameEventEmitter.emit(ClientGameNotificationEventEnum.AddSpellToHand, spell.id));
}

function history() {
  playerPlayedCards.forEach((card) =>
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayedCardsCurrentRound, card),
  );
}

function getPersonalTargets() {
  // FIXME
}

function playYourCards() {
  gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayerPlayingSpells, true);
}

onMounted(() => {
  gameEventEmitter.addListener(InboundGameServerEventEnum.UpdatePlayerList, () => (players.value = getPlayers()));
  gameEventEmitter.addListener(
    InboundGameServerEventEnum.UpdateGameSessionInfo,
    () => (sessionInfo.value = getSessionInfo()),
  );
  gameEventEmitter.addListener(InboundGameServerEventEnum.UpdatePlayerState, () => (player.value = getPlayer()));
});

onUnmounted(() => {
  gameEventEmitter.removeAllListeners();
});
</script>
<style>
ul {
  list-style-type: none; /* Remove bullets */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.game-page-content {
  position: relative;
  z-index: 2;
  background-color: #121212;
}

@media only screen and (max-width: 1050px) {
  .cardImg strong {
    font-size: 8px;
  }
}

#outer-dropzone {
  height: 140px;
}

#inner-dropzone {
  height: 80px;
}

.dropzone {
  background-color: #bfe4ff;
  border: dashed 4px transparent;
  border-radius: 4px;
  margin: 10px auto 30px;
  padding: 10px;
  width: 80%;
  transition: background-color 0.3s;
}

.drop-active {
  border-color: #aaa;
}

.drop-target {
  background-color: #29e;
  border-color: #fff;
  border-style: solid;
}

.drag-drop {
  display: inline-block;
  min-width: 40px;
  padding: 2em 0.5em;
  margin: 1rem 0 0 1rem;

  color: #fff;
  background-color: #29e;
  border: solid 2px #fff;

  touch-action: none;
  transform: translate(0px, 0px);

  transition: background-color 0.3s;
}

.drag-drop.can-drop {
  color: #000;
  background-color: #4e4;
}
</style>

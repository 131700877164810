<template>
  <Transition name="slide-fade">
    <modal v-if="showModal">
      <div class="card-preview">
        <CardPreview :item="card" :show-targets="showTargets"></CardPreview>
      </div>
    </modal>
  </Transition>
</template>

<script setup>
import CardPreview from './card-preview';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, ref } from 'vue';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

let card = ref(null);
let showModal = ref(null);
let showTargets = ref(null);

function setItem(item) {
  showTargets.value = item?.showTargets;
  showModal.value = !!item;
  card.value = item;
}

onMounted(() => gameEventEmitter.addListener(ClientEventEnum.GetCardPreview, setItem));
onUnmounted(() => gameEventEmitter.removeListener(ClientEventEnum.GetCardPreview, setItem));
</script>

<style scoped>
.card-preview {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  padding: 10px; /* Додати відступи від бордера до контенту таблиці */
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>

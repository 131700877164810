<template>
  <strong class="username-text">{{ ownerKilled }}</strong>
  <strong class="eleminated-text">{{ actionText }}</strong>
  <strong v-if="data.ownerId !== data.targetId" class="username-text">{{ targetKilled }}</strong>
</template>

<script setup>
import { getPlayerById } from '@/js/game-session/player/player';
import { ref } from 'vue';

const props = defineProps({
  data: Object,
});

const ownerKilled = ref(getPlayerById(props.data.ownerId)?.username);
const targetKilled = ref(getPlayerById(props.data.targetId)?.username);

const actionText = ownerKilled.value === targetKilled.value ? ref('самогубнувся') : ref(`замочив`);
</script>

<style scoped>
.eleminated-text {
  line-height: normal;
  top: 50%;
  padding: 5px;
  display: inline-block;
  vertical-align: middle;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px 2px #1a1e1a;
  font-size: 14px;
}

.username-text {
  line-height: normal;
  top: 50%;
  padding: 5px;
  display: inline-block;
  vertical-align: middle;
  justify-content: center;
  color: #ff0000;
  text-shadow: 1px 1px 2px #1a1e1a;
  font-size: 14px;
}
</style>

import { getters } from '@/js/store/store';
import { CardType } from '@/js/game-content/static/game-static';

const defaultSessionInfo = {
  currentSpells: [],
  currentUsername: null,
  stage: 'GAME_PREPARATION',
  spellsCount: 0,
  treasureCount: 0,
};

const exceptions = [
  CardType.Fatigue,
  CardType.Castle,
  CardType.GetDeadMage,
  CardType.GetBlood,
  CardType.DrainBlood,
  CardType.Weather,
  CardType.Perk,
];

const data = () => ({
  [CardType.Spell]: getters.spells(),
  [CardType.Treasure]: getters.treasures(),
  [CardType.DeadMage]: getters.deadMages(),
  [CardType.MageHero]: getters.mageHeroes(),

  [CardType.Weather]: {
    id: 'weather-id',
    name: 'Weather',
    key: 'get_dead_mage.jpg',
    cardType: 'Weather',
    actionTypes: ['Get'],
    actions: [],
  },

  [CardType.Perk]: {
    id: 'perk-id',
    name: 'Perk',
    key: 'get_dead_mage.jpg',
    cardType: 'Perk',
    actionTypes: ['Get'],
    actions: [],
  },

  [CardType.GetDeadMage]: {
    id: 'get-dead-mage',
    name: 'DeadMage',
    key: 'get_dead_mage.jpg',
    cardType: 'GetDeadMage',
    actionTypes: ['Get'],
    actions: [
      {
        id: 'get-dead-mage-action-id',
        target: 'Self',
        type: 'GetDeadMage',
        value: 1,
      },
    ],
  },
  [CardType.Fatigue]: {
    id: 'fatigue-id',
    cardType: 'Fatigue',
    actionTypes: ['Hit'],
    key: 'fatigue.jpg',
    name: 'Fatigue',
    actions: [
      {
        id: 'fatigue-action-id',
        target: 'Self',
        type: 'Hit',
        value: 1,
      },
    ],
  },
  [CardType.Castle]: {
    id: 'castle-id',
    name: 'CastleFacilities',
    key: 'castle.jpg',
    cardType: 'Castle',
    actionTypes: [],
    actions: [
      {
        id: 'castle-action-id-0',
        target: 'Self',
        type: 'Heal',
        value: 1,
      },
      {
        id: 'castle-action-id-1',
        target: 'Self',
        type: 'GetBlood',
        value: 1,
      },
    ],
  },

  [CardType.DrainBlood]: {
    id: `drain-blood`,
    name: 'DrainBlood',
    key: 'drain_blood.jpg',
    cardType: 'DrainBlood',
    actionTypes: ['Set'],
    actions: [
      {
        id: `drain-blood-action`,
        target: 'Self',
        type: 'DrainBlood',
        value: 1,
      },
    ],
  },
  [CardType.GetBlood]: {
    id: `blood`,
    name: 'Blood',
    key: 'blood.jpg',
    cardType: 'GetBlood',
    actionTypes: ['Get'],
    actions: [
      {
        id: `blood-action`,
        target: 'Self',
        type: 'GetBlood',
        value: 5,
      },
    ],
  },
});

function getCard(cardType, cardId) {
  if (exceptions.includes(cardType)) {
    return data()[cardType];
  }

  return data()[cardType][cardId];
}

function getSessionInfo() {
  const sessionInfo = getters.sessionInfo();

  if (!sessionInfo) {
    return defaultSessionInfo;
  }

  return {
    ...sessionInfo,
    currentSpells: sessionInfo.currentSpells.map((card) => ({ ...card, ...data().Spell[card.id] })),
  };
}

export { getSessionInfo, getCard };

const ClientLobbyNotificationEventEnum = {
  InitGameSession: 'init-game-session',
  JoinToLobbySuccess: 'join-to-lobby-success',
  UpdateClientsCount: 'update-clients-count',
  CreateGameSession: 'create-game-session',
  SomethingWentWrong: 'something-went-wrong',
  UpdateLobbyList: 'update-lobby-list',
  LobbyError: 'lobby-error',
  LobbyNotifyClient: 'lobby-notify-client',
  HostLeaveLobby: 'host-leave-lobby',
  CannotCreateLobby: 'cannot-create-lobby',
  KickFromLobby: 'kick-from-lobby',
  LobbyIsFull: 'lobby-is-full',
  CannotJoinLobby: 'cannot-join-lobby',
  CannotKickFromLobby: 'cannot-kick-from-lobby',
  UpdatePlayerList: 'update-player-list',
};

export { ClientLobbyNotificationEventEnum };

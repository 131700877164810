<template>
  <modal v-if="showModal">
    <div class="player-preview">
      <f7-block>
        <f7-row>
          <h1>Обери скарб</h1>
        </f7-row>
        <f7-row class="section-background">
          <br />
          <div :data-id="treasure.id" class="card-in-row" v-for="treasure in treasures" :key="treasure.id">
            <CardIcon @click="pickTreasure(treasure.id, spell)" :item="treasure" />
          </div>
        </f7-row>
      </f7-block>
    </div>
  </modal>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { getCard } from '@/js/game-session/sessionInfo/sessionInfo';
import { CardType } from '@/js/game-content/static/game-static';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import CardIcon from '@/components/card/card-icon.vue';

let treasures = ref([]);
let showModal = ref(false);
let spell = ref('');
let timer = null;

function setItem(data) {
  showModal.value = !!data;
  treasures.value = data;
}

function setTreasures({ treasureIds, spellId }) {
  const treasures = treasureIds.map((id) => getCard(CardType.Treasure, id));

  setItem(treasures);

  spell.value = spellId;

  timer = setTimeout(() => {
    console.log('pickTreasure: ', treasureIds[0]);
    pickTreasure(treasureIds[0], spellId);
  }, 10_000);
}

function pickTreasure(id, spellId) {
  gameEventEmitter.emit(OutboundServerEventEnum.PickTreasure, {
    spellId,
    treasureId: id,
  });

  closeWindow();
}

function closeWindow() {
  setItem(null);
  clearTimeout(timer);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PickTreasures, setTreasures);
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PickTreasuresComplete, closeWindow);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PickTreasures, setTreasures);
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PickTreasuresComplete, closeWindow);
});
</script>

<style scoped>
.player-preview {
  display: flex;
  align-items: stretch;
  position: fixed;
  z-index: 999999;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: rgba(26, 25, 25, 1);
}

.card-in-row {
  float: left;
  width: 170px;
  height: 80px;
}
</style>

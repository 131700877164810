const GameStageEnum = {
  GAME_PREPARATION: 'GAME_PREPARATION',
  START_BATTLE: 'START_BATTLE',
  START_ROUND: 'START_ROUND',
  SPELLS_PREPARATION: 'SPELLS_PREPARATION',
  PLAYING_SPELLS: 'PLAYING_SPELLS',
  PLAYING_DEAD_MAGES: 'PLAYING_DEAD_MAGES',
  FINISH_DEAD_MAGES: 'FINISH_DEAD_MAGES',
  END_ROUND: 'END_ROUND',
  END_BATTLE: 'END_BATTLE',
  END_GAME: 'END_GAME',
  TREASURES_ACTION_ON_PLAYER_TURN: 'TREASURES_ACTION_ON_PLAYER_TURN',
};

export { GameStageEnum };

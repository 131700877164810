<template>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <modal v-if="showModal">
      <div class="mages-preview">
        <f7-col>
          <div class="mages-picker section-background">
            <f7-block>
              <f7-row>
                <h1>PICK YOUR MAGE</h1>
              </f7-row>
              <f7-row>
                <div :data-id="mage.id" class="card-in-row" v-for="mage in mages" :key="mage.id">
                  <PickMage :mage-hero="mage" @click="pickMageHero(mage.id)"> </PickMage>
                </div>
              </f7-row>
              <f7-row>
                <strong>This effect will apply for each battle</strong>
              </f7-row>
            </f7-block>
          </div>
        </f7-col>
      </div>
    </modal>
  </XyzTransition>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import PickMage from '@/components/pick-mages/pick-mage';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

let mages = ref([]);
let showModal = ref(false);

function setItem(data) {
  showModal.value = !!data;
  mages.value = data;
}

function setMages({ mages }) {
  setItem(mages);
}

function pickMageHero(mageId) {
  gameEventEmitter.emit(OutboundServerEventEnum.PickMage, mageId);

  setItem(null);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.BattleStarted, setItem);
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PickMages, setMages);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PickMages, setMages);
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.BattleStarted, setItem);
});
</script>

<style scoped>
.mages-preview {
  display: flex;
  align-items: stretch;
  position: fixed; /* Змінено з absolute на fixed */
  z-index: 9999;
  top: 0; /* Змінено з 50% на 0 */
  left: 0; /* Змінено з 50% на 0 */
  width: 100%; /* Додано ширину на весь екран */
  height: 100%; /* Додано висоту на весь екран */
  background-color: rgba(26, 25, 25, 0.8); /* Змінено кольорову схему на rgba() з прозорістю */
  padding: 10px;
}

.mages-picker {
  display: flex;
  align-items: stretch;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-in-row {
  float: left;
  width: 200px;
  height: 300px;
}
</style>

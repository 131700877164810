<template>
  <f7-page login-screen style="background-position: center; background-repeat: no-repeat; width: 100%">
    <f7-block justify-content-center style="text-align: center">
      <p>
        Right now, while you are reading these lines, they are literally gutting each other for the right to be called
        the most unrealistically cool sorcerer in the whole kingdom!
      </p>
      <h1 style="color: deeppink">What are you waiting for?!</h1>
    </f7-block>
    <f7-row>
      <!-- Первый блок -->
      <f7-col>
        <f7-list form>
          <h2>Анонімна гра</h2>
          <f7-list-input
            label="Nickname"
            type="text"
            :placeholder="placeholder"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list style="text-align: center">
          <button class="bubbly-button" style="height: 45px; width: 50%" @click="anonymousLogin">Start</button>
        </f7-list>
      </f7-col>

      <!-- Второй блок -->
      <f7-col>
        <SignUp v-if="shouldCreateAccount"></SignUp>
        <Login v-if="!shouldCreateAccount"></Login>
        <p v-if="!shouldCreateAccount">Немає аккаунту? <b @click="createAccount()">Створити!</b></p>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { f7 } from 'framework7-vue';
import { actions, getters } from '@/js/store/store';

import SignUp from '@/components/site/signup.component.vue';
import Login from '@/components/site/login.component.vue';
import { HttpClient } from '@/js/utils/http-client';

const loginScreenOpened = false;
const anonymousLoginOpened = false;
const password = '';

const username = ref(getters.username());
const placeholder = 'the most brutal nickname';

const shouldCreateAccount = ref(false);

const isValidUsername = (username) => {
  username = username.trim();
  return !(!username || username.length > 20 || username.length < 3);
};

const createAccount = () => {
  shouldCreateAccount.value = true;
};

const anonymousLogin = () => {
  const isValid = isValidUsername(username.value);

  if (!isValid) {
    const toastCenter = f7.toast.create({
      text: 'Введите ваш никнейм!',
      position: 'center',
      closeTimeout: 2000,
    });
    toastCenter.open();
    return;
  }

  actions.setUsername(username.value);
  closeAndGo();
};

const closeAndGo = () => {
  f7.loginScreen.close();
  f7.views.main.router.navigate('/lobby/');
};

onMounted(async () => {
  const accessToken = getters.accessToken();

  if (accessToken && accessToken.split('.')[1]) {
    const decoded = atob(accessToken.split('.')[1]);

    const accountId = JSON.parse(decoded)?.accountId;

    if (!accountId) {
      return;
    }

    const account = await new HttpClient()
      .get(`/accounts/${accountId}`, {}, { Authorization: `Bearer ${accessToken}` })
      .catch((error) => {
        return { error };
      });

    if (!account?.data || account.error) {
      return;
    }

    actions.setAccount(account.data);
    actions.setUsername(account.data.username);
    f7.views.main.router.navigate('/lobby/');
  }
});
onUnmounted(() => {});
</script>

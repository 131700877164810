<template>
  <modal v-if="showModal">
    <div class="player-preview">
      <PlayerPreview :player="item"></PlayerPreview>
      <button @click="closePreview()" class="close-btn">x</button>
    </div>
  </modal>
</template>

<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import PlayerPreview from './player-preview';
import { onMounted, onUnmounted, ref } from 'vue';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

let item = ref(null);
let showModal = ref(false);

function setItem(data) {
  showModal.value = !!data;
  item.value = data;
}

function closePreview() {
  gameEventEmitter.emit(ClientEventEnum.GetPlayerPreview, null);
}

onMounted(() => gameEventEmitter.addListener(ClientEventEnum.GetPlayerPreview, setItem));
onUnmounted(() => gameEventEmitter.removeListener(ClientEventEnum.GetPlayerPreview, setItem));
</script>

<style scoped>
.player-preview {
  position: fixed;
  z-index: 9999999;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  padding: 10px; /* Додати відступи від бордера до контенту таблиці */
}

.close-btn {
  width: 20px;
  background-color: red;
  position: absolute;
  z-index: 10000;
  margin-top: 30px;
  top: 0;
  right: 0;
}
</style>

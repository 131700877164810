<template>
  <f7-page>
    <f7-block class="container card-deck mb-3 text-center" style="padding: 2%">
      <carousel :items-to-show="2">
        <slide v-for="slide in rules" :key="slide">
          <RuleCard :title="slide.title">
            {{ slide.body }}
          </RuleCard>
        </slide>
      </carousel>
    </f7-block>
    <p>Триває загрузка гри. Чекайте!</p>
  </f7-page>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import RuleCard from '@/components/loading-screen/rule-card.component.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from 'vue';

const rules = ref([
  {
    title: 'Типи магів',
    body: 'На початку гри ти можеш обрати собі легендарного мага, який буде з тобою до самого кінця. Кожний маг має свої унікальні бонуси.',
  },
  {
    title: '💪🎲 Могутній куб',
    body: 'Скільки карток однакового кольору в заклинанні, скільки кубиків у тебе на це заклинання.',
  },
  {
    title: 'Хід епічної сутички',
    body:
      ' Магічний турнір складається із кількох ігор. Гра триває стільки раундів, скільки потрібно для перемоги. У\n' +
      '            кожному раунді гравці кастують заклинання, шмалять по суперниках, крадуть легендарні скарби та всіляко\n' +
      '            намагаються не склеїти ласти. Чаклун, що примудрився залишитися єдиним, хто не вирушив на корм хробакам,\n' +
      '            перемагає у грі та здобуває перемогу 🥇.',
  },
  {
    title: 'Захист 🛡',
    body: 'Додатковий захист нікому не завадить!',
  },
  {
    title: 'Замок 🏰',
    body: 'Власник замку лікуєтся на 1 та отримує 1 кров кожного нового раунду.',
  },
  {
    title: 'Кров 🩸',
    body: 'Накопичуй кров, щоб витрачати її на посилення карт',
  },
  {
    title: 'Скарби 🏆',
    body:
      'Скарби - ядрені предмети, які прилітають тобі від ефектів деяких карт заклинань. Будь пильний, деякі заздрісні\n' +
      '-          чаклуни можуть віджати в тебе твою красу. Максимально можеш мати 5 скарбів.',
  },
  {
    title: 'Втома 💫',
    body:
      'В епічній сутичці бере участь багато карт. У випадку використання всіх карт маги почнуть відчувати втому!\n' +
      '          Втома означає що живий маг отримує 1 шкоду на початку кожного раунду.',
  },
  {
    title: 'Колода дохлих чаклунів 💀',
    body:
      'Смерть це ще не привід для кінця гри. На початку кожного нового раунду кожен дохлий чаклун отримує нову карту\n' +
      '          дохлого чаклуна. Якщо інші чаклуни захопляться смертовбивством один одного ще на кілька раундів, у гравців, що\n' +
      '          заляпалися, виявиться кілька кіл!',
  },
]);

export default {
  data() {
    return { rules };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    RuleCard,
  },
};
</script>

<style scoped>
.container {
  background-image: url('https://d3eliglfhyhzo3.cloudfront.net/site/main-background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>

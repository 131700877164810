<script setup></script>

<template>
  <table>
    <thead>
      <h1>Правила гри</h1>
    </thead>
    <tbody>
      <tr>
        <td>
          На початку гри ти можеш обрати собі легендарного мага, який буде з тобою до самого кінця. Кожний маг має свої
          унікальні бонуси.
        </td>
      </tr>
      <tr>
        <td>На початку раунду живий гравець готує заклинання з доступних карт.</td>
      </tr>
      <tr>
        <td>Будь-яке заклинання може включати від 1 до 3 карт. Але не більше 1 картки кожного типу.</td>
      </tr>
      <tr>
        <td>Скільки карток однакового кольору в заклинанні, скільки кубиків у тебе на це заклинання.</td>
      </tr>
      <tr>
        <td>При наведенні на картку показуются можливі цілі.</td>
      </tr>
      <tr>
        <td>
          Є карти які можна підсилити за домогою крові. Кров можна дістати за вбивства магів або з інших заклинань.
        </td>
      </tr>
      <tr>
        <td>Чим меньше в тебе карт на руці, тим раніше твій хід.</td>
      </tr>
      <tr>
        <td>
          Більшість карт заклинань спрямовані на конкретну жертву. Найчастіше потерпілий вказаний на самій карті, Тому
          не забудьте прочитати текст карт, перш ніж збирати заклинання з них. Жертва може бути випадковою Це може бути
          чаклун, що сидить зліва або праворуч від вас. Навіть усі вороги можуть бути жертвами одночасно.
        </td>
      </tr>

      <tr>
        <td>
          Скарби - це ваша сила або ні, не завжди. Будьте обережні, хтось захоче їх вкрасти!. Маг може тримати
          максимально 5 скарбів.
        </td>
      </tr>
      <tr>
        <td>
          Відкинувши ласти, чаклун скидає картки з руки, потім скидає скарби, а потім отримує "Картка дохлого колдуна".
          Маг може тримати максимально 5 дохлих колдунів.
        </td>
      </tr>
      <tr>
        <td>Останій вцілілий гравець отримає медаль чаклуна. Якщо у вас дві медалі - ви переможець турніру!</td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import Card from '../../components/card/card';
import BottomCardPanel from '../../components/card-panel/item-card-panel';
import CardIcon from '../../components/card/card-icon';
import CardPreviewComponent from '../../components/card/card-preview-component';
import PersonalTargetPreviewComponent from '../../components/personal-target/personal-target-preview-component';
import PlayerMenu from '../../components/player/player-menu';
import PlayerPreviewComponent from '../../components/player/player-preview-component';
import { player1, treasure, deadMage, spell, targets, spell1, spell2 } from './fixtures';
import PlayerIcon from '../../components/player/player-icon';
import InformationPanelComponent from '../../components/information-panel/information-panel-component';
import NotificationPreviewComponent from '../../components/notification/notification-preview-component';
import SoundComponent from '@/components/sound/sound-component';
import SpecialEffectPreviewComponent from '@/components/special-effects/special-effect-preview-component';
import PlayerPlayingSpellsPreviewComponent from '@/components/current-cards-panel/player-playing-spells-preview-component';
import PickMagesPreviewComponent from '@/components/pick-mages/pick-mages-preview-component';
import HistoryCurrentCardsComponent from '@/components/history/history-current-cards-component';
import TablescoreComponent from '@/components/tablescore/tablescore-component';
import TauntComponent from '@/components/sound/taunt-component.vue';
import { GameStageEnum } from '@/js/enum/game-stage.enum';

const stage = GameStageEnum.SPELLS_PREPARATION;
const players = targets;
</script>

<template>
  <f7-page>
    <f7-row>
      <TablescoreComponent></TablescoreComponent>
      <HistoryCurrentCardsComponent></HistoryCurrentCardsComponent>
      <PickMagesPreviewComponent></PickMagesPreviewComponent>
      <PlayerPlayingSpellsPreviewComponent :cards="player1.pickedSpells"></PlayerPlayingSpellsPreviewComponent>
      <SpecialEffectPreviewComponent></SpecialEffectPreviewComponent>
      <SoundComponent></SoundComponent>
      <TauntComponent></TauntComponent>
      <NotificationPreviewComponent></NotificationPreviewComponent>
      <InformationPanelComponent
        :player="player1"
        :stage="GameStageEnum.SPELLS_PREPARATION"
      ></InformationPanelComponent>
      <Card :item="spell"></Card>

      <Card :item="spell1"></Card>

      <Card :item="spell2"></Card>

      <Card :item="treasure"></Card>

      <Card :item="deadMage"></Card>
    </f7-row>
    <f7-row>
      <PersonalTargetPreviewComponent></PersonalTargetPreviewComponent>
    </f7-row>

    <f7-row>
      <BottomCardPanel :stage="stage" :player="player1"></BottomCardPanel>
    </f7-row>

    <f7-col>
      <br />
      <PlayerIcon :player="player1"></PlayerIcon>
      <br />
      <PlayerMenu :players="players"></PlayerMenu>
    </f7-col>
    <f7-row>
      <br />
      <CardIcon :item="spell"></CardIcon>
      <br />
      <CardIcon :item="treasure"></CardIcon>
      <br />
      <CardIcon :item="deadMage"></CardIcon>
      <br />
    </f7-row>
    <CardPreviewComponent></CardPreviewComponent>
    <PlayerPreviewComponent></PlayerPreviewComponent>
  </f7-page>
</template>

<style scoped></style>

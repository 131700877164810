import io from 'socket.io-client';
import { config } from '@/config';

export class SocketGame {
  constructor(url, id) {
    if (!SocketGame._instance) {
      SocketGame._instance = io(config.gameServerProxyUrl, {
        reconnection: true,
        withCredentials: true,
        extraHeaders: {
          'account-id': id,
          'game-server-ip': url,
        },
      });
    }

    return SocketGame._instance;
  }

  static emit(event, data) {
    return this._instance.emit(event, data);
  }

  static on() {
    return this._instance.on;
  }

  static disconnect() {
    this._instance.disconnect();
  }

  static connect() {
    this._instance.connect();
  }

  static id() {
    return this._instance.id;
  }
}

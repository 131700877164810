<template>
  <f7-row>
    <f7-col>
      <div class="card-preview" @mouseenter="getTargets()">
        <div>
          <strong
            class="display-header"
            :style="[item.sign && item.cardType === CardType.Spell ? signColors[item.sign] : signColors[item.cardType]]"
            >{{ item.cardType === CardType.Spell ? item.type : '' }} {{ item.cardType }}
            <span v-if="item.rating">⚡{{ item.rating }}</span></strong
          >
        </div>
        <div class="card-preview-img" :style="[getImgStyle(item.key)]">
          <f7-row v-if="item.cardType === CardType.Spell">
            <f7-col
              ><strong>{{ item.type === 'source' ? '1/3' : '' }}</strong></f7-col
            >
            <f7-col
              ><strong>{{ item.type === 'quality' ? '2/3' : '' }}</strong></f7-col
            >
            <f7-col
              ><strong>{{ item.type === 'delivery' ? '3/3' : '' }}</strong></f7-col
            >
          </f7-row>
        </div>

        <div class="text-size">
          <div class="title-height">
            <strong class="font-style" :title="item.type" style="width: 100%">{{ item.name }}</strong>
          </div>
          <div class="card-body">
            <strong v-if="item.when" style="color: orangered" class="text-size">{{ getWhen(item.when) }}</strong>
          </div>

          <div class="section-background" v-for="action in item.actions">
            <f7-row width="100">
              <div v-if="Array.isArray(action?.value)">
                <strong style="color: red" class="text-size">{{ getTarget(action.target) }}</strong>
                <strong>{{ getAction(action.type) }}</strong>
                <div class="section-background" v-for="chooseAction in action?.value">
                  <strong class="font-style">
                    {{ getActionDescription(chooseAction) }}
                  </strong>
                </div>
                <strong v-if="action.actionCondition" class="font-style" style="color: red">{{
                  getCondition(action)
                }}</strong>
              </div>
              <div v-if="!isObject(action?.value)">
                <strong class="font-style">
                  {{ getActionDescription(action) }}
                </strong>
                <strong v-if="action.actionCondition" class="font-style" style="color: red">{{
                  getCondition(action)
                }}</strong>
              </div>
              <div v-if="isObject(action?.value) && !isEmpty(action?.value) && !Array.isArray(action?.value)">
                <f7-row width="100">
                  <strong style="color: red" class="text-size">{{ getTarget(action.target) }}</strong>
                  <br />
                  <strong>{{ getAction(action.type) }}</strong>
                </f7-row>
                <strong style="font-size: 12px">💪🎲</strong>
                <f7-row>
                  <hr class="gradient" />
                  <f7-col>
                    <strong class="dice-text"> 😢 1-4 {{ action.value.low }} </strong>
                  </f7-col>
                  <f7-col>
                    <strong class="dice-text"> 😌 5-9 {{ action.value.medium }} </strong>
                  </f7-col>
                  <f7-col>
                    <strong class="dice-text"> 🥵 10+ {{ action.value.high }} </strong>
                  </f7-col>
                </f7-row>
              </div>
            </f7-row>
          </div>
        </div>
      </div>
    </f7-col>
    <f7-col v-if="showTargets">
      <div v-for="(action, index) in item.actions">
        <div v-if="action.target.startsWith('Random')" class="random-player-icon player-shift">
          <div class="random-target-sign">💅</div>
        </div>
        <div
          v-if="!action.target.startsWith('Random') && action.target !== 'Self' && !players[index]?.length"
          class="no-player-icon player-shift"
        >
          <div class="no-target-sign">🤬</div>
        </div>

        <div v-for="player in players[index] || []" :key="player.id">
          <PlayerIcon :prefer-attr-list="['health', 'username']" class="player-shift" :player="player"></PlayerIcon>
        </div>
        <hr />
      </div>
    </f7-col>
  </f7-row>
</template>

<script setup>
import { isEmpty, isObject } from 'lodash';
import { CardType, imageCardShadowStyle, signColors, staticDict } from '@/js/game-content/static/game-static';
import PlayerIcon from '../player/player-icon';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, reactive } from 'vue';
import { getAction, getTarget, getCondition, getActionDescription, getWhen } from '@/js/card';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

const props = defineProps({
  item: Object,
  showTargets: Boolean,
});

let players = reactive({});

function getTargets() {
  gameEventEmitter.emit(OutboundServerEventEnum.GetTargets, props.item.id);
}

function getImgStyle(key) {
  const imgUrl = `${staticDict[props.item.cardType]}${key}`;
  return imageCardShadowStyle(imgUrl);
}

function setTargets(dto) {
  if (dto.cardId !== props.item.id) return;

  players[dto.actionIndex] = dto.players || [];
}

onMounted(() => {
  gameEventEmitter.addListener(ClientEventEnum.SetTargets, setTargets);
  getTargets();
});
onUnmounted(() => gameEventEmitter.removeListener(ClientEventEnum.SetTargets, setTargets));
</script>

<style scoped>
.card-preview-img {
  width: 100%;
  height: 40%;
}

.player-shift {
  margin-left: 40px;
}

.card-preview {
  background: #353536;
  border: 2px solid black;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  height: 276px;
  width: 182px;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  margin: 0;
}

.card-body {
  border-radius: 6px;
}

.card-preview:hover {
  border: 3px solid #f1546e;
}

.card-preview span {
  font-size: 10px;
}

.dice-text {
  font-size: 12px;
  background-color: #29292d;
}

.title-height {
  background: linear-gradient(#37373b, #1a1a1f);
  box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.4);
  height: 10px;
}

.random-player-icon {
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.random-target-sign {
  width: 38px;
  height: 38px;
  background-color: yellow;
}

.no-target-sign {
  width: 38px;
  height: 38px;
  background-color: red;
}

.no-player-icon {
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.gradient {
  height: 3px;
  width: 100%;
  border: none;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    rgb(224, 222, 236) 0%,
    rgb(174, 215, 128) 21%,
    rgb(22, 170, 6) 51%,
    rgb(255, 0, 51) 100%
  );
}

.text-size {
  font-size: 12px;
  margin: 0;
}
</style>

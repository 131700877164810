<template>
  <audio id="prepare-skills-notification" :src="`${staticRoot}/prepare-skills-notification.wav`"></audio>
  <audio id="resurrect" :src="`${staticRoot}/resurrect.mp3`"></audio>
  <audio id="weather-notification" :src="`${staticRoot}/weather-notification.wav`"></audio>
  <audio id="roll-dices" :src="`${staticRoot}/roll-dices.wav`"></audio>
  <audio id="pick-spell" :src="`${staticRoot}/wrong-action.mp3`"></audio>
  <audio id="your-turn-sound" :src="`${staticRoot}/your-turn-sound.wav`"></audio>
  <audio id="end-turn-notification" :src="`${staticRoot}/end-turn-notification.wav`"></audio>
  <audio id="ready-notification" :src="`${staticRoot}/ready-notification.mp3`"></audio>
  <audio id="use-spell" :src="`${staticRoot}/use-spell.wav`"></audio>
  <audio id="return-back" :src="`${staticRoot}/return-back.wav`"></audio>

  <audio id="block-damage" :src="`${staticRoot}/block-damage.mp3`"></audio>
  <audio id="get-damage" :src="`${staticRoot}/get-damage.mp3`"></audio>
  <audio id="get-dead-mage" :src="`${staticRoot}/get-dead-mage.mp3`"></audio>
  <audio id="get-heal" :src="`${staticRoot}/get-heal.mp3`"></audio>
  <audio id="get-shield" :src="`${staticRoot}/get-shield.mp3`"></audio>
  <audio id="get-treasure" :src="`${staticRoot}/get-treasure.mp3`"></audio>

  <audio id="double-kill" :src="`${staticRoot}/double-kill.mp3`"></audio>
  <audio id="first-blood" :src="`${staticRoot}/first-blood.mp3`"></audio>
  <audio id="i-want-to-die" :src="`${staticRoot}/i-want-to-die.mp3`"></audio>
  <audio id="killed-player" :src="`${staticRoot}/killed-player.mp3`"></audio>
  <audio id="mega-kill" :src="`${staticRoot}/mega-kill.mp3`"></audio>
  <audio id="monster-kill" :src="`${staticRoot}/monster-kill.mp3`"></audio>
  <audio id="personal-target" :src="`${staticRoot}/personal-target.mp3`"></audio>
  <audio id="ultra-kill" :src="`${staticRoot}/ultra-kill.mp3`"></audio>
  <audio id="click" :src="`${staticRoot}/click.mp3`"></audio>
  <audio id="error" :src="`${staticRoot}/error.mp3`"></audio>
  <audio id="winner-end-game" :src="`${staticRoot}/winner-end-game.mp3`"></audio>
  <audio id="drop-spell" :src="`${staticRoot}/drop-spell.mp3`"></audio>
  <audio id="add-spell" :src="`${staticRoot}/add-spell.mp3`"></audio>
  <audio id="add-to-picked" :src="`${staticRoot}/add-to-picked.mp3`"></audio>
  <audio id="remove-from-picked" :src="`${staticRoot}/remove-from-picked.mp3`"></audio>
  <audio id="end-round" :src="`${staticRoot}/end-round.mp3`"></audio>
  <audio id="pick-mages" :src="`${staticRoot}/pick-mages.mp3`"></audio>
  <audio id="pick-treasure" :src="`${staticRoot}/pick-treasure.mp3`"></audio>
  <audio id="round-started" :src="`${staticRoot}/round-started.mp3`"></audio>

  <f7-row>
    <f7-col width="5">
      <f7-menu-item
        class="sheet-open menu-item-size"
        v-show="voiceEnabled"
        v-on:click="switchVolume(voiceEnabled)"
        text="Game🔊"
      ></f7-menu-item>
      <f7-menu-item
        class="sheet-open menu-item-size"
        v-show="!voiceEnabled"
        v-on:click="switchVolume(voiceEnabled)"
        text="Game🔇"
      ></f7-menu-item>
    </f7-col>
  </f7-row>
</template>

<script setup>
import { config } from '@/config';

import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, ref } from 'vue';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

let hasActiveAudio = ref(false);
let voiceEnabled = ref(true);
const staticRoot = `${config.staticRoot}/game-sounds`;

async function switchVolume(status) {
  if (status) {
    if (gameEventEmitter.listenerCount(ClientGameNotificationEventEnum.PlayAudio) === 0) {
      gameEventEmitter.addListener(ClientGameNotificationEventEnum.PlayAudio, playAudio);
    }
  } else {
    gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PlayAudio, playAudio);
  }

  return (voiceEnabled.value = !status);
}

function playAudio(audioId) {
  if (voiceEnabled.value) {
    hasActiveAudio.value = true;

    let sound = document.getElementById(audioId);

    if (!sound) {
      console.error('sound not found: ', audioId);
      return;
    }

    sound.volume = 0.35;
    sound.play();

    sound.addEventListener('ended', () => {
      hasActiveAudio.value = false;
    });
  }
}

onMounted(() => gameEventEmitter.addListener(ClientGameNotificationEventEnum.PlayAudio, playAudio));
onUnmounted(() => gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PlayAudio, playAudio));
</script>

<style scoped></style>

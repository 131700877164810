<template>
  <strong>Приготуй магію ({{ player.pickedSpells.length }} / {{ player.maxCountPickedSpell }})</strong>
  <button
    v-if="player.mageStatus !== 'dead' && !isReady"
    style="float: right; width: 50px; height: 50px; background-color: forestgreen"
    @click="ready()"
  >
    <strong style="color: white">Готов</strong>
  </button>

  <f7-block class="pickedSpellsBlock">
    <f7-row>
      <f7-col class="center-text">
        <draggable
          handle=".handle"
          :disabled="stage !== GameStageEnum.SPELLS_PREPARATION"
          :list="player.pickedSpells"
          group="card"
          @remove="backToHand"
          @add="pickSpell"
        >
          <div class="card-in-row" :data-id="card.id" v-for="card in player.pickedSpells" :key="card.id">
            <Card class="handle" :item="card" />
          </div>

          <div class="card-in-row" v-for="card in preview">
            <CardHover></CardHover>
          </div>
        </draggable>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { defineComponent } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';

export default defineComponent({
  components: {
    draggable: VueDraggableNext,
  },
});
</script>

<script setup>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import Card from '../card/card';
import CardHover from '../card/card-hover';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { orderBy } from 'lodash';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { GameStageEnum } from '@/js/enum/game-stage.enum';
import { getters } from '@/js/store/store';

const props = defineProps({
  stage: String,
  player: Object,
});

const isReady = ref(false);
let preview = ref([0, 1, 2]);

function ready() {
  isReady.value = true;
  gameEventEmitter.emit(OutboundServerEventEnum.Ready);
}

function getPreviewLength() {
  if (props.player.pickedSpells.length === 3) return 0;
  if (props.player.pickedSpells.length === 2) return Math.max(props.player.maxCountPickedSpell - 2, 0);
  if (props.player.pickedSpells.length === 1) return Math.max(props.player.maxCountPickedSpell - 1, 0);
  if (props.player.pickedSpells.length === 0) return props.player.maxCountPickedSpell;
}

function removeSpellFromPickedSpells() {
  preview.value.length = getPreviewLength();
}

function pickSpell(element) {
  const cardId = Object.values(element.item.attributes).find((attr) => attr.name === 'data-id').value;
  removeSpellFromPickedSpells();
  // Client side prediction
  // gameEventEmitter.emit(ClientGameNotificationEventEnum.AddSpellToPicked, cardId);

  gameEventEmitter.emit(OutboundServerEventEnum.PickSpell, cardId);
}

function backToHand() {
  preview.value.length = getPreviewLength();
}
</script>

<style scoped>
.pickedSpellsBlock {
  overflow: hidden;
  height: 200px;
  width: 550px;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.card-in-row {
  float: left;
  width: 130px;
  height: 120px;
}
</style>

<template>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <modal v-if="showModal">
      <div class="mages-preview">
        <f7-row>
          <f7-col>
            <div class="mages-picker section-background" @click="closePreview()">
              <f7-block>
                <RulesComponent></RulesComponent>
              </f7-block>
            </div>
          </f7-col>
        </f7-row>
      </div>
    </modal>
  </XyzTransition>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import RulesComponent from '@/components/rules/rules.component.vue';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

let showModal = ref(true);

function setItem(data) {
  showModal.value = !!data;
}

function closePreview() {
  setItem(null);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.BattleStarted, setItem);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.BattleStarted, setItem);
});
</script>

<style scoped>
.mages-preview {
  display: flex;
  align-items: stretch;
  position: fixed; /* Змінено з absolute на fixed */
  z-index: 99991;
  top: 0; /* Змінено з 50% на 0 */
  left: 0; /* Змінено з 50% на 0 */
  width: 100%; /* Додано ширину на весь екран */
  height: 100%; /* Додано висоту на весь екран */
  background-color: rgba(26, 25, 25, 0.8); /* Змінено кольорову схему на rgba() з прозорістю */
  padding: 10px;
}

.mages-picker {
  display: flex;
  align-items: stretch;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-in-row {
  float: left;
  width: 200px;
  height: 300px;
}
</style>

<template>
  <div class="card"></div>
</template>

<script setup></script>
<style scoped>
.card {
  background: #353536;
  border: 2px solid black;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  opacity: 0.15;
  height: 175px;
  width: 125px;
  justify-content: center;
  margin: 0;
}
</style>

import { createApp } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import VueAnimXyz from '@animxyz/vue3';
import '@animxyz/core';
import Framework7 from 'framework7/lite-bundle';
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

import 'framework7/framework7-bundle.css';

import '../css/app.css';

import App from '../components/app.vue';

Framework7.use(Framework7Vue);

window.onerror = (msg, url, line, col, error) => {
  console.error('msg: ', msg);
  console.error('error: ', error);
};

window.addEventListener('unhandledrejection', (event) => {
  console.error('EVENT: ', event);
});

window.addEventListener('error', (event) => {
  console.error('EVENT: ', event);
});

try {
  const app = createApp(App);
  registerComponents(app);

  app.use(VueAnimXyz);

  app.config.errorHandler = (err, vm, info) => {
    console.error('err: ', err);
  };

  app.mount('#app');
} catch (error) {
  console.error('error: ', error);
}

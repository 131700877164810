<template>
  <Transition name="slide-fade">
    <modal v-if="showModal">
      <div class="scoreboard-preview">
        <div class="scoreboard section-background">
          <button style="width: 15%" @click="closeTable" class="close-button">Закрити</button>
          <h2>Погода: {{ notificationData.name }}</h2>
          <h2>Погодний маг: {{ notificationData.weatherMageName }}</h2>
          <p>Опис: {{ notificationData.description }}</p>
        </div>
      </div>
    </modal>
  </Transition>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

const EAction = {
  Frostbite: 'Максимальна кількість карт на руці тепер дві',
  NastyHeat: 'Завдає 1 шкоду за кожний скарб мага',
  Squall: 'Кожен маг отримує 1 шкоду для кожної картки до бою',
  ShowerOfHail: 'Маючи шанс на 20%, кожен гравець отримує випадкову шкоду',
  Smoke: 'Завдає 1 шкоду кожному магу',
  Storm: 'Завдає випадкової шкоди кожному магу від 1 до 6. Можливий другий удар.',
  Tornado: 'Скидає 3 картки з руки',
  HealthRain: "Кожен живий маг отримує від 1 до 6 здоров'я",
  Breeze: "Відновлює всім 3 здоров'я",
  ClearDay: 'Відновлює кількість максимальних карт на руці. Повертає оригінальний нік.',
  DustStorm: 'До кінця гри ніки та колір змінюються',
  Fog: 'Змішує всі карт до бою між живими магами',
  Icing: 'З можливістю 20%, маг може пропустити хід',
  Mirage: 'З можливістю 20%, маг може отримати скарб',
};

const showModal = ref(false);

const notificationData = reactive({
  name: '',
  weatherMageName: '',
  description: '',
});

function setData({ name, weatherMageName }) {
  showModal.value = !!name;

  if (!name) return;

  notificationData.name = name;
  notificationData.weatherMageName = weatherMageName;
  notificationData.description = EAction[name];
}

function closeTable() {
  showModal.value = false;
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.WeatherNotification, setData);
});

onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.WeatherNotification, setData);
});
</script>

<style>
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #555;
}
.scoreboard {
  position: relative;
  width: 50%;
  height: 50%;
  padding: 10px;
  overflow: auto;
}

.scoreboard-preview {
  display: flex;
  align-items: stretch;
  position: fixed; /* Змінено з absolute на fixed */
  z-index: 9999;
  top: 0; /* Змінено з 50% на 0 */
  left: 0; /* Змінено з 50% на 0 */
  width: 100%; /* Додано ширину на весь екран */
  height: 100%; /* Додано висоту на весь екран */
  background-color: rgba(26, 25, 25, 0.8); /* Змінено кольорову схему на rgba() з прозорістю */
  padding: 10px;
}
</style>

<template>
  <div class="mage-hero section-background">
    <strong>{{ mageHero.name }}</strong>
    <div class="mage-hero-avatar" :style="getImgStyle(mageHero.key)"></div>
    <div v-for="action in mageHero.actions">
      <p class="mage-hero-description display-body">{{ getActionDescription(action) }}</p>
    </div>
  </div>
</template>

<script setup>
import { imageCardShadowStyle } from '@/js/game-content/static/game-static';
import { getActionDescription } from '@/js/card';
import { config } from '@/config';

const props = defineProps({
  mageHero: Object,
});

function getImgStyle(key) {
  const imgUrl = `${config.staticRoot}/mages/${key}`;

  return imageCardShadowStyle(imgUrl);
}
</script>

<style scoped>
.mage-hero {
  height: 270px;
  width: 170px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}

.display-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 150px;
  background: linear-gradient(#37373b, #1a1a1f);
  box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.4);
  height: 18px;
}

.display-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mage-hero:hover {
  border: 3px solid #f1546e;
  transform: scale(1.1);
}

.mage-hero-avatar {
  height: 100px;
}

.mage-hero-name {
  font-size: 24px;
  font-weight: bold;
}

.mage-hero-description {
  font-size: 14px;
  margin-top: 10px;
}
</style>

export const ActionUaEnum = {
  SnatchRandomTreasure: 'Вкради випадковий скарб',
  GetRandomTreasure: 'Отримай скарб',
  Hit: 'Шкода',
  Resurrect: 'Воскресіння',
  SkipTurn: 'Пропусти хід',
  SnatchShield: 'Вкради щит',
  BalanceHealth: "Балансувати здоров'я",
  HealForUniqueSign: 'Лікування за унікальним коліром',
  HitForUniqueSign: 'Шкода за унікальним коліром',
  HitForEachSign: 'Шкода за кожним коліром',
  Heal: 'Лікування',
  IncreasePoints: 'Збільшити кількість очок',
  DropSpellFromHand: 'Відкинути заклинання з руки',
  DropRandomTreasure: 'Відкинути випадковий скарб',
  GetRandomSpellFromHand: 'Отримай випадкове заклинання з руки',
  RollAndGetDamage: 'Кидай кубик і отримай шкоду',
  HitForEachAliveMage: 'Шкода за кожного живого мага',
  HitForEachTreasure: 'Шкода за кожен скарб',
  AddValueForDicesValue: 'Додати значення до кубиків',
  RollAndGetTreasure: 'Кидай кубик і отримай скарб',
  DropRandomSpellFromPickedSpells: 'Відкинути випадкове заклинання з вибраних',
  HitForEachShieldAndDestroy: 'Шкода за кожен щит і знищення щита',
  GetCastle: 'Отримай замок',
  GetShield: 'Отримай щит',
  RollAndHeal: 'Кидай кубик і отримай лікування',
  SnatchHealth: "Вкради здоров'я",
  RollAndGetShield: 'Кидай кубик і отримай щит',
  GetRandomSpellFromDeckToPicked: 'Отримай випадкове заклинання з колоди в вибрані',
  GetDeadMage: 'Отримай дохлого мага',
  GetRandomSpellFromDeckToHand: 'Отримай випадкове заклинання з колоди в руку',

  IncreaseMaxHealth: "Збільшить максимальне здоров'я",
  IncreaseDefaultPointsForTurn: 'Збільшить кількість очок за хід',

  HitForCastle: 'Шкода за замок',
  IncreaseMaxCountSpellOnHand: 'Збільшить максимальну кількість заклинань в руці',

  SnatchBlood: 'Вкради кров',
  DropBlood: 'Відкинь кров',
  DropDrainBlood: 'Злити кров',
  GetBlood: 'Отримай кров',
  DrainBlood: 'Злити кров',

  DropTargetTreasure: 'Відкинути скарб',
  SnatchTargetTreasure: 'Вкради скарб цілі',
  ChooseBetween: 'Обери дію',
};

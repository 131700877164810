import { gameEventEmitter } from '../game-event-emitter';

import { CardType, NotificationType } from '../game-content/static/game-static';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

class NotificationService {
  constructor() {
    this.#initNotificationListeners();
  }

  #initNotificationListeners() {
    gameEventEmitter.addListener(ClientGameNotificationEventEnum.NotifyPlayers, ({ message }) => {
      gameEventEmitter.emit(ClientEventEnum.EmitNotification, { type: NotificationType.Text, message });
    });

    gameEventEmitter.addListener(ClientGameNotificationEventEnum.PrepareSpells, () => {
      gameEventEmitter.emit(ClientEventEnum.EmitNotification, { type: CardType.Spell, message: 'Prepare spells!' });
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'prepare-skills-notification');
    });

    gameEventEmitter.addListener(ClientGameNotificationEventEnum.Resurrect, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'resurrect');
    });

    gameEventEmitter.addListener(ClientGameNotificationEventEnum.PlayerRollDices, (dices) => {
      const templateText = dices.reduce(
        (sum, current, index) => (sum += `🎲 Кубик ${index + 1}: Результат ${current}. `),
        '',
      );
      const sum = dices.reduce((sum, current) => (sum += current));

      const message = `${templateText}Загальна сума ${sum}`;

      gameEventEmitter.emit(ClientEventEnum.EmitNotification, { type: NotificationType.RollDices, message });
    });
  }
}

export { NotificationService };

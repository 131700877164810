import io from 'socket.io-client';
import { config } from '@/config';

export class SocketLobby {
  constructor() {
    return SocketLobby.instance();
  }

  static instance() {
    if (!SocketLobby._instance) {
      if (!config.gatewayUrl || !config.gatewayPort) {
        console.error('config.gatewayUrl, ', config.gatewayUrl);
        console.error('config.gatewayPort, ', config.gatewayPort);

        throw new Error('Not defined gateway url');
      }

      SocketLobby._instance = io(`${config.gatewayUrl}:${config.gatewayPort}`);
    }
    return SocketLobby._instance;
  }

  static emit(event, data) {
    if (this._instance.disconnected) {
      this._instance.connect();
    }

    return this._instance.emit(event, data);
  }

  static on() {
    return this._instance.on;
  }

  static disconnect() {
    this._instance.disconnect();
  }

  static id() {
    return this._instance.id;
  }
}

const ClientEventEnum = {
  SetTargets: 'set-targets',
  SpecialEffect: 'special-effect',
  GetCardPreview: 'get-card-preview',
  ShowCardDetails: 'show-card-details',
  InitLoadingScreen: 'init-loading-screen',
  EmitNotification: 'emit-notification',
  GetPlayerPreview: 'get-player-preview',
  AddEffect: 'add-effect',
  //  RemoveSpellFromPicked: 'remove-spell-from-picked',
};

export { ClientEventEnum };

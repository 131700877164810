import { reactive, ref } from 'vue';

const state = {
  username: ref(null),
  roomId: ref(null),

  player: ref(null),
  players: ref([]),

  sessionInfo: ref(null),
  availableTypes: ref(null),

  spells: reactive({}),
  treasures: reactive({}),
  deadMages: reactive({}),
  mageHeroes: reactive({}),

  gamePort: ref(null),
  gameUrl: ref(null),
  status: ref(null),

  isCreatedGame: ref(false),

  accessToken: ref(''),
  refreshToken: ref(''),

  token: ref(null),
  isReconnected: ref(null),
  account: reactive({}),
};

const getters = {
  spells: () => state.spells,
  treasures: () => state.treasures,
  deadMages: () => state.deadMages,
  mageHeroes: () => state.mageHeroes,
  username: () => localStorage.getItem('username') || state.username.value,
  players: () => state.players.value,
  player: () => state.player.value,
  status: () => state.status.value,
  roomId: () => localStorage.getItem('roomId') || state.roomId.value,
  token: () => localStorage.getItem('token') || state.token.value,
  sessionInfo: () => state.sessionInfo.value,
  gamePort: () => localStorage.getItem('gamePort') || state.gamePort.value,
  gameUrl: () => localStorage.getItem('gameUrl') || state.gameUrl.value,
  isCreatedGame: () => state.isCreatedGame.value,
  isReconnected: () => state.isReconnected.value,
  account: () => state.account,
  accessToken: () => localStorage.getItem('accessToken') || state.accessToken.value,
  refreshToken: () => localStorage.getItem('refreshToken') || state.refreshToken.value,
};

const actions = {
  setAuthToken(accessToken, refreshToken) {
    state.accessToken.value = accessToken;
    state.refreshToken.value = refreshToken;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  },
  setAccount(account) {
    state.account = account;
  },
  setUsername(username) {
    localStorage.setItem('username', username);
    state.username.value = username;
  },
  setStatus(status) {
    state.status.value = status;
  },
  setSessionInfo(sessionInfo) {
    state.sessionInfo.value = sessionInfo;
  },
  setPlayers(players) {
    state.players.value = players;
  },
  setToken(token) {
    localStorage.setItem('token', token);
    state.token.value = token;
  },
  updatePlayers(players) {
    const playersDict = players.reduce((accum, player) => {
      accum[player.id] = player;
      return accum;
    }, {});
    state.players.value = state.players.value.map((player) => ({
      ...player,
      ...playersDict[player.id],
    }));
  },
  addPlayer(player) {
    state.players.value.push(player);
  },
  setRoomId(roomId) {
    state.roomId.value = roomId;
    localStorage.setItem('roomId', roomId);
  },
  setIsCreatedGame(isCreatedGame) {
    state.isCreatedGame.value = isCreatedGame;
  },
  setPlayer(player) {
    state.player.value = player;
  },
  setSpells(spells) {
    state.spells = spells;
  },
  setDeadMages(deadMages) {
    state.deadMages = deadMages;
  },
  setMageHeroes(mageHeroes) {
    state.mageHeroes = mageHeroes;
  },
  setTreasures(treasures) {
    state.treasures = treasures;
  },
  setGamePort(port) {
    localStorage.setItem('gamePort', port);
    state.gamePort.value = port;
  },
  setGameUrl(url) {
    localStorage.setItem('gameUrl', url);
    state.gameUrl.value = url;
  },
  setIsReconnected(value) {
    state.isReconnected.value = value;
  },
};

export { getters, actions, state };

<template>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom duration-10 stagger">
    <div class="effect-position" v-if="specialEffects[SpecialEffectEnum.getHeal].status">
      <strong style="background-color: green">{{ specialEffects[SpecialEffectEnum.getHeal].value }}💚</strong>
    </div>
  </XyzTransition>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <div class="effect-position" v-if="specialEffects[SpecialEffectEnum.getDamage].status">
      <strong style="background-color: red">{{ specialEffects[SpecialEffectEnum.getDamage].value }}🤜🏽</strong>
    </div>
  </XyzTransition>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <div class="effect-position" v-if="specialEffects[SpecialEffectEnum.getTreasure].status">
      <strong style="background-color: yellow">{{ specialEffects[SpecialEffectEnum.getTreasure].value }}🏆</strong>
    </div>
  </XyzTransition>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <div class="effect-position" v-if="specialEffects[SpecialEffectEnum.getShield].status">
      <strong style="background-color: blue">{{ specialEffects[SpecialEffectEnum.getShield].value }}🛡️</strong>
    </div>
  </XyzTransition>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <div class="effect-position" v-if="specialEffects[SpecialEffectEnum.blockDamage].status">
      <strong style="background-color: blue">{{ specialEffects[SpecialEffectEnum.blockDamage].value }}🙅</strong>
    </div>
  </XyzTransition>
  <div
    @click="setPlayerItem(player)"
    class="player-preview"
    :class="specialEffect"
    :style="{ 'background-color': player.color }"
  >
    <div class="btn">
      <f7-row>
        <f7-col class="player-attr">
          <p v-if="player.wins" class="player-menu-attr-text">🥇</p>
          <p class="player-menu-attr-text">{{ player.health }}{{ player.health > 0 ? '💚' : '💀' }}</p>
          <p class="player-menu-attr-text">{{ player.shield > 0 ? `${player.shield}🛡️` : '' }}</p>
          <p v-if="player.mageStatus === 'awaiting' && player.played">✅</p>
        </f7-col>
        <!--  <f7-col>
                    <img
                        title="Маг отключился от игры"
                        style="max-width: 40px; max-height: 30px"
                        v-if="player.disconnected"
                        src="https://img.icons8.com/ios-filled/50/000000/no-network.png"
                    />
                  </f7-col> -->
      </f7-row>
    </div>
  </div>
</template>

<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, ref } from 'vue';
import { SpecialEffectEnum } from '@/js/enum/special-effect-enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

const props = defineProps({
  player: Object,
});

const specialEffects = ref({
  [SpecialEffectEnum.getHeal]: { status: false, value: 0 },
  [SpecialEffectEnum.getDamage]: { status: false, value: 0 },
  [SpecialEffectEnum.getShield]: { status: false, value: 0 },
  [SpecialEffectEnum.getTreasure]: { status: false, value: 0 },
  [SpecialEffectEnum.getDeadMage]: { status: false, value: 0 },
  [SpecialEffectEnum.blockDamage]: { status: false, value: 0 },
});

const defaultEffectName = 'player-preview-effect';
const animationDuration = 1500;

const specialEffect = ref(defaultEffectName);

function removeEffect({ playerId, effect }) {
  setTimeout(() => {
    if (playerId !== props.player.id) return;

    specialEffect.value = defaultEffectName;

    specialEffects.value[effect].status = false;
    specialEffects.value[effect].value = 0;
  }, animationDuration);
}

function setPlayerItem(item) {
  gameEventEmitter.emit(ClientEventEnum.GetPlayerPreview, null);
  gameEventEmitter.emit(ClientEventEnum.GetPlayerPreview, item);
}

function addEffect({ value, playerId, effect }) {
  if (playerId !== props.player.id) return;

  specialEffect.value = effect;
  specialEffects.value[effect].status = true;
  specialEffects.value[effect].value = value;

  removeEffect({ playerId, effect });
}

onMounted(() => {
  gameEventEmitter.addListener(ClientEventEnum.AddEffect, addEffect);
});

onUnmounted(() => {
  gameEventEmitter.removeListener(ClientEventEnum.AddEffect, addEffect);
});
</script>

<style scoped>
.effect-position {
  width: 75px;
  height: 75px;
  position: absolute;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-preview {
  flex: 0 0 auto;
  perspective: 300px;
  max-width: 160px;
  width: 75px;
  height: 75px;
  border: 3px solid #070707;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2a2a2a;
}

.player-preview-effect {
}

.player-menu-attr-text {
  color: white;
  text-shadow: 1px 1px 2px #1a1e1a;
  font-size: 10px;
}

.player-preview-get-damage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.player-preview-get-damage::before {
  opacity: 0;
}

.player-preview-get-damage {
  animation: red-border 2s linear;
}

@keyframes red-border {
  0% {
    border-color: #070707;
  }
  50% {
    border-color: red;
  }
  100% {
    border-color: #070707;
  }
}

.player-attr {
  text-align: center;
  vertical-align: text-top;
  align-items: center;
  justify-content: center;
}
/* heal */
.player-preview-get-heal::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.player-preview-get-heal::before {
  opacity: 0;
}

.player-preview-get-heal {
  animation: green-border 2s linear;
}

@keyframes green-border {
  0% {
    border-color: #070707;
  }
  50% {
    border-color: #07de04;
  }
  100% {
    border-color: #070707;
  }
}

/* shield */

.player-preview-get-shield::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.player-preview-get-shield::before {
  opacity: 0;
}

.player-preview-get-shield {
  animation: blue-border 2s linear;
}

@keyframes blue-border {
  0% {
    border-color: #070707;
  }
  50% {
    border-color: #023af5;
  }
  100% {
    border-color: #070707;
  }
}

/* treasure */
.player-preview-get-treasure::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.player-preview-get-treasure::before {
  opacity: 0;
}

.player-preview-get-treasure {
  animation: yellow-border 2s linear;
}

@keyframes yellow-border {
  0% {
    border-color: #070707;
  }
  50% {
    border-color: #f5ff1b;
  }
  100% {
    border-color: #070707;
  }
}

/* block-damage */
.player-preview-block-damage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.player-preview-block-damage::before {
  opacity: 0;
}

.player-preview-block-damage {
  animation: purple-border 2s linear;
}

@keyframes purple-border {
  0% {
    border-color: #070707;
  }
  50% {
    border-color: #c602f3;
  }
  100% {
    border-color: #070707;
  }
}

/* dead-mage */
.player-preview-get-dead-mage::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.player-preview-get-dead-mage::before {
  opacity: 0;
}

.player-preview-get-dead-mage {
  animation: white-border 2s linear;
}

@keyframes white-border {
  0% {
    border-color: #070707;
  }
  50% {
    border-color: #f5f5f5;
  }
  100% {
    border-color: #070707;
  }
}

@media only screen and (max-width: 1050px) {
}
</style>

<template>
  <XyzTransition appear xyz="fade">
    <modal v-if="showModal">
      <f7-block class="component-preview">
        <f7-col>
          <div class="use-spells-preview center-text section-background">
            <f7-block>
              <TauntComponent></TauntComponent>
              <f7-row>
                <div class="card-in-row" v-for="card in cards.filter((c) => c.cardType === 'Spell')" :key="card.id">
                  <div>
                    <strong v-if="card.isDropped">Cкинув: {{ card.droppedBy }}</strong>
                  </div>
                  <CardPreview
                    :class="[
                      card.hasClicked ? (card.hasClicked && card.hasPlayed ? 'card-played' : 'card-clicked') : '',
                    ]"
                    @click="useCard(card)"
                    :item="card"
                    :show-targets="true"
                  ></CardPreview>
                  <div>
                    <f7-button
                      v-if="
                        !card.hasClicked &&
                        !card.hasDrainBlood &&
                        card.actions.find((action) => action?.actionCondition?.type === 'blood')
                      "
                      @click="drainBlood(card)"
                      fill
                      color="red"
                      style="width: 100%"
                    >
                      <strong>Віддати кров</strong>
                    </f7-button>
                  </div>
                </div>
              </f7-row>
            </f7-block>
          </div>
        </f7-col>
      </f7-block>
    </modal>
  </XyzTransition>
</template>

<script setup>
import CardPreview from '@/components/card/card-preview';
import TauntComponent from '@/components/sound/taunt-component.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { CardType } from '@/js/game-content/static/game-static';
import { f7 } from 'framework7-vue';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';

const props = defineProps({
  cards: Array,
});

let showModal = ref(false);

function setItem(data) {
  showModal.value = !!data;
}

function setHasPlayed(spellId) {
  const spell = props.cards.find((card) => card.id === spellId);

  if (!spell) return;

  spell.hasPlayed = true;
  spell.hasClicked = true;
}

function drainBlood(card) {
  const cardIndex = props.cards.findIndex((c) => c.id === card.id);

  const previousPlayedCard = cardIndex === 0 ? true : props.cards[cardIndex - 1].hasPlayed;

  if (!previousPlayedCard) {
    f7.dialog.alert('Зіграй попередню карту!');
    return;
  }

  props.cards[cardIndex].hasDrainBlood = true;

  gameEventEmitter.emit(OutboundServerEventEnum.DrainBlood, { spellId: card.id });
}

function useCard(card) {
  if (card.cardType !== CardType.Spell) return;

  const cardIndex = props.cards.findIndex((c) => c.id === card.id);

  const previousPlayedCard = cardIndex === 0 ? true : props.cards[cardIndex - 1].hasPlayed;

  if (!previousPlayedCard) {
    f7.dialog.alert('Зіграй попередню карту!');
    return;
  }

  gameEventEmitter.emit(OutboundServerEventEnum.UseSpell, { spellId: card.id });

  if (props.cards.every((card) => card.hasClicked)) setItem(null);
  if (!props?.cards?.length) setItem(null);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PlayerPlayingSpells, setItem);
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.SpellPlayed, setHasPlayed);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PlayerPlayingSpells, setItem);
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.SpellPlayed, setHasPlayed);
});
</script>

<style scoped>
.use-spells-preview {
  display: flex;
  align-items: stretch;
  position: absolute;
  z-index: 9997;
  width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-in-row {
  float: left;
  width: 200px;
  height: 300px;
}

.card-in-row:hover {
  cursor: grab;
}

.card-clicked {
  opacity: 0.7;
}

.card-played {
  opacity: 0.7;
  border: 2px solid red;
}
</style>

export const StageUaEnum = {
  GAME_PREPARATION: 'Підготовка до гри',
  START_BATTLE: 'Початок махача',
  START_ROUND: 'Початок раунда',
  SPELLS_PREPARATION: 'Підготовка заклинання',
  PLAYING_SPELLS: 'Гра заклинаннями',
  PLAYING_DEAD_MAGES: 'Гра дохлими магами',
  FINISH_DEAD_MAGES: 'Завершення гри дохлими магами',
  END_ROUND: 'Завершення раунда',
  END_BATTLE: 'Завершення махача',
  END_GAME: 'Завершення гри',
  TREASURES_ACTION_ON_PLAYER_TURN: 'Дії скарбів на ході гравця',
};

const ClientGameNotificationEventEnum = {
  BattleStarted: 'battle-started',
  PlayAudio: 'play-audio',
  PlayerPlayingSpells: 'player-playing-spells',
  PlayerRollDices: 'player-roll-dices',
  PrepareSpells: 'prepare-spells',

  Pause: 'pause',
  Unpause: 'unpause',
  RoundEnded: 'round-ended',
  RoundStarted: 'round-started',
  GetTreasure: 'get-treasure',
  GetDeadMage: 'get-dead-mage',
  GetShield: 'get-shield',
  GetHeal: 'get-heal',
  GetDamage: 'get-damage',
  MakeDamage: 'make-damage',
  BlockDamage: 'block-damage',
  PickMages: 'pick-mages',
  ShowReconnect: 'show-reconnect',
  StopTimer: 'stop-timer',
  StartTimer: 'start-timer',
  AddSpellToHand: 'add-spells-to-hand',
  GetPersonalTargets: 'get-personal-targets',
  GetTargets: 'get-targets',
  PlayedCardsCurrentRound: 'played-cards-current-round',
  NotifyPlayers: 'notify-players',
  AddSpellToPicked: 'add-spell-to-picked',
  RemoveSpellFromHand: 'remove-spell-from-hand',
  OutOfTreasures: 'out-of-treasures',
  OutOfDeadMages: 'out-of-dead-mages',
  EndGameStatistic: 'end-game-statistic',
  GameStatistic: 'game-statistic',

  PickTreasures: 'pick-treasures',
  NotEnoughBlood: 'not-enough-blood',
  ChooseCard: 'choose-card',

  CanUseSpell: 'can-use-spell',
  SpellPlayed: 'spell-played',

  SomethingWentWrong: 'game-something-went-wrong',

  PlayerKilled: 'player-killed',
  PlayerCommitmentSuicide: 'player-commitment_suicide',
  PlayerUltraKill: 'player-ultra-kill',
  PlayerDoubleKill: 'player-double-kill',
  PlayerMegaKill: 'player-mega-kill',
  PlayerMonsterKill: 'player-monster-kill',
  FirstBlood: 'first-blood',

  Resurrect: 'resurrect',
  PickPersonalTarget: 'client-pick-personal-target',

  WeatherNotification: 'client-weather-notification',
  PickPersonalTargetComplete: 'client-pick-personal-target-complete',
  PickTreasuresComplete: 'client-pick-treasures-complete',
  ChooseCardComplete: 'client-choose-card-complete',
};

export { ClientGameNotificationEventEnum };

<template>
  <modal v-if="showModal">
    <div class="player-preview">
      <f7-block>
        <f7-row>
          <h1>Обери гравця</h1>
        </f7-row>
        <p>
          <strong>Карта: {{ card.name }} | {{ cardAction }}</strong>
        </p>
        <f7-row class="section-background">
          <div :data-id="player.id" class="card-in-row" v-for="player in players" :key="player.id">
            <PlayerTarget @click="pickTarget(player.id)" :player="player"></PlayerTarget>
          </div>
        </f7-row>
      </f7-block>
    </div>
  </modal>
</template>

<script setup>
import PlayerTarget from './personal-target';
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { getPlayers } from '@/js/game-session/players/players';
import { getCard } from '@/js/game-session/sessionInfo/sessionInfo';
import { getAction } from '@/js/card';

let players = ref([]);
let showModal = ref(false);

let cardId = null;
let action = ref(null);

let card = ref(null);
let cardAction = ref(null);
let timer = null;

function setItem(data) {
  showModal.value = !!data;
  players.value = data;
}

function setPersonalTargets({ spellId, targetIds, actionId, cardType }) {
  const players = getPlayers().filter((player) => targetIds.includes(player.id));

  setItem(players);

  cardId = spellId;
  action.value = actionId;

  card.value = getCard(cardType, spellId);
  cardAction.value = getAction(card.value.actions.find((action) => action.id === actionId).type);

  timer = setTimeout(() => {
    console.log('pickTarget: ', players[0].id);
    pickTarget(players[0].id);
  }, 20_000);
}

function pickTarget(playerId) {
  gameEventEmitter.emit(OutboundServerEventEnum.PickPersonalTarget, {
    spellId: cardId,
    targetId: playerId,
    actionId: action.value,
  });

  closeWindow();
}

function closeWindow() {
  setItem(null);
  clearTimeout(timer);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PickPersonalTarget, setPersonalTargets);
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PickPersonalTargetComplete, closeWindow);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PickPersonalTarget, setPersonalTargets);
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PickPersonalTargetComplete, closeWindow);
});
</script>

<style scoped>
.player-preview {
  display: flex;
  align-items: stretch;
  position: fixed;
  z-index: 999999;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px; /* Додати відступи від бордера до контенту таблиці */
  background-color: rgba(26, 25, 25, 1); /* Змінено кольорову схему на rgba() з прозорістю */
}

.card-in-row {
  float: left;
  width: 170px;
  height: 80px;
}
</style>

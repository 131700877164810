import { getters } from '@/js/store/store';

function getPlayers() {
  const players = getters.players() || [];
  const treasures = getters.treasures();
  const step = (2 * Math.PI) / players.length;

  return players.map((player, index) => ({
    ...player,
    treasures: player?.treasures
      ? player?.treasures?.map((treasure) => ({
          ...treasures[treasure.id],
        }))
      : [],
    style: {
      left: `${50 + 180 * Math.cos(index * step)}px`,
      top: `${50 + 180 * Math.sin(index * step)}px`,
    },
  }));
}

export { getPlayers };

<template>
  <HistoryComponent />
  <div class="block section-background reverseOrder">
    <div class="card-in-column" v-for="cardInfo in list" :key="cardInfo.id">
      <CardIcon @click="setItem(cardInfo)" :item="cardInfo.card" :show-targets="false"></CardIcon>
    </div>
  </div>
</template>

<script setup>
import HistoryComponent from '../../components/history/history-component';
import CardIcon from '../card/card-icon';
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { getCard } from '@/js/game-session/sessionInfo/sessionInfo';
import { getPlayerById } from '@/js/game-session/player/player';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

const size = 8;
const list = ref([]);

function setItem(item) {
  gameEventEmitter.emit(ClientEventEnum.ShowCardDetails, item);
}

function addElementToList(element) {
  const lastElement = list.value[list.value.length - 1];
  const card = lastElement?.cardId === element.cardId;

  if (card) {
    lastElement.container.push(...element.container);
    return;
  }

  if (list.value.length >= size) {
    list.value.shift();
  }

  list.value.push(element);
}

function addCard(card) {
  if (!card.cardType) return;

  const item = {
    ...card,
    player: getPlayerById(card.playerId),
    card: { ...getCard(card.cardType, card.cardId), cardType: card.cardType },
    container: [
      {
        targetsBefore: card.targetsBefore,
        targetsAfter: card.targetsAfter.map((target) => ({
          ...target,
          actions: target.actions.map((action) => ({
            ...action,
            cards: action?.cards?.map((card) => getCard(card.cardType, card.id)),
          })),
        })),
      },
    ],
  };

  addElementToList(item);
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.PlayedCardsCurrentRound, addCard);
});

onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.PlayedCardsCurrentRound, addCard);
});
</script>

<style scoped>
.block {
  height: 300px;
}
.card-in-column {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  height: 30px;
}

.reverseOrder {
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
}
</style>

<template>
  <f7-block class="spellOnHandsBlock">
    <f7-row>
      <f7-col>
        <div class="cards">
          <draggable handle=".handle" :list="player.spellsOnHand" group="card" @add="addToPicked">
            <div class="card" :data-id="card.id" v-for="card in player.spellsOnHand" :key="card.id">
              <Card
                :class="[
                  isSpellsPreparationStage() && isAvailableCard(card) ? 'handle card-face card-available' : 'card-face',
                ]"
                :item="card"
              />
            </div>
          </draggable>
        </div>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { defineComponent } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';

export default defineComponent({
  components: {
    draggable: VueDraggableNext,
  },
});
</script>

<script setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';
import Card from '../card/card';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { getPlayer } from '@/js/game-session/player/player';
import { GameStageEnum } from '@/js/enum/game-stage.enum';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';
import { getters } from '@/js/store/store';

const props = defineProps({
  player: Object,
  stage: String,
});

function isAvailableCard(card) {
  const pickedSpellsSize = props.player.pickedSpells.length >= props.player.maxCountPickedSpell;
  return !pickedSpellsSize && props.player.availableSpellTypes.includes(card.type);
}

function isSpellsPreparationStage() {
  return props.stage === GameStageEnum.SPELLS_PREPARATION;
}

function addToPicked(element) {
  if (props.stage !== GameStageEnum.SPELLS_PREPARATION) return;

  const cardId = Object.values(element.item.attributes).find((attr) => attr.name === 'data-id').value;

  // client side prediction
  // gameEventEmitter.emit(ClientGameNotificationEventEnum.AddSpellToHand, cardId);

  gameEventEmitter.emit(OutboundServerEventEnum.BackToHand, cardId);
}
</script>

<style scoped lang="scss">
.cards {
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  position: fixed;
}

.card {
  visibility: hidden;
  height: 135px;
  width: 85px;
  float: left;
}

.card-face {
  visibility: visible;
  bottom: 0;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  transition: 800ms cubic-bezier(0.19, 1, 0.22, 1) transform;
}

@keyframes fade {
  0% {
    opacity: 0.9;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.15);
  }
}

.spellOnHandsBlock {
  overflow: hidden;
  z-index: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translate(50%, -150%);
  opacity: 0;
}

.card-available:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 1px 2px 0 0 rgba(0, 255, 0, 0.7);
  animation: pulse 1.3s infinite;
}

.card-available:hover {
  transform: scale(1.1);
}

.card-available:hover:after {
  animation: none;
}

@keyframes pulse {
  0% {
    opacity: 0.2;
    box-shadow: inset 0 0 0 0 rgba(0, 255, 0, 0.7);
  }
  50% {
    box-shadow: inset 0 0 0 10px rgba(95, 176, 95, 0.7);
    opacity: 0.7;
  }
  100% {
    box-shadow: inset 0 0 0 0 rgba(9, 94, 9, 0.7);
    opacity: 0.1;
  }
}
</style>

const InboundGameServerEventEnum = {
  ReturnBackToGame: 'return-back-to-game',
  AddPlayerToList: 'add-player-to-list',
  UpdatePlayerList: 'update-player-list',
  UpdateGameSessionInfo: 'update-game-session-info',
  CreatePlayersList: 'create-players-list',
  PlayTauntAudio: 'play-taunt-audio',
  UpdatePlayerState: 'update-player-state',
  AuthToken: 'auth-token',
  CannotReconnect: 'cannot-reconnect',
  WeatherNotification: 'weather-notification',
  //  AddPerks: 'add-perks',
};

export { InboundGameServerEventEnum };

<template>
  <Transition name="slide-fade">
    <modal v-if="showModal">
      <div class="scoreboard-preview">
        <div class="scoreboard section-background">
          <button style="width: 15%" @click="closeTable" class="close-button">Закрити</button>
          <BasicTableScoreComponent :players="tableScoreData.players"></BasicTableScoreComponent>
          <GameTableScoreComponent :tableScoreData="tableScoreData"></GameTableScoreComponent>
        </div>
      </div>
    </modal>
  </Transition>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import BasicTableScoreComponent from './players-tablescore.component';
import GameTableScoreComponent from './game-tablescore.component';

const showModal = ref(false);

const tableScoreData = ref({
  players: [],
  currentBattle: 0,
  currentRound: 0,
  gameDuration: 0,
  treasuresCount: 0,
  deadMageCount: 0,
});

function setTableScoreData(data) {
  showModal.value = !!data;
  tableScoreData.value = data;
}

function closeTable() {
  showModal.value = false;
}

onMounted(() => {
  gameEventEmitter.addListener(ClientGameNotificationEventEnum.GameStatistic, setTableScoreData);
});

onUnmounted(() => {
  gameEventEmitter.removeListener(ClientGameNotificationEventEnum.GameStatistic, setTableScoreData);
});
</script>

<style>
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #555;
}
.scoreboard {
  position: relative;
  width: 50%;
  height: 50%;
  padding: 10px;
  overflow: auto;
}

.scoreboard-preview {
  display: flex;
  align-items: stretch;
  position: fixed; /* Змінено з absolute на fixed */
  z-index: 9999;
  top: 0; /* Змінено з 50% на 0 */
  left: 0; /* Змінено з 50% на 0 */
  width: 100%; /* Додано ширину на весь екран */
  height: 100%; /* Додано висоту на весь екран */
  background-color: rgba(26, 25, 25, 0.8); /* Змінено кольорову схему на rgba() з прозорістю */
  padding: 10px;
}
</style>

<template>
  <XyzTransition appear xyz="fade up-100% flip-down flip-right-50% rotate-left-100% origin-bottom">
    <modal v-if="showModal">
      <div class="loading-screen">
        <Rules />
      </div>
    </modal>
  </XyzTransition>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import { gameEventEmitter } from '@/js/game-event-emitter';
import Rules from '@/components/loading-screen/rules';
import { ClientLobbyNotificationEventEnum } from '@/js/enum/server-interaction/client-lobby-notification-event.enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';
import { getDeadMages, getMageHeroes, getSpells, getTreasures } from '@/js/game-content/game-content';
import { actions } from '@/js/store/store';
import { HttpClient } from '@/js/utils/http-client';

let showModal = ref(false);

async function setItem(data) {
  showModal.value = data;

  const currentGameVersion = await getGameVersion();

  const contentList = ['spells', 'treasures', 'deadMages', 'mageHeroes'];
  /*let [spells, treasures, deadMages, mageHeroes] = contentList.map((content) => {
    if (localStorage.getItem(content) === '[object Object]') {
      return null;
    }

    return JSON.parse(localStorage.getItem(content));
  });*/

  // if ([spells, treasures, deadMages, mageHeroes].some((c) => !Object.keys(c || {}).length)) {
  // if (true) {
  // || gameVersion.value.toString() !== currentGameVersion?.version.toString()) {

  const content = await Promise.all([
    await getSpells(),
    await getTreasures(),
    await getDeadMages(),
    await getMageHeroes(),
  ]);

  const spells = content[0];
  const treasures = content[1];
  const deadMages = content[2];
  const mageHeroes = content[3];

  // contentList.forEach((content, index) => localStorage.setItem(content, JSON.stringify(data[index])));
  /* } else {
     console.log('USE CACHE');
   }
 */
  actions.setSpells(spells);
  actions.setTreasures(treasures);
  actions.setDeadMages(deadMages);
  actions.setMageHeroes(mageHeroes);
}

const getGameVersion = async () => {
  return new HttpClient()
    .get('/lobbies/version')
    .then((data) => data)
    .catch((error) => {
      console.error(error);
    });
};

onMounted(async () => {
  gameEventEmitter.addListener(ClientLobbyNotificationEventEnum.CreateGameSession, setItem);
});
onUnmounted(() => {
  gameEventEmitter.removeListener(ClientEventEnum.InitLoadingScreen, setItem);
});
</script>

<style scoped>
.loading-screen {
  display: flex;
  align-items: stretch;
  position: fixed; /* Змінено з absolute на fixed */
  z-index: 9999;
  top: 0; /* Змінено з 50% на 0 */
  left: 0; /* Змінено з 50% на 0 */
  width: 100%; /* Додано ширину на весь екран */
  height: 500px; /* Додано висоту на весь екран */
  background-color: rgba(26, 25, 25, 0.8); /* Змінено кольорову схему на rgba() з прозорістю */
  padding: 10px;
}
</style>

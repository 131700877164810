<template>
  <f7-page class="page-content-lobby">
    <f7-block>
      <f7-row>
        <f7-col width="15" class="sidebar">
          <f7-block>
            <f7-block class="profile-block">
              <h3>Username: {{ account.username }}</h3>
              <p>AVATAR</p>
              <p>Рейтинг: {{ account.rating || 0 }}</p>
            </f7-block>

            <!-- Блок меню -->
            <f7-block class="menu-block">
              <h2>не работает</h2>
              <f7-button @click="navigateTo('lobby')">Лобби</f7-button>
              <f7-button @click="navigateTo('leaderboard')">Лидерборд</f7-button>
              <f7-button @click="navigateTo('profile')">Профиль</f7-button>
              <hr class="solid" />
              <h2>работает</h2>
              <f7-button @click="navigateTo('spells')">Заклинання</f7-button>
              <f7-button @click="navigateTo('treasures')">Скарби</f7-button>
              <f7-button @click="navigateTo('mage-hero')">Маги</f7-button>
              <f7-button @click="navigateTo('dead-mage')">Дохлі колдуни</f7-button>
            </f7-block>
          </f7-block>
        </f7-col>

        <f7-col width="85">
          <f7-row>
            <f7-col>
              <f7-row class="lobby-container">
                <div class="lobby-menu">
                  <f7-col>
                    <f7-row class="section-background lobbyGamesDescriptions">
                      <f7-header>Name</f7-header>
                      <f7-header>Players</f7-header>
                      <f7-header>Mods</f7-header>
                    </f7-row>
                    <div class="list links-list section-background lobbyListOfGames">
                      <p v-if="!lobbyList?.length">Ігор немає, спробуй створити сам</p>
                      <ul v-for="lobby in lobbyList" :key="lobby.roomId">
                        <li>
                          <a href="#" @click="joinLobby(lobby)">
                            <f7-row style="width: 100%">
                              <f7-col width="25">
                                <f7-block-title>{{ lobby.username }}</f7-block-title>
                              </f7-col>
                              <f7-col width="35" style="text-align: center">
                                <f7-block-title>{{ lobby.playersCount }}/{{ lobby.playersLimit }} </f7-block-title>
                              </f7-col>
                              <f7-col width="10">
                                <f7-block-title>{{ lobby.gameModes }}</f7-block-title>
                              </f7-col>
                            </f7-row>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <f7-col style="margin-top: -20px">
                      <f7-row>
                        <p style="font-weight: 600">{{ gameVersion }}</p>
                        <f7-button
                          class="bubbly-button"
                          :class="{ disabled: !isAvailableServer }"
                          large
                          raised
                          fill
                          @click="createGame"
                          ><strong>Create game</strong></f7-button
                        >
                        <f7-button
                          v-if="config.env === 'development'"
                          class="bubbly-button"
                          large
                          raised
                          fill
                          @click="playground"
                          ><strong>playground</strong></f7-button
                        >
                        <f7-button
                          v-if="config.env === 'development'"
                          class="bubbly-button"
                          large
                          raised
                          fill
                          @click="simulation"
                          ><strong>simulation</strong></f7-button
                        >
                        <!--  <f7-button
                          class="bubbly-button"
                          :class="{ disabled: !isAvailableServer }"
                          large
                          raised
                          fill
                          @click="findGame"
                          ><strong>Find game</strong></f7-button
                        > Disabled for future release -->
                        <f7-button
                          v-show="showCanReconnectButton"
                          class="lobbyMainButtons bubbly-button"
                          style="background-color: green"
                          large
                          raised
                          fill
                          @click="reconnectToGame"
                          ><strong>Переподключиться</strong></f7-button
                        >
                      </f7-row>
                      <!--  <f7-row>
                        <f7-button class="lobbyMainButtons bubbly-button" large raised fill @click="checkReplay">Replay</f7-button>
                      </f7-row> -->
                    </f7-col>
                  </f7-col>
                </div>
              </f7-row>
            </f7-col>

            <f7-col>
              <div class="list links-list section-background" style="width: 20%">
                <h3>Гравців онлайн: {{ playersCount }}</h3>
              </div>
              <div class="announcement">
                <f7-row>
                  <f7-col>
                    <div class="list links-list section-background lobbyListOfGames">
                      <f7-header>Дошка оголошень</f7-header>
                      <a class="external link" href="https://t.me/+ZpoUVXvnmqcwNTYy" target="_blank">
                        <span>Телеграм "Бойові маги UA</span>
                      </a>
                      <a class="external link" href="https://discord.gg/VJAFMh5tzG" target="_blank">
                        <span>Діскорд</span>
                      </a>
                      <br />
                      <strong>Bugs</strong>
                      <table>
                        <tr>
                          <td></td>
                        </tr>
                      </table>
                    </div>
                  </f7-col>
                </f7-row>
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { f7 } from 'framework7-vue';
import { HttpClient } from '@/js/utils/http-client';
import { SocketLobby } from '@/js/communication/socket-lobby';
import LoadingScreen from '@/components/loading-screen/loading-screen-component';
import { getDeadMages, getMageHeroes, getSpells, getTreasures } from '@/js/game-content/game-content';
import { config } from '@/config';
import { actions, getters } from '@/js/store/store';
import { ClientLobbyNotificationEventEnum } from '@/js/enum/server-interaction/client-lobby-notification-event.enum';
import { LobbyServerEventEnum } from '@/js/enum/server-interaction/lobby-server-event.enum';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

const gameVersion = ref(config.gameVersion);
const showCanReconnectButton = ref(false);
const isAvailableServer = ref(false);
const serverInfo = ref(0);
const lobbyList = ref([]);
const retries = ref(0);
const maxRetries = ref(5);
const account = getters.account();
const playersCount = ref(0);

let gameSocket = null;
const socket = new SocketLobby();

socket.on(ClientLobbyNotificationEventEnum.UpdateClientsCount, ({ count }) => {
  playersCount.value = count;
});

const createGame = () => {
  actions.setRoomId(socket.id);

  const createGameDto = {
    username: getters.username(),
    roomId: socket.id,
  };

  if (!socket.connected) {
    socket.connect();
  }

  socket.emit(LobbyServerEventEnum.CreateNewGame, createGameDto);

  f7.views.main.router.navigate('/in-game-lobby/');
};

const playground = () => {
  f7.views.main.router.navigate('/playground/');
};

const navigateTo = (routing) => {
  f7.views.main.router.navigate('/content/', {
    props: {
      contentType: routing,
    },
  });
};

const simulation = () => {
  f7.views.main.router.navigate('/game-simulation/');
};

const findGame = () => {
  socket.emit(LobbyServerEventEnum.FindMatch);

  socket.on(ClientLobbyNotificationEventEnum.JoinToLobbySuccess, async ({ roomId }) => {
    actions.setRoomId(roomId);
    actions.setIsCreatedGame(true);
    f7.views.main.router.navigate('/in-game-lobby/');
  });
};

const joinLobby = async (lobby) => {
  console.log('lobby: ', lobby);
  localStorage.setItem('playerId', socket.id);
  actions.setRoomId(lobby.roomId);

  socket.emit(LobbyServerEventEnum.JoinToLobby, { roomId: lobby.roomId, username: getters.username() });
  f7.preloader.show();

  socket.on(ClientLobbyNotificationEventEnum.JoinToLobbySuccess, () => {
    f7.views.main.router.navigate('/in-game-lobby/');
    f7.preloader.hide();
  });
};
const reconnectToGame = async () => {
  f7.preloader.show();

  showCanReconnectButton.value = false;
  actions.setStatus('reconnect');

  const content = await Promise.all([
    await getSpells(),
    await getTreasures(),
    await getDeadMages(),
    await getMageHeroes(),
  ]);
  // fixme
  const spells = content[0];
  const treasures = content[1];
  const deadMages = content[2];
  const mageHeroes = content[3];

  actions.setSpells(spells);
  actions.setTreasures(treasures);
  actions.setDeadMages(deadMages);
  actions.setMageHeroes(mageHeroes);

  setTimeout(() => f7.views.main.router.navigate('/game/'), 1000);
};

const getLobbyList = async () => {
  lobbyList.value = await new HttpClient()
    .get('/lobbies')
    .then((response) => {
      isAvailableServer.value = true;
      return response.data;
    })
    .catch((error) => {
      isAvailableServer.value = false;

      console.error(error);
      f7.dialog.alert('Проблеми із пошуком махачів! Спробуй пізніше');
    });
};

const getServerInfo = async () => {
  serverInfo.value = await new HttpClient()
    .get('/games')
    .then((response) => {
      return `Доступно серверів: ${1 - response.count}.\n Ліміт серверів: 1`;
    })
    .catch((error) => {
      console.error(error);
    });
};

const canReconnect = async () => {
  const gamePort = getters.gamePort();
  const gameUrl = getters.gameUrl();

  if (gamePort && gameUrl) {
    const status = await new HttpClient(`http://${gameUrl}:${gamePort}`)
      .get('/api/health')
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        actions.setGamePort(null);
        actions.setGameUrl(null);

        console.warn('warn: ', error);
      });
    showCanReconnectButton.value = status === 'ok';
  }

  const roomId = getters.roomId();

  if (!showCanReconnectButton.value && roomId) {
    const response = await new HttpClient().get(`/game/${roomId}`).catch((error) => {
      console.warn('warn: ', error);
    });

    if (!response.data) return;

    if (response?.data?.port && response?.data?.url) {
      showCanReconnectButton.value = true;
      actions.setGamePort(response.data.port);
      actions.setGameUrl(response.data.url);
      retries.value = 0;
    } else {
      if (retries.value >= maxRetries.value) return;

      setTimeout(() => canReconnect(), 10_000);
    }
  }
};

onMounted(async () => {
  await getLobbyList();
  await getServerInfo();

  socket.on(ClientLobbyNotificationEventEnum.UpdateLobbyList, async () => {
    await getLobbyList();
    await getServerInfo();
  });

  socket.on(ClientLobbyNotificationEventEnum.SomethingWentWrong, async ({ reason }) => {
    f7.dialog.alert(`Something went wrong. Please try later. Reason: ${reason}`, () =>
      setTimeout(() => {
        f7.views.main.router.navigate('/lobby/');
      }, 1500),
    );
  });

  socket.on(ClientLobbyNotificationEventEnum.LobbyNotifyClient, async ({ event }) => {
    f7.dialog.alert(event);

    const events = [
      ClientLobbyNotificationEventEnum.HostLeaveLobby,
      ClientLobbyNotificationEventEnum.CannotCreateLobby,
      ClientLobbyNotificationEventEnum.KickFromLobby,
    ];

    if (events.includes(event)) f7.views.main.router.navigate('/lobby/');
  });

  socket.on(ClientLobbyNotificationEventEnum.UpdatePlayerList, async ({ players }) => {
    actions.setPlayers(players);
  });

  socket.emit(LobbyServerEventEnum.GetClientCount);

  // await canReconnect();
});

onUnmounted(() => {
  socket.removeAllListeners(ClientLobbyNotificationEventEnum.LobbyNotifyClient);
});
</script>

<style scoped>
.sidebar {
  height: 100%;
  background-color: #3b3b3b;
  padding: 10px;
}

.profile-block {
  text-align: center;
}

.profile-block img {
  max-width: 100px;
  border-radius: 50%;
}

.menu-block {
  margin-top: 20px;
}

.menu-block f7-button {
  margin-bottom: 10px;
}

.lobby-container {
  margin-top: 5px;
  height: 400px;
  width: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.lobbyListOfGames {
  height: 300px;
  overflow: auto;
}

.lobbyGamesDescriptions {
  justify-content: space-around;
}

.page-content-lobby {
  background-image: url('https://d3eliglfhyhzo3.cloudfront.net/site/lobby-background.jpg');
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.lobby-menu {
  flex: 1;
  margin-right: 20px;
}

.announcement {
  flex: 1;
  max-width: 30%;
  min-width: 200px;
}

@media (max-width: 767px) {
  .lobby-container {
    flex-direction: column;
    align-items: center;
  }

  .lobby-menu {
    margin-right: 0;
    width: 100%;
  }

  .announcement {
    max-width: 100%;
    margin-top: 20px;
  }

  .lobbyListOfGames {
    height: 150px;
    overflow: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .lobby-container {
    max-width: 800px;
  }
}

@media (min-width: 992px) {
  .lobby-container {
    max-width: 1200px;
  }

  .lobbyListOfGames {
    height: 250px;
    overflow: auto;
  }
}
</style>

<template>
  <modal v-if="showModal">
    <div class="notification-preview">
      <div class="message">
        <div class="message-body">
          <p>{{ notification.message }}</p>
        </div>
      </div>
    </div>
  </modal>
</template>

let card = ref(null);
<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, ref } from 'vue';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

let showModal = ref(false);
const notification = ref({});

function setItem(dto) {
  showModal.value = !!dto;

  if (!dto) return;

  notification.value = { type: dto.type, message: dto.message };

  setTimeout(() => {
    gameEventEmitter.emit(ClientEventEnum.EmitNotification, null);
  }, 3500);
}

onMounted(() => gameEventEmitter.addListener(ClientEventEnum.EmitNotification, setItem));
onUnmounted(() => gameEventEmitter.removeListener(ClientEventEnum.EmitNotification, setItem));
</script>

<style scoped>
.notification-preview {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.message {
  background-color: #1f1f1f;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  color: white;
  margin: 5px;
  padding: 5px;
  max-width: 500px;
  overflow: hidden;
}

.message-body {
  padding: 5px;
}
</style>

import { TargetUaEnum } from '@/js/enum/translate/ua/target-ua.enum';
import { WhenUaEnum } from '@/js/enum/translate/ua/when-ua.enum';
import { ActionUaEnum } from '@/js/enum/translate/ua/action-ua.enum';

function getTarget(target) {
  return `Ціль: ${TargetUaEnum[target]}.`;
}

function getWhen(target) {
  return `${WhenUaEnum[target]}`;
}

function getAction(type) {
  return `Дія: ${ActionUaEnum[type]}. `;
}

function getCondition(action) {
  const { actionCondition } = action;

  const actionConditions = {
    roll: (actionCondition) => `Умова: 🎲 ${actionCondition.condition.sign} ${actionCondition.condition.value}`,
    castle: () => 'Умова: Власник 🏰',
    blood: (actionCondition) => `Умова: Злий 🩸 ${actionCondition.condition.value}`,
    mostWeak: () => 'Умова: Найслабший',
    PickedSpellsNotFull: () => 'Умова: Неповне заклинання',
  };

  return actionConditions[actionCondition.type](actionCondition);
}

function getActionDescription(action) {
  return `${getAction(action.type)} ${getTarget(action.target)} Значення: ${action.value}`;
}

export { getTarget, getAction, getActionDescription, getCondition, getWhen };

import { gameEventEmitter } from '../game-event-emitter';
import { getPlayerById, getPlayersById } from '../game-session/player/player';
import { NotificationService } from './notification-service';
import { SpecialEffectEnum } from '@/js/enum/special-effect-enum';
import { getters, actions } from '@/js/store/store';
import { f7 } from 'framework7-vue';
import { InboundGameServerEventEnum } from '@/js/enum/server-interaction/inbound-game-server-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';
import { OutboundServerEventEnum } from '@/js/enum/server-interaction/outbound-server-event.enum';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';
import { NotificationType } from '@/js/game-content/static/game-static';

class Game {
  #socket = null;

  constructor(socket) {
    this.#socket = socket;

    this.#initListeners();
    this.#initSocketListeners();

    new NotificationService();

    /*this.#socket.onAny((eventName, ...args) => {
      console.log('eventName::: ', eventName);
      console.log('args::: ', args);
    });*/
  }

  close() {
    this.#socket.disconnect();
  }

  #initSocketListeners() {
    this.#socket.on(InboundGameServerEventEnum.PlayTauntAudio, ({ audioId }) => {
      gameEventEmitter.emit(InboundGameServerEventEnum.PlayTauntAudio, { audioId });
    });

    this.#socket.on(ClientGameNotificationEventEnum.Pause, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.Pause);
    });

    this.#socket.on(ClientGameNotificationEventEnum.Unpause, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.Unpause);
    });

    this.#socket.on(ClientGameNotificationEventEnum.GetTargets, ({ targets, cardId, actionIndex }) => {
      const players = targets.length ? getPlayersById(targets) : [];

      gameEventEmitter.emit(ClientEventEnum.SetTargets, { players, cardId, actionIndex });
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerPlayingSpells, ({ playing }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayerPlayingSpells, playing);

      const sound = playing ? 'your-turn-sound' : 'end-turn-notification';

      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, sound);
    });

    this.#socket.on(InboundGameServerEventEnum.UpdateGameSessionInfo, (payload) => {
      actions.setSessionInfo(payload);
      gameEventEmitter.emit(InboundGameServerEventEnum.UpdateGameSessionInfo);
    });

    this.#socket.on(InboundGameServerEventEnum.UpdatePlayerState, (payload) => {
      const updatedPlayer = { ...getters.player(), ...payload };
      actions.setPlayer(updatedPlayer);
      gameEventEmitter.emit(InboundGameServerEventEnum.UpdatePlayerState);
    });

    this.#socket.on(InboundGameServerEventEnum.ReturnBackToGame, async ({ roomId }) => {
      f7.preloader.hide();

      this.#getSessionState();
      actions.setIsReconnected(true);
      actions.setRoomId(roomId);
    });

    this.#socket.on(InboundGameServerEventEnum.CannotReconnect, async () => {
      await this.#cannotReconnect();
    });

    this.#socket.on(InboundGameServerEventEnum.AuthToken, ({ token }) => {
      actions.setToken(token);
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayAudio, ({ audioId }) =>
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, audioId),
    );

    this.#socket.on(ClientGameNotificationEventEnum.PrepareSpells, () =>
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PrepareSpells),
    );

    this.#socket.on(ClientGameNotificationEventEnum.RoundStarted, (payload) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'round-started');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.RoundStarted, payload);
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayerPlayingSpells, null);
    });

    this.#socket.on(ClientGameNotificationEventEnum.BattleStarted, () =>
      gameEventEmitter.emit(ClientGameNotificationEventEnum.BattleStarted),
    );

    this.#socket.on(ClientGameNotificationEventEnum.PlayerRollDices, ({ dices }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'roll-dices');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayerRollDices, dices);
    });

    this.#socket.on(ClientGameNotificationEventEnum.EndGameStatistic, (tableScoreData) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'winner-end-game');

      actions.setRoomId(null);
      actions.setToken(null);
      actions.setGameUrl(null);
      actions.setGamePort(null);

      gameEventEmitter.emit(ClientGameNotificationEventEnum.EndGameStatistic, tableScoreData);
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerKilled, ({ ownerId, targetId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayerKilled, { ownerId, targetId });
      gameEventEmitter.emit(ClientEventEnum.SpecialEffect, {
        ownerId,
        targetId,
        event: ClientGameNotificationEventEnum.PlayerKilled,
      });

      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'killed-player');
    });

    this.#socket.on(InboundGameServerEventEnum.UpdatePlayerList, ({ players }) => {
      const playerDict = players.reduce((accum, player) => {
        accum[player.id] = player;

        return accum;
      }, {});
      const newPlayers = getters.players().map((player) => ({ ...player, ...playerDict[player.id] }));
      actions.updatePlayers(newPlayers);
      gameEventEmitter.emit(InboundGameServerEventEnum.UpdatePlayerList);
    });

    this.#socket.on(InboundGameServerEventEnum.CreatePlayersList, async ({ players }) => {
      actions.setPlayers(players);
      gameEventEmitter.emit(InboundGameServerEventEnum.UpdatePlayerList);
    });

    this.#socket.on(ClientGameNotificationEventEnum.NotifyPlayers, (dto) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.NotifyPlayers, dto);
    });

    this.#socket.on(InboundGameServerEventEnum.AddPlayerToList, async ({ player }) => {
      actions.addPlayer(player);
    });

    this.#socket.on(InboundGameServerEventEnum.WeatherNotification, async ({ name, weatherMageName }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'weather-notification');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.WeatherNotification, { name, weatherMageName });
    });

    this.#socket.on(ClientGameNotificationEventEnum.StartTimer, ({ value }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.StartTimer, { value });
    });

    this.#socket.on(ClientGameNotificationEventEnum.StopTimer, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.StopTimer);
    });

    this.#socket.on(ClientGameNotificationEventEnum.AddSpellToHand, ({ spellId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.AddSpellToHand, spellId);
    });

    this.#socket.on(ClientGameNotificationEventEnum.RemoveSpellFromHand, ({ spellId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'drop-spell');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.RemoveSpellFromHand, spellId);
    });

    this.#socket.on(ClientGameNotificationEventEnum.AddSpellToPicked, ({ spellId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'pick-spell');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.AddSpellToPicked, spellId);
    });

    this.#socket.on(ClientGameNotificationEventEnum.RoundEnded, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'end-round');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.RoundEnded);
    });

    this.#socket.on(ClientGameNotificationEventEnum.GetTreasure, (dto) => {
      gameEventEmitter.emit(ClientEventEnum.AddEffect, {
        value: dto.treasures.length,
        playerId: dto.playerId,
        effect: SpecialEffectEnum.getTreasure,
      });

      dto.playerId && gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'get-treasure');
    });

    this.#socket.on(ClientGameNotificationEventEnum.GetDeadMage, ({ deadMage, playerId }) => {
      gameEventEmitter.emit(ClientEventEnum.AddEffect, { value: 1, playerId, effect: SpecialEffectEnum.getDeadMage });
    });

    this.#socket.on(ClientGameNotificationEventEnum.GetShield, ({ value, playerId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'get-shield');
      gameEventEmitter.emit(ClientEventEnum.AddEffect, { value, playerId, effect: SpecialEffectEnum.getShield });
    });

    this.#socket.on(ClientGameNotificationEventEnum.GetHeal, ({ value, playerId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'get-heal');
      gameEventEmitter.emit(ClientEventEnum.AddEffect, { value, playerId, effect: SpecialEffectEnum.getHeal });
    });

    this.#socket.on(ClientGameNotificationEventEnum.GetDamage, ({ value, playerId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'get-damage');
      gameEventEmitter.emit(ClientEventEnum.AddEffect, { value, playerId, effect: SpecialEffectEnum.getDamage });
    });

    this.#socket.on(ClientGameNotificationEventEnum.PickMages, ({ mages }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'pick-mages');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PickMages, { mages });
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayedCardsCurrentRound, (playedCardsCurrentRound) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayedCardsCurrentRound, playedCardsCurrentRound);
    });

    this.#socket.on(ClientGameNotificationEventEnum.GameStatistic, (payload) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.GameStatistic, payload);
    });

    this.#socket.on(ClientGameNotificationEventEnum.PickTreasures, (dto) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PickTreasures, dto);
    });

    this.#socket.on(ClientGameNotificationEventEnum.PickTreasuresComplete, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PickTreasuresComplete);
    });

    this.#socket.on(ClientGameNotificationEventEnum.NotEnoughBlood, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'error');
      gameEventEmitter.emit(ClientEventEnum.EmitNotification, {
        type: NotificationType.Text,
        message: 'Недостатньо крові',
      });
    });

    this.#socket.on(ClientGameNotificationEventEnum.ChooseCard, (dto) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'personal-target');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.ChooseCard, dto);
    });

    this.#socket.on(ClientGameNotificationEventEnum.ChooseCardComplete, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.ChooseCardComplete);
    });

    this.#socket.on(ClientGameNotificationEventEnum.SpellPlayed, ({ spellId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'click');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.SpellPlayed, spellId);
    });

    this.#socket.on(ClientGameNotificationEventEnum.PickPersonalTarget, (dto) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'personal-target');
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PickPersonalTarget, dto);
    });

    this.#socket.on(ClientGameNotificationEventEnum.PickPersonalTargetComplete, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PickPersonalTargetComplete);
    });

    this.#socket.on(ClientGameNotificationEventEnum.SomethingWentWrong, ({ message }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'error');
      gameEventEmitter.emit(ClientEventEnum.EmitNotification, {
        type: NotificationType.Text,
        message,
      });
    });

    this.#socket.on(ClientGameNotificationEventEnum.BlockDamage, ({ value, playerId }) => {
      playerId && gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'block-damage');
      gameEventEmitter.emit(ClientEventEnum.AddEffect, { value, playerId, effect: SpecialEffectEnum.blockDamage });
    });

    this.#socket.on(ClientGameNotificationEventEnum.BlockDamage, ({ value, playerId }) => {
      playerId && gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'block-damage');
      gameEventEmitter.emit(ClientEventEnum.AddEffect, { value, playerId, effect: SpecialEffectEnum.blockDamage });
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerCommitmentSuicide, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'i-want-to-die');
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerUltraKill, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'ultra-kill');
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerDoubleKill, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'double-kill');
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerMegaKill, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'mega-kill');
    });

    this.#socket.on(ClientGameNotificationEventEnum.PlayerMonsterKill, () => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'monster-kill');
    });

    this.#socket.on(ClientGameNotificationEventEnum.FirstBlood, ({ ownerId }) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'first-blood');

      const owner = getPlayerById(ownerId);
      setTimeout(
        () =>
          gameEventEmitter.emit(ClientEventEnum.EmitNotification, {
            type: NotificationType.Text,
            message: `${owner?.username} проливає першу кров! Отримай благословення від ЛеоКіллера!`,
          }),
        1_000,
      );
    });
  }

  #initListeners() {
    gameEventEmitter.addListener(OutboundServerEventEnum.ReconnectToGame, () => {
      const username = getters.username();
      const roomId = getters.roomId();
      const token = getters.token();

      setTimeout(() => this.#cannotReconnect(), 10_000);

      const userData = token
        ? {
            token,
          }
        : { roomId, username };

      this.#socket.emit(OutboundServerEventEnum.ReconnectToGame, userData);
    });

    gameEventEmitter.addListener(OutboundServerEventEnum.Pause, () => {
      this.#socket.emit(OutboundServerEventEnum.Pause, { token: getters.token() });
    });
    gameEventEmitter.addListener(OutboundServerEventEnum.GameStatistic, () => {
      this.#socket.emit(OutboundServerEventEnum.GameStatistic, { token: getters.token() });
    });
    gameEventEmitter.addListener(OutboundServerEventEnum.AutoPlay, () => {
      this.#socket.emit(OutboundServerEventEnum.AutoPlay, { token: getters.token() });
    });

    gameEventEmitter.addListener(OutboundServerEventEnum.PickTreasure, (dto) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'pick-treasure');
      this.#socket.emit(OutboundServerEventEnum.PickTreasure, { ...dto, token: getters.token() });
    });

    gameEventEmitter.addListener(OutboundServerEventEnum.PickAction, (dto) => {
      gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'click');
      this.#socket.emit(OutboundServerEventEnum.PickAction, { ...dto, token: getters.token() });
    });
    gameEventEmitter.addListener(OutboundServerEventEnum.SendTauntAudio, (audioId) => this.#sendTauntAudio(audioId));
    gameEventEmitter.addListener(OutboundServerEventEnum.Ready, () => this.#ready());
    gameEventEmitter.addListener(OutboundServerEventEnum.BackToHand, (cardId) => this.#playerBackSpellToHand(cardId));
    gameEventEmitter.addListener(OutboundServerEventEnum.PickSpell, (cardId) => this.#playerPickSpell(cardId));
    gameEventEmitter.addListener(OutboundServerEventEnum.UseSpell, (dto) => this.#useSpell(dto));

    gameEventEmitter.addListener(OutboundServerEventEnum.DrainBlood, ({ spellId }) => this.#drainBlood(spellId));
    gameEventEmitter.addListener(OutboundServerEventEnum.GetTargets, (cardId) => this.#getTargets(cardId));
    gameEventEmitter.addListener(OutboundServerEventEnum.PickMage, (mageId) => this.#pickMage(mageId));
    gameEventEmitter.addListener(OutboundServerEventEnum.JoinToGame, () => this.#joinToGame());
    gameEventEmitter.addListener(OutboundServerEventEnum.PickPersonalTarget, (dto) => this.#pickPersonalTarget(dto));
  }

  #ready() {
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'ready-notification');
    this.#socket.emit(OutboundServerEventEnum.Ready, { token: getters.token() });
  }

  #sendTauntAudio(audioId) {
    this.#socket.emit(OutboundServerEventEnum.SendTauntAudio, { audioId, token: getters.token() });
  }

  #playerBackSpellToHand(spellId) {
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'return-back');
    this.#socket.emit(OutboundServerEventEnum.BackToHand, { spellId, token: getters.token() });
  }

  #playerPickSpell(spellId) {
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'pick-spell');
    this.#socket.emit(OutboundServerEventEnum.PickSpell, { spellId, token: getters.token() });
  }

  #useSpell(dto) {
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'use-spell');

    this.#socket.emit(OutboundServerEventEnum.UseSpell, {
      spellId: dto.spellId,
      targetId: dto.targetId,
      token: getters.token(),
    });
  }

  #drainBlood(spellId) {
    this.#socket.emit(OutboundServerEventEnum.DrainBlood, { spellId, token: getters.token() });
  }

  #getTargets(spellId) {
    this.#socket.emit(OutboundServerEventEnum.GetTargets, { spellId, token: getters.token() });
  }

  #joinToGame() {
    this.#socket.emit(OutboundServerEventEnum.JoinToGame, {
      roomId: getters.roomId(),
      username: getters.username(),
    });
  }

  #pickPersonalTarget(dto) {
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'click');
    this.#socket.emit(OutboundServerEventEnum.PickPersonalTarget, {
      ...dto,
      token: getters.token(),
    });
  }

  #pickMage(mageId) {
    gameEventEmitter.emit(ClientGameNotificationEventEnum.PlayAudio, 'click');
    this.#socket.emit(OutboundServerEventEnum.PickMage, { mageId, token: getters.token() });
  }

  #getSessionState() {
    this.#socket.emit(OutboundServerEventEnum.GetSessionState, { token: getters.token() });
  }

  #cannotReconnect() {
    if (getters.isReconnected()) return;

    f7.preloader.hide();

    actions.setRoomId(null);
    actions.setToken(null);
    actions.setGameUrl(null);
    actions.setGamePort(null);

    f7.dialog.alert(`Cannot reconnect`, () => {
      location.reload();
    });
  }
}

export { Game };

<template>
  <f7-app v-bind="f7params" theme-dark>
    <f7-view main class="safe-areas" url="/"> </f7-view>
  </f7-app>
</template>
<script>
import { getDevice } from 'framework7/lite-bundle';
import { routes } from '@/js/routes/routes';
import { state } from '@/js/store/store';
import { SocketLobby } from '@/js/communication/socket-lobby';

export default {
  setup() {
    const device = getDevice();
    // Framework7 Parameters
    const f7params = {
      name: 'ЕПІЧНІ МАХАЧІ БОЙОВИХ МАГІВ', // App name
      theme: 'auto', // Automatic theme detection
      id: 'io.framework7.battle-mages', // App bundle ID
      state,
      routes,
      pushState: true,
      serviceWorker: {
        path: '/service-worker.js',
      },

      // Input settings
      input: {
        scrollIntoViewOnFocus: device.cordova && !device.electron,
        scrollIntoViewCentered: device.cordova && !device.electron,
      },
      // Cordova Statusbar settings
      statusbar: {
        iosOverlaysWebView: true,
        androidOverlaysWebView: false,
      },
    };

    return {
      f7params,
    };
  },
  created() {
    new SocketLobby();
  },
};
</script>

<template>
  <modal v-if="showModal">
    <XyzTransition appear xyz="fade up big">
      <div class="special-effect-preview">
        <EliminatedNotification
          v-if="event === ClientGameNotificationEventEnum.PlayerKilled"
          :data="item"
        ></EliminatedNotification>
      </div>
    </XyzTransition>
  </modal>
</template>

<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import { onMounted, onUnmounted, ref } from 'vue';
import EliminatedNotification from './eliminated-notification';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';
import { ClientGameNotificationEventEnum } from '@/js/enum/server-interaction/client-game-notification-event.enum';

let item = ref(null);
let showModal = ref(false);
let event = ref(null);

function setItem(data) {
  showModal.value = !!data;
  item.value = data;

  if (!data) return;

  event.value = data.event;

  setTimeout(() => {
    setItem(null);
  }, 2500);
}

onMounted(() => gameEventEmitter.addListener(ClientEventEnum.SpecialEffect, setItem));
onUnmounted(() => gameEventEmitter.removeListener(ClientEventEnum.SpecialEffect, setItem));
</script>

<style scoped>
.special-effect-preview {
  position: fixed;
  z-index: 9999;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px; /* Додати відступи від бордера до контенту таблиці */
}
</style>

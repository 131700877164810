<template>
  <f7-block class="spellOnHandsBlock">
    <f7-row>
      <div class="cards">
        <div v-if="cards?.length" class="card-in-row" :data-id="card.id" v-for="card in cards" :key="card.id">
          <CardIcon :item="card" />
        </div>

        <div v-if="!cards?.length" class="card-in-row" v-for="card in preview">
          <CardIconHover></CardIconHover>
        </div>
      </div>
    </f7-row>
  </f7-block>
</template>
<script setup>
import { ref } from 'vue';
import CardIcon from '@/components/card/card-icon';
import CardIconHover from '@/components/card/card-icon-hover';

const props = defineProps({
  cards: Array,
});
let preview = ref([0, 1, 2, 3, 4]);
</script>

<style scoped>
.spellOnHandsBlock {
  overflow: hidden;
  height: 70px;
  z-index: 1;
  display: block;
}

.card-in-row {
  float: left;
  width: 85px;
  height: 70px;
}
</style>

<template>
  <div
    class="player-icon-card section-background"
    @mouseenter="setPlayerItem(player)"
    @mouseleave="setPlayerItem(null)"
    :style="{ 'background-color': player.color }"
  >
    <div class="player-icon-body">
      <strong v-if="shouldShowParam('health')" class="player-attr">{{ player.health }}💚</strong>
      <strong v-if="shouldShowParam('pointsForTurn')" class="player-attr">{{ player.pointsForTurn }}⚡</strong>
      <img :src="imgUrl" />
    </div>
  </div>
</template>

<script setup>
import { gameEventEmitter } from '@/js/game-event-emitter';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

const props = defineProps({
  player: Object,
  preferAttrList: { type: Array, required: false, default: [] },
});

const imgUrl = '';

function setPlayerItem(item) {
  gameEventEmitter.emit(ClientEventEnum.GetPlayerPreview, item);
}

function shouldShowParam(param) {
  return props.preferAttrList.includes(param);
}
</script>
<style scoped>
.player-attr {
  line-height: normal;
  top: 50%;
  padding: 5px;
  display: inline-block;
  vertical-align: middle;
  justify-content: center;
  color: white;
  text-shadow: 1px 1px 2px #1a1e1a;
  font-size: 10px;
}

.player-icon-card {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  position: relative;
  overflow: hidden;
  border-style: outset;
  font-weight: bolder;
  line-height: 50px;
}

.player-icon-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 1050px) {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

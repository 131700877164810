<template>
  <div @mouseenter="setCardItem(item)" @mouseleave="setCardItem(null)" class="card">
    <div>
      <strong
        class="display-header"
        :style="[item.sign && item.cardType === CardType.Spell ? signColors[item.sign] : signColors[item.cardType]]"
        >{{ item.cardType }} <span v-if="item.rating">⚡{{ item.rating }}</span></strong
      >
    </div>

    <div class="cardImg" :style="[getImgStyle(item.key)]">
      <f7-row v-if="item.cardType === CardType.Spell">
        <f7-col
          ><strong>{{ item.type === 'source' ? '1/3' : '' }}</strong></f7-col
        >
        <f7-col
          ><strong>{{ item.type === 'quality' ? '2/3' : '' }}</strong></f7-col
        >
        <f7-col
          ><strong>{{ item.type === 'delivery' ? '3/3' : '' }}</strong></f7-col
        >
      </f7-row>
    </div>

    <div class="text-size">
      <div class="title-height">
        <strong :title="item.type" style="width: 100%">{{ item.name }}</strong>
      </div>
      <div class="card-body">
        <div v-for="action in item.actions">
          <strong style="color: red" class="text-size">{{ getTarget(action.target) }}</strong>
        </div>
      </div>
      <br />
      <span v-if="item.when" style="color: orangered" class="text-size">{{ getWhen(item.when) }}</span>
    </div>
  </div>
</template>

<script setup>
import { staticDict, CardType, signColors, imageCardShadowStyle } from '@/js/game-content/static/game-static';
import { gameEventEmitter } from '@/js/game-event-emitter';
import { getTarget, getWhen } from '@/js/card';
import { ClientEventEnum } from '@/js/enum/client-interaction/client-event.enum';

const props = defineProps({
  item: Object,
});

function getImgStyle(key) {
  const imgUrl = `${staticDict[props.item.cardType]}${key}`;

  return imageCardShadowStyle(imgUrl);
}

function setCardItem(item) {
  const data = item ? { ...item, showTargets: true } : item;
  gameEventEmitter.emit(ClientEventEnum.GetCardPreview, data);
}
</script>
<style scoped>
.cardImg {
  width: 100%;
  height: 50%;
}

.card {
  transition: all 0.4s;
  background: #353536;
  border: 2px solid black;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateZ(0);
  height: 175px;
  width: 125px;
  transition-duration: 1500ms;
  justify-content: center;
  text-align: center;
  font-size: 10px;
  margin: 0;
}

.card span {
  font-size: 10px;
}

.card-body {
  border-radius: 6px;
}

.title-height {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 150px;
  background: linear-gradient(#37373b, #1a1a1f);
  box-shadow: 0 1px 2px 3px rgba(0, 0, 0, 0.4);
  height: 18px;
}
</style>

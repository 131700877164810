const OutboundServerEventEnum = {
  Pause: 'pause',
  GameStatistic: 'get-game-statistic',

  JoinToGame: 'join-to-game',
  Ready: 'ready',
  AutoPlay: 'auto-play',

  ReconnectToGame: 'reconnect-to-game',
  GetSessionState: 'get-session-state',

  UseSpell: 'use-spell',
  PickTreasure: 'pick-treasure',
  PickAction: 'pick-action',
  GetTargets: 'get-targets',
  BackToHand: 'back-to-hand',
  PickSpell: 'pick-spell',
  SendTauntAudio: 'send-taunt-audio',
  PickMage: 'pick-mage',
  DrainBlood: 'drain-blood',
  PickPersonalTarget: 'pick-personal-target',
};

export { OutboundServerEventEnum };

export const TargetUaEnum = {
  AllTargetWithoutFullTreasures: 'Без повних скарбів',
  PersonalTarget: 'Обери',
  MostWeak: 'Найслабший',
  RightTarget: 'Правий',
  LeftTarget: 'Лівий',
  AllTarget: 'Всі без тебе',
  RandomTargetWithSpellsOnHand: 'Маг з заклинаннями в руці',
  RandomTargetWithPickedSpells: 'Маг з вибраними заклинаннями',
  AllTargetWithoutTreasures: 'Без скарбів',
  AllTargetWithTreasures: 'З скарбами',
  MostHealthyTarget: 'Найздоровіший',
  AllTargetThatHealthyThanYou: 'Всі, хто здоровіші за тебе',
  AllTargetThatAlreadyPlayed: 'Всі, хто вже грав',
  AllTargetThatNotPlayedYet: 'Всі, хто ще не грав',
  RandomTargetThatAlreadyPlayed: 'Випадковий, хто вже грав',
  RandomTargetThatNotPlayedYet: 'Випадковий, хто ще не грав',
  Self: 'Ти',
  Dead: 'Мертвий',
  Winners: 'Переможці',
  Random: 'Випадкова',
  LeftAndRight: 'Лівий та правий',
  AllTargetWithYou: 'Всі з тобою',
  AllTargetWithShield: 'Всі з щитом',
  TargetWithCastle: 'Маг з замком',
  RandomTargetWithShield: 'Маг з щитом',
  TargetWithLessKilledMages: 'Маг з найменшою кількістю вбитих магів',
  TargetWithMostKilledMages: 'Маг з найбільшою кількістю вбитих магів',
  TargetWithMostNumberOfHeals: 'Маг з найбільшою кількістю лікувань',
  AllAlivePlayers: 'Всі живі маги',
  RandomTargetWithTreasure: 'Маг зі скарбом',
  AllTargetWithBlood: "Всі маги з кров'ю",
  RandomTargetWithBlood: "Маг з кров'ю",
  AllTargetWithoutBlood: 'Всі маги без крові',
};

<script setup>
import { reactive } from 'vue';
import { HttpClient } from '@/js/utils/http-client';
import { f7 } from 'framework7-vue';
import { actions } from '@/js/store/store';

const loginForm = reactive({
  email: '',
  password: '',
});

function validateForm() {
  if (!loginForm.password || !loginForm.email) {
    console.log('Заполните все поля.');
    return false;
  }

  return true;
}
async function submitForm() {
  if (!validateForm()) {
    console.log('Форма содержит ошибки. Пожалуйста, исправьте и отправьте снова.');
    return;
  }

  const auth = await new HttpClient()
    .post('/auth/login', {
      password: loginForm.password,
      email: loginForm.email,
    })
    .catch((error) => {
      console.error(error);
      f7.dialog.alert('Проблеми зі входом. Спробуйте пізніше');
    });

  if (auth.error) {
    console.error(auth.error);
    return;
  }

  const account = await new HttpClient()
    .get(`/accounts/${auth.data.accountId}`, {}, { Authorization: `Bearer ${auth.data.accessToken}` })
    .catch((error) => {
      console.error(error);
      f7.dialog.alert('Проблеми з акаунтом. Спробуйте пізніше');
    });

  if (account.error) {
    return;
  }

  actions.setAuthToken(auth.data.accessToken, auth.data.refreshToken);
  actions.setAccount(account.data);
  actions.setUsername(account.data.username);
  f7.views.main.router.navigate('/lobby/');
}
</script>

<template>
  <div class="login-form">
    <h2>Вхід в систему</h2>
    <form @submit.prevent="submitForm">
      <label for="username">Email:</label>
      <input type="text" id="email" v-model="loginForm.email" required />

      <label for="password">Password:</label>
      <input type="password" id="password" v-model="loginForm.password" required />

      <button type="submit">Увійти</button>
    </form>
  </div>
</template>

<style scoped>
.login-form {
  max-width: 400px;
  margin: auto;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  font-weight: bold;
}

input {
  padding: 8px;
  font-size: 16px;
}

button {
  background-color: #3498db;
  color: white;
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

button:hover {
  background-color: #2980b9;
}
</style>
